import { NotificationComponent } from './../notification/notification.component';
import { Component, ElementRef, OnInit, HostListener, ViewChild, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Notification, NotificationType } from 'src/app/classes/utility/notification';
import { UleService } from 'src/app/services/ule/ule.service';
import { uleModeEnum, UleOfDevice } from 'src/app/classes/enums/ule.enum';

const custom: string = "hello";

@Component({
  selector: 'app-ule-mode',
  templateUrl: './ule-mode.component.html',
  styleUrls: ['./ule-mode.component.css']
})
export class UleModeComponent implements OnInit, OnDestroy {
  @Output() setCodeEditorEditable: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  fakeUleEnum = uleModeEnum;
  expandUleMode:boolean = false;
  uleProperties: UleOfDevice = this.uleService.currentUle;  
  uleSubscriber: any;

  constructor(private uleService: UleService) { }

  ngOnInit(): void {
    this.uleSubscriber = this.uleService.uleChanged.subscribe((ule: UleOfDevice) => {
      this.uleProperties = ule;
      if(this.uleProperties.mode === uleModeEnum.CUSTOM){
        this.updateUleEditor(uleModeEnum.CUSTOM);
      }else{
        this.updateUleEditor(uleModeEnum.ADV_FORMAT);
      }
    });
  }

  ngOnDestroy(): void {
    this.uleSubscriber.unsubscribe();
  }
  
  setExpandUleMode(value: boolean): void{
    this.expandUleMode = value;
  }

  setMode(value: uleModeEnum): void{
    this.uleService.setCurrentUle(this.uleProperties.productName, this.uleProperties.connection, value);
  }

  updateUleEditor(value: uleModeEnum): void{
    if(value === uleModeEnum.ADV_FORMAT){
      setTimeout(() => {
        this.setCodeEditorEditable.emit(false);
      }, 0);
    }else{
      setTimeout(() => {
        this.setCodeEditorEditable.emit(true);
      }, 0);
    }
    this.setExpandUleMode(false);
  }

  getIconOnMode(): string{
    let src: string = "";
    if(this.uleProperties.mode === uleModeEnum.ADV_FORMAT){
      src = "assets/images/product/adv_format.png";
    }else{
      src = "assets/images/product/custom_ule.png";
    }

    return src;
  }
}

