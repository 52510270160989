<div id="wrapper">
  <main id="main" role="main" class="container-fluid">
    <div class="row">
      <div class="col no-padding sidebar" [ngClass]="{'visible': standaloneMode || isRemoted || azureMode}">
        <app-sidebar [standaloneMode]="standaloneMode" [isRemoted]="isRemoted" #sidebar></app-sidebar>
      </div>
      <div class="col-md">
        <div class="internal-wrapper">
          <app-recent [standaloneMode]="standaloneMode" #recent></app-recent>
          <section id="cats">
            <div class="row">
              <div class="col-12 col-md-6">
                <h1 class="">{{'HOME.OFFLINE-CONFIGURATION' | translate}}</h1>
              </div>
              <div class="col-12 col-md-6 search-container">
                <div id="frm-search">
                  <input type="text" [placeholder]="'HOME.SEARCH-PLACEHOLDER' | translate" autocomplete="off"
                    [(ngModel)]="deviceSearchValue" (ngModelChange)="refreshSearch($event)" />
                  <button type="submit" class="btn btn-default">{{'HOME.SEARCH' | translate}}</button>
                </div>
                <div class="search-results" [ngClass]="{visible: searchedProducts.length > 0}">
                  <a class="result" *ngFor="let product of searchedProducts" [routerLink]="'/configuration/product/' + product.index">
                    <img [src]="product.imageUrl" alt="">
                    <span class="name">{{product.name}}</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <h4 class="mb-5">
                  {{'HOME.DESCRIPTION' | translate}}
                </h4>
                <div class="row">
                  <div class="col-12">
                    <div class="card-deck mb-5">
                      <app-img-link class="app-img-link" *ngFor="let item of productsMenu; let i = index" [title]="item.name"
                        [image]="'assets/images/home/' + item.name + '.png'" [link]="'/configuration/category/' + i">
                      </app-img-link>
                      <app-img-link class="app-img-link" [title]="'Custom Configuration'" *ngIf="standaloneMode"
                        [image]="'assets/images/home/PowerScan.png'" [link]="'/configuration/category/' + 'userconfig'">
                      </app-img-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </main>
</div>
