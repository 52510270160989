<button type="button" id="customManualButton" class="btn btn-primary" data-toggle="modal" data-target="#customManual"></button>

<div class="modal fade" id="customManual" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{'PRODUCT.CUSTOM-MANUAL' | translate}}</h5>
        <div class="btn-group">
          <button type="button" class="btn btn-outline-warning btn-sm" *ngIf="step === 2"
            [ngClass]="{active: showProgrammingModeLabel}" [hidden]="hideProgrammingModeLabelButton()" aria-label="Show configuration" (click)="toggleProgrammingModeLabel()">
            {{'SHARED.SHOW-PROGRAMMING-LABEL' | translate}}
          </button>
          <button type="button" class="btn btn-outline-warning btn-sm" *ngIf="step === 2"
            [ngClass]="{active: showConfiguration}" aria-label="Show configuration" (click)="toggleConfiguration()">
            {{'SHARED.SHOW-CONFIG' | translate}}
          </button>
          <button type="button" class="btn btn-outline-warning btn-sm" *ngIf="step === 2"
            [ngClass]="{active: concatenate}" aria-label="Concatenate" (click)="toggleConcatenate()">
            {{'SHARED.CONCATENATE' | translate}}
          </button>
          <div class="download-share" *ngIf="step === 2">
            <button type="button" class="btn btn-outline-warning btn-sm"
              aria-label="Download" (click)="download()" [disabled]="customecreatingPdf">
              {{'SHARED.DOWNLOAD' | translate}}
            </button>
            <div class="toggle">
              <div>PDF</div>
              <div class="custom-control custom-switch">
                <input type="checkbox"
                  class="custom-control-input"
                  [(ngModel)]="customselectedFileType"
                  id="customswitchFileType">
                <label class="custom-control-label" for="customswitchFileType"></label>
              </div>
              <div>RTF</div>
            </div>
          </div>
        </div>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="step === 1">
          <div class="d-flex justify-content-between">
            <h5>{{'PDF-PREVIEW.ITEMS' | translate}}</h5>
            <div class="d-flex flex-row-reverse">
              <button type="button" class="btn btn-outline-warning btn-sm d-flex" aria-label="Add modified parameters to custom manual list" (click)="addModifiedParams()" [title]="'SHARED.ADD-MODIFIED-PARAMS-TO-CUSTOM-MANUAL-LIST' | translate">
                <img src="../../../assets/images/plus-solid.svg" alt="" id="header_icon"><span>{{'SHARED.ADD-MODIFIED-PARAMS' | translate}}</span>
              </button>
              @if (state() == 'edit') {
                <div class="btn btn-sm d-flex" id="delete_button" [title]="'SHARED.DELETE' | translate" (click)="onEditDelete()">
                  <img id="delete_icon" src="../../../assets/images/trash-solid.svg" alt="">
                </div>
              }

              @if (state() == 'edit') {
                <div class="element_checkbox d-flex align-items-center" [title]="'SIDEBAR.SELECT-ALL-SEARCH-DEVICE-OPTIONS' | translate">
                  <input
                    (change)="onEditCheckAllChange($event)" 
                    id="edit_check_all" class="substituted" type="checkbox" aria-hidden="true"/>
                  <label for="edit_check_all" class="no_margin"></label>
                </div>
              }
            </div>
          </div>

          <div class="manual-item" *ngFor="let item of customManual; index as i">
            <div class="desc">{{item.description}}</div>
            <div class="d-flex align-items-center">
              @if (state() == 'normal') {
                <div class="btn btn-sm d-flex" (click)="deleteFromManual(item)" id="delete_button" [title]="'SHARED.DELETE' | translate">
                  <img id="delete_icon" src="../../../assets/images/trash-solid.svg" alt="">
                </div>
              }
              @if (state() == 'edit') {
                <div class="element_checkbox">
                  <input [id]="'edit_check_' + i" class="substituted" type="checkbox" aria-hidden="true" 
                    (change)="onEditInputChange(item)"
                    [checked]="item.isOnEdit"/>
                  <label [for]="'edit_check_' + i" class="no_margin"></label>
                </div>
              }
            </div>
          </div>
          <div class="buttons d-flex justify-content-center">
            <div class="btn btn-outline-warning btn-sm" (click)="printToDatamatrix()">{{'PDF-PREVIEW.PRINT-DX' | translate}}</div>
            <div class="btn btn-outline-warning btn-sm" (click)="printToBarcode()">{{'PDF-PREVIEW.PRINT-BC' | translate}}</div>
            @if (state() == 'normal') {
              <div class="btn-group dropup opitons_group">
                <div class="options_btn" data-toggle="dropdown" aria-expanded="false">
                  <img class="icon_img" src="assets/images/product/options.png" alt="">
                </div>
                <div class="dropdown-menu">
                  <div class="option_element"><button class="btn_option" (click)="onEdit()">{{'SHARED.EDIT' | translate}}</button></div>
                  <div class="option_element"><button class="btn_option" (click)="onDeleteAll()">
                    {{'SHARED.DELETE-ALL' | translate}}</button></div>
                </div>
              </div>
            }
            @if(state() == 'edit'){
              <div class="cancel_btn" (click)="onCancel()">
                <div>{{'SHARED.CANCEL' | translate}}</div>
              </div>
            }
          </div>
        </div>
        <div class="pdf" [ngClass]="{'visible': step === 2}">
          <div class="clearfix">
            <div class="btn btn-outline-warning btn-sm" (click)="back()">{{'SHARED.BACK' | translate}}</div>
            <div class="parameters clearfix">
              <div class="margin">
                <div class="label">{{'SHARED.MAX-COMMAND-LENGTH' | translate}}</div>
                <app-bootstrap-combo [defaultValue]="currentMaxCommand" [values]="maxCommandValues"
                  (valueChanged)="onMaxCommandValueChanged($event)">
                </app-bootstrap-combo>
              </div>
              <div class="margin">
                <div class="label">{{'SHARED.MARGIN' | translate}}</div>
                <app-bootstrap-combo [defaultValue]="currentMargin" [values]="marginValues"
                  (valueChanged)="onMarginValueChanged($event)">
                </app-bootstrap-combo>
              </div>
              <div class="margin">
                <div class="label">{{'SHARED.HEIGHT' | translate}}</div>
                <app-bootstrap-combo [defaultValue]="currentHeight" [values]="heightValues"
                  (valueChanged)="onHeightValueChanged($event)">
                </app-bootstrap-combo>
              </div>
              <div class="width" *ngIf="type === 'code128'">
                <div class="label">{{'SHARED.WIDTH' | translate}}</div>
                <app-bootstrap-combo [defaultValue]="currentWidth" [values]="widthValues"
                  (valueChanged)="onWidthValueChanged($event)">
                </app-bootstrap-combo>
              </div>
            </div>
          </div>

          <app-pdf-content #pdfContent [preCommands]="preCommands" [postCommands]="postCommands" [width]="currentWidth"
            [preCmd] = "preCmd"[enterConfigurationCmd] = "enterConfigurationCmd" [exitConfigurationCmd] = "exitConfigurationCmd"
            [commands]="customManualCommands" [productName]="productName" [margin]="currentMargin" [height]="currentHeight" [maxCommand]="currentMaxCommand" [family]="family" [currentUle]="">
          </app-pdf-content>
        </div>
      </div>
    </div>
  </div>
</div>
