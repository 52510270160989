import { uleModeEnum } from './../../classes/enums/ule.enum';
import { ProductComponent } from 'src/app/pages/product/product.component';
import { Component, OnInit, Input, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { Parameter, ParameterOption } from 'src/app/classes/parameters';
import { Helper } from 'src/app/classes/utility';
import { NotificationComponent } from '../notification/notification.component';
import { UleModeComponent } from '../ule-mode/ule-mode.component';

@Component({
  selector: 'app-readable-ascii',
  templateUrl: './readable-ascii.component.html',
  styleUrls: ['./readable-ascii.component.css']
})
export class ReadableAsciiComponent implements OnInit {
  @Input() parameter: Parameter;
  @Input() readonly = false;
  @Input() checkCurrentValue: boolean = false;
  @Input() notification: NotificationComponent;
  @Input() uleModeComponent: UleModeComponent;
  @Output() valueChanged: EventEmitter<string> = new EventEmitter<string>();

  deviceValueText = '';
  currentValueText = '';
  selectedOptions: String[] = [];
  hiddenString: string = "*****";

  constructor(private eRef: ElementRef) {
  }

  ngOnInit(): void {
    const defaultValueArray = this.parameter.defaultValue.match(/.{1,2}/g);
    this.refreshValue();

    let reversedDefaultValueArray = defaultValueArray.reverse();
    let checkValueNotEmpty = false;
    for (const value of reversedDefaultValueArray) {
      const optionValue = this.parameter.options.find(it => it.value.toUpperCase() === value);
      if (optionValue) {
        if (!checkValueNotEmpty && optionValue.value.toLowerCase() !== this.parameter.fillChar.toLowerCase()) {
          checkValueNotEmpty = true;
        }
        
        if (checkValueNotEmpty) {
          this.deviceValueText = optionValue.name + this.deviceValueText ;
        }
      }
    }
    if(this.parameter.hideValue === true){
      this.currentValueText = this.hiddenString;
      this.deviceValueText = this.hiddenString;
    }
  }

  refreshValue(): void {
    const valueArray = this.parameter.value.match(/.{1,2}/g);
    this.currentValueText = '';
    this.selectedOptions = [];

    let reversedValueArray = valueArray.reverse();
    let checkValueNotEmpty = false;
    let reversedSelectedOptions = [];
    for (let value of reversedValueArray) {
      let optionValue = this.parameter.options.find(it => it.value.toUpperCase() === value);
      if (optionValue) {
        if (!checkValueNotEmpty && optionValue.value.toLowerCase() !== this.parameter.fillChar.toLowerCase()) {
          checkValueNotEmpty = true;
        }
        if (checkValueNotEmpty) {
          reversedSelectedOptions.push(optionValue);
          this.currentValueText = optionValue.name + this.currentValueText;
        }
      }
    }
    if(checkValueNotEmpty && this.parameter.hideValue === true){
      this.currentValueText = this.hiddenString;
    }

    this.selectedOptions = reversedSelectedOptions.reverse();
  }

  changeInputValue(changedValue: string, setDeviceValue: boolean = true): void {
    if (setDeviceValue) {
      this.parameter.deviceValue = changedValue;
      this.deviceValueText = '';
    }
    this.currentValueText = '';

    const deviceValueArray = changedValue.match(/.{1,2}/g);
    
    let reversedDeviceValueArray = deviceValueArray.reverse();
    let checkValueNotEmpty = false;
    for (const value of reversedDeviceValueArray) {
      const optionValue = this.parameter.options.find(it => it.value.toUpperCase() === value);
      
      if (optionValue) 
      {
        if (!checkValueNotEmpty && optionValue.value.toLowerCase() !== this.parameter.fillChar.toLowerCase()) 
        {
          checkValueNotEmpty = true;
        }
        
        if (checkValueNotEmpty) 
        {
          if (setDeviceValue) 
          {
            this.deviceValueText = optionValue.name + this.deviceValueText;
          }
          this.currentValueText = optionValue.name + this.currentValueText;
        }
      }
    }
    if(this.parameter.hideValue === true){
      this.deviceValueText = this.hiddenString;
      this.currentValueText = this.hiddenString;
    }
    this.valueChanged.emit(this.parameter.name);
  }

  selectOption(changedValue: string): void {
    this.selectedOptions = [];

    for(let i = 0; i < changedValue.length; i++){
      let code: String = changedValue.charCodeAt(i).toString(16);
      this.selectedOptions.push(code);
    }

    this.saveChanges();
  }

  saveChanges(): void {
    const missingValues = Helper.parseIntWithRadix(this.parameter.maxLen) - this.selectedOptions.length;
    const missingValuesArray = Array(missingValues).fill(this.parameter.fillChar);

    this.parameter.value = this.selectedOptions.join('').toUpperCase() + missingValuesArray.join('');

    this.valueChanged.emit(this.parameter.name);
  }

  onHideValueParamClick(): void {
    if(this.parameter.hideValue === true){
      if(this.currentValueText === this.hiddenString) {
        this.currentValueText = "";
      }
    }
  }

  updateInputBoxBlur(): void {
    if(this.parameter.hideValue === true){
      if(this.currentValueText === "") {
        this.currentValueText = this.hiddenString;
      }
    }
  }
}
