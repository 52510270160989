import { XmlService } from 'src/app/services/xml.service';
import { UleOfDevice, uleModeEnum } from './../../classes/enums/ule.enum';
import { Injectable } from '@angular/core';
import { Parameter } from 'src/app/classes/parameters';
import { CodeEditorComponent } from 'src/app/shared/code-editor/code-editor.component';
import { BehaviorSubject } from 'rxjs';
import { WriteCommand } from '../device/device';

@Injectable({
  providedIn: 'root'
})
export class UleService {
  isInUleEditor: boolean = false;
  currentUle: UleOfDevice = {
    productName: '',
    connection: '',
    mode: uleModeEnum.ADV_FORMAT
  };
  uleChanged: BehaviorSubject<UleOfDevice> = new BehaviorSubject<UleOfDevice>(this.currentUle);
  
  constructor(private XmlService: XmlService) { }

  setCurrentUle(productName: string, connection: string, mode: uleModeEnum): void{
    this.currentUle = {productName: productName, connection: connection, mode: mode};
    this.uleChanged.next(this.currentUle);
  }

  hasAnyPackParameterChanged(): boolean{
    const openingProduct = this.XmlService.editedProducts.find(it => it.name === this.currentUle.productName && it.connection === this.currentUle.connection);
    return openingProduct.parameters.some(it => it.isPackParameter && it.value !== it.defaultValue);
  }

  getAFCommandsFromUle(ule: string): WriteCommand[]{
    let cmds: WriteCommand[] = [];
    const lines = ule.split('\n');
    for(let i = 0; i < lines.length; i++){
      const line = lines[i];
      if(line.startsWith('$C')){
        const code = line.substring(2, 6);
        const value = line.substring(6);
        cmds.push({code: code, value: value});
      }
    }
    return cmds;
  }
}
