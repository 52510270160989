import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {

  private showParameterCode = LocalStorageService.getItem("showParameterCode");

  setShowParameterCode(value: boolean): void {
    this.showParameterCode = value;
    LocalStorageService.setItem("showParameterCode", this.showParameterCode);
  }

  getShowParameterCode(): boolean {
    if (this.showParameterCode === null) {
      this.setShowParameterCode(false);
    }
    return this.showParameterCode;
  }
}