<div class="form-group row d-flex align-items-center" *ngIf="parameter.childParameters.length === 0">
  <div class="col-5 col-form-label text-right">
    {{parameter.label}}
  </div>
  <div class="col-5">
    <button type="button" class="btn btn-primary" data-toggle="modal"
      [attr.data-target]="'#modalStringFilled-' + parameter.code" [disabled]="readonly"
      (click)="onClick()">
      {{'SHARED.EDIT' | translate}}
    </button>
    <div class="values no-uppercase">
      {{selectedOptionsText}}
    </div>
  </div>
  <div class="col-2">
    <!--<a role="button" *ngIf="customManualEnabled" class="btn btn-outline-warning btn-sm">+Add</a>-->
  </div>
</div>

<!-- Modal -->
<div class="modal fade myModal" [id]="'modalStringFilled-' + parameter.code" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">{{parameter.label}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body clearfix">
        <span class="title">{{'GS1-ELEMENT-STRING.NAME' | translate}}</span>
        <span class="range">
          {{'GS1-ELEMENT-STRING.OUTPUT' | translate}} [{{'SHARED.MIN' | translate}} {{parameter.minLen}} - {{'SHARED.MAX' | translate}} {{parameter.maxLen}}]
        </span>
        <span class="range">
          {{'GS1-ELEMENT-STRING.INPUT' | translate}} [{{'SHARED.MIN' | translate}} {{parameter.minLen}} - {{'SHARED.MAX' | translate}} {{parameter.maxLen}}]
        </span>
        <div class="possible-values-list">
          <div>
            <input type="text" id="searchInputGS1Element" name="searchInputGS1Element" placeholder="Search..." value="" (change)="searchListGS1Element($event)"/>
          </div>
          <div class="possible-values">
            <div *ngFor="let option of commonFieldsAfterSearch" (click)="selectOptionToInputAndOutput(option)">
              {{option['@name']}}
            </div>
          </div>
          <span class="title">{{'GS1-ELEMENT-STRING.SEPARATOR' | translate}}</span>
          <div class="possible-values">
            <div *ngFor="let option of separatorFields; let i = index" (click)="selectOption(i, '01')">
              {{option}}
            </div>
          </div>
        </div>
        <div class="selected-values-container">
          <div class="buttons clearfix">
            <div class="button" (click)="moveUpInputOption()"
              [ngClass]="{disabled: activeInputOptions.indexOf(0) >= 0 || activeInputOptions.length === 0}">
              <img src="assets/images/move-up.svg" />
            </div>
            <div class="button" (click)="moveDownInputOption()"
              [ngClass]="{disabled: activeInputOptions.indexOf(selectedInputOptions.length - 1) >= 0 || activeInputOptions.length === 0}">
              <img src="assets/images/move-down.svg" />
            </div>
            <div class="button delete" (click)="deleteInputActiveOptions()"
              [ngClass]="{disabled: activeInputOptions.length === 0}">
              <img src="assets/images/delete.svg" />
            </div>
            <div class="button" (click)="addOutputOptions()"
              [ngClass]="{disabled: activeInputOptions.length === 0}">
              <img src="assets/images/right-arrow-svgrepo-com.svg" />
            </div>
          </div>
          <div class="selected-values" cdkDropList [cdkDropListData]="selectedInputOptions" (cdkDropListDropped)="drop($event)" id="input-list">
            <div class="input-box"
                *ngFor="let option of selectedInputOptions; let i = index"
                (cdkDragStarted)="onDragStart('input')"
                 cdkDrag
                 [ngClass]="{active: activeInputOptions.indexOf(i) >= 0}"
                 (click)="toggleInputActiveOption(i)">
                 <div class="custom-placeholder" *cdkDragPlaceholder></div>
              {{option.name}}
            </div>
          </div>
        </div>
        <div class="selected-values-container">
          <div class="buttons clearfix">
            <div class="button" (click)="moveUpOutputOption()"
              [ngClass]="{disabled: activeOutputOptions.indexOf(0) >= 0 || activeOutputOptions.length === 0}">
              <img src="assets/images/move-up.svg" />
            </div>
            <div class="button" (click)="moveDownOutputOption()"
              [ngClass]="{disabled: activeOutputOptions.indexOf(selectedOutputOptions.length - 1) >= 0 || activeOutputOptions.length === 0}">
              <img src="assets/images/move-down.svg" />
            </div>
            <div class="button delete" (click)="deleteOutputActiveOptions()"
              [ngClass]="{disabled: activeOutputOptions.length === 0}">
              <img src="assets/images/delete.svg" />
            </div>
            <div class="add-label-gs1-toggel"
              [ngClass]="{disabled: selectedOutputOptions.length === 0}">
              <input type="checkbox" id="addLabelEnableCheckbox" name="addLabelEnableCheckbox" value="addLabelEnableCheckboxValue" [checked]="addLabelEnable" (change)="addLabelEnableToggle($event)">
              <label id="addLabelEnableLabel" for="addLabelEnableCheckbox">Add Label</label><br>
            </div>
          </div>
          <div class="selected-values" cdkDropList [cdkDropListData]="selectedOutputOptions" (cdkDropListDropped)="drop($event)" id="output-list">
            <div class="output-box"
              *ngFor="let option of selectedOutputOptions; let i = index" 
              [ngClass]="{active: activeOutputOptions.indexOf(i) >= 0}"
              (cdkDragStarted)="onDragStart('output')"
              cdkDrag
              (click)="toggleOutputActiveOption(i)">
              <div class="custom-placeholder" *cdkDragPlaceholder></div>
              {{addLabelEnable && option.value.substring(0,2) === "03" ? '"' + option.name + '":' + option.name : option.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-warning" (click)="close()"
          data-dismiss="modal">{{'SHARED.CLOSE' | translate}}</button>
        <button type="button" class="btn btn-warning" data-dismiss="modal" (click)="saveChanges(true)">{{'SHARED.SAVE-CHANGES' | translate}}</button>
        <button type="button" class="btn btn-warning" (click)="clearAll()">{{'SHARED.CLEAR-ALL' | translate}}</button>
      </div>
    </div>
  </div>
</div>
