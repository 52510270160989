<button type="button" class="btn btn-info btn-lg d-none" data-toggle="modal" [attr.data-target]="'#' + identifier"
    [id]="identifier + 'Button'"></button>

<!-- Modal -->
<div [id]="identifier" class="modal fade rounded-lg" role="dialog">
    <div class="modal-dialog">

        <!-- ===============================================================================================================
            LOAD
        ================================================================================================================= -->
        <div class="modal-content file_operator_modal" *ngIf="type == 'load'">
            <div class="modal-header">
                <h4 class="modal-title">{{title}}</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="form-group">
                        <label for="select_file">File directory</label>
                        <div class="d-flex">
                            <input type="text" class="form-control" id="select_file" [placeholder]="acceptedFiles">
                            <button class="btn btn-success text-white my_button">Upload File</button>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputPassword1">Password</label>
                        <input type="password" class="form-control" id="exampleInputPassword1">
                    </div>
                    <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" id="exampleCheck1">
                        <label class="form-check-label" for="exampleCheck1">Check me out</label>
                    </div>
                    <button type="submit" class="btn btn-primary">Submit</button>
                </form>
            </div>
            <div class="modal-footer">
                <div class="col-6 text-right">
                    <a role="button" class="btn btn-outline-warning" style="white-space: nowrap;" data-dismiss="modal">
                        {{'SHARED.OK' | translate}}
                    </a>
                </div>
            </div>
        </div>

        <!-- ===============================================================================================================
            READ FRS
        ================================================================================================================= -->
        <div class="modal-content file_operator_modal" *ngIf="type == 'read'">
            <div class="modal-header">
                <h4 class="modal-title">{{title}}</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <form *ngIf="type == 'read'">
                    <div class="form-group row">
                        <label for="ccf_customer_name" class="text-right col-4">Customer Name</label>
                        <div class="col-8">
                            <input type="text" class="form-control" id="ccf_customer_name" [placeholder]="" [value]="" #ccfCustomerName>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="ccf_config_name" class="text-right col-4">Config Name</label>
                        <div class="col-8">
                            <input type="text" class="form-control" id="ccf_config_name" [value]="frsCcfInfo.productName" #ccfConfigName data-toggle="tooltip" data-placement="top" [title]="'FILE-OPERATOR.OVERRIDE_TAG_0060_WARNING' | translate">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="ccf_interfaces" class="text-right col-4">Active Interface</label>
                        <div class="col-8">
                            <select class="form-control" id="ccf_interfaces" #ccfInterfaces>
                                <option *ngFor="let interface of frsCcfInfo.interfaces" [value]="interface" [selected]="interface === frsCcfInfo.initActiveInterface">
                                    {{interface}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row" [hidden]="!isSecure">
                        <label for="ccf_copy_level" class="text-right col-4">Copy Level</label>
                        <div class="col-8">
                            <select class="form-control" id="ccf_copy_level" [(ngModel)]="copyLevelModel" (change)="onChangeCopyLevel($event)">
                                <option *ngFor="let copyLevel of frsCcfInfo.copyLevels">
                                    {{copyLevel}}
                                </option>
                            </select>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer d-flex justify-content-right">
                <a role="button" class="btn btn-warning my_button" style="white-space: nowrap;" data-dismiss="modal" (click)="onFrsOkClicked()">
                    {{'SHARED.OK' | translate}}
                </a>
                <a role="button" class="btn btn-outline-warning my_button" style="white-space: nowrap;" data-dismiss="modal">
                    {{'SHARED.CANCEL' | translate}}
                </a>
            </div>
        </div>

    </div>
</div>