import { ConfigurationElementType } from './enums/configuration-element-type';
import { Parameter } from './parameters';

export class ConfigurationElement {
  public name: string;
  public text: string;
  public guiType: string;
  public order: number;
  public type: ConfigurationElementType;
  public parameter: Parameter;
  public code: string;
  public maxSize: string = "";
  public packageSize: string = "";
  public hideValue: boolean;
  public satellitePairingRemoving: string = "";
  public satelliteClearAll: string = "";
  public satelliteListAll: string = "";
  public satelliteCheckStatus: string = "";
  public satelliteDeviceListAll: string = "";

  constructor(name: string, text: string, guiType: string, order: number, code: string,
              type: ConfigurationElementType, parameter: Parameter, maxSize: string = "", packageSize: string = "", hideValue: boolean,
              satellitePairingRemoving: string = "", satelliteClearAll: string = "", satelliteListAll: string = "", satelliteCheckStatus: string = "", satelliteDeviceListAll: string = "") {
    this.name = name;
    this.text = text  ;
    this.guiType = guiType;
    this.order = order;
    this.code = code;
    this.type = type;
    this.parameter = parameter;
    this.maxSize = maxSize;
    this.packageSize = packageSize;
    this.hideValue = hideValue;
    this.satellitePairingRemoving = satellitePairingRemoving;
    this.satelliteClearAll = satelliteClearAll;
    this.satelliteListAll = satelliteListAll;
    this.satelliteCheckStatus = satelliteCheckStatus;
    this.satelliteDeviceListAll = satelliteDeviceListAll;
  }
}
