import { ParamMenuItem } from '../param-menu-item';
import { ConfigurationPage } from '../configuration-page';
import { Parameter, ParameterRule } from '../parameters';
import { Command, Helper, Message } from '../utility';
import { SearchItem } from '../utility/search-item';
import { uleModeEnum } from '../enums/ule.enum';
import { CCF_SETTINGS_DEFAULT_DATA, SettingGroup } from './setting.type';

export class ProductData {
  public name: string;
  public properties: Map<string, string>;
  public paramMenu: ParamMenuItem[];
  public configurationPages: ConfigurationPage[];
  public parameters: Parameter[];
  public preCommands: Command[];
  public postCommands: Command[];
  public messages: Message[];
  public currentConfigFile = '';
  public currentUle = '';
  public uleMode: uleModeEnum = uleModeEnum.ADV_FORMAT;
  public currentCertificate = '';
  public parsingResult: any;
  public smartBattery: any[] = [];
  public statisticPage = [];
  public interfaceClasses: Object;
  public connection: string = "";
  public searchHistory: SearchItem[] = [];
  public CCFSettingsData: SettingGroup[] = JSON.parse(JSON.stringify(CCF_SETTINGS_DEFAULT_DATA));

  constructor(name: string, properties: Map<string, string>, paramMenu: ParamMenuItem[], configurationPages: ConfigurationPage[],
              parameters: Parameter[], preCommand: Command[], postCommand: Command[], messages: Message[], parsingResult: any,
              interfaceClasses: Object, connection: string) {
    this.name = name;
    this.properties = properties;
    this.paramMenu = paramMenu;
    this.configurationPages = configurationPages;
    this.parameters = parameters;
    this.preCommands = preCommand;
    this.postCommands = postCommand;
    this.messages = messages;
    this.parsingResult = parsingResult;
    this.interfaceClasses = interfaceClasses;
    this.connection = connection;

    for (const parameter of this.parameters) {
      this.executeParameterRules(parameter.name);
    }
  }

  public executeParameterRules(name: string, recursion: boolean = false): void {
    // Get the parameter
    const parameter = this.parameters.find(it => it.name === name);
    if (parameter) {
      // Go through all rules of this parameter
      for (const rule of parameter.rulesAsSender) {
        this.executeParameterRule(parameter, rule, recursion);
      }
    }
  }
  private executeParameterRule(parameter: Parameter, rule: ParameterRule, recursion: boolean): void {
    // If i satisfy the rule execute it on the target parameter/s
    if (rule.condition === 'equalTo') {
      const compliant = rule.senderAcceptedValues.find(
        it => it.parameter === parameter.name && it.valueToCheck === parameter.value) !== undefined;

      let targets = this.parameters.filter(it =>
        rule.targets.find(t => Helper.isParameterAffectedFromRule(it.name, t) === true) !== undefined);

      targets = targets.filter(it => rule.sender.find(s => it.name === s) === undefined);
      for (const target of targets) {
        target.executeRule(rule, compliant);
      }

      if (recursion) {
        for (const target of targets) {
          this.executeParameterRules(target.name);
        }
      }
    } else if (rule.condition === 'multiEqualTo') {
      const senders = this.parameters.filter(it =>
        rule.sender.find(t => Helper.isParameterAffectedFromRule(it.name, t) === true) !== undefined);

      const paramGroupBy = rule.senderAcceptedValues.reduce((r, a) => {
        r[a.parameter] = [...r[a.parameter] || [], a];
        return r;
      }, {});

      const compliant =
        Object.keys(paramGroupBy).filter(it =>
          senders.find(s =>
            paramGroupBy[it].find(av => s.name === av.parameter && s.value === av.valueToCheck)))
        .length === Object.keys(paramGroupBy).length;

      let targets = this.parameters.filter(it =>
        rule.targets.find(t => Helper.isParameterAffectedFromRule(it.name, t) === true) !== undefined);

      targets = targets.filter(it => senders.find(s => s.name === it.name) === undefined);
      for (const target of targets) {
        target.executeRule(rule, compliant);
      }

      if (recursion) {
        for (const target of targets) {
          this.executeParameterRules(target.name);
        }
      }
    } else {
      console.log(rule.condition);
    }
  }
}
