import { Component, ElementRef, OnInit,Input, Output, EventEmitter, ViewChild } from '@angular/core';

import * as ace from "ace-builds";

import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/mode-javascript';
@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {
  @ViewChild('codeEditor', { static: true }) codeEditorElmRef!: ElementRef;
  codeEditor!: ace.Ace.Editor;
  @Input() code: string = '';
  @Output() closeModal = new EventEmitter<void>();
  @Output() save = new EventEmitter<string>();

  ngOnInit(): void {
    this.initializeEditor();
  }

  initializeEditor(): void {
    const element = this.codeEditorElmRef.nativeElement;
    this.codeEditor = ace.edit(element);

    this.codeEditor.setTheme('ace/theme/github');
    this.codeEditor.getSession().setMode('ace/mode/crystal');
    this.codeEditor.setOptions({
      fontSize: '13pt',
      printMargin: false,
    });

    // Set the initial content
    this.codeEditor.setValue(this.code);
  }

  close(): void {
    this.closeModal.emit();
  }

  saveCode(): void {
    this.code = this.codeEditor.getValue();
    this.save.emit(this.code); // Emit the saved code
    this.close();
  }
}
