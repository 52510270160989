import { UleModeComponent } from './../ule-mode/ule-mode.component';
import { UleService } from './../../services/ule/ule.service';
import { Component, OnInit, ViewChild, ElementRef, Input, OnChanges, SimpleChanges, AfterViewInit, Output, EventEmitter } from '@angular/core';
import * as ace from 'ace-builds';

import 'ace-builds/src-noconflict/mode-assembly_x86';
import 'ace-builds/src-noconflict/mode-crystal';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-github';
import { Parameter } from 'src/app/classes/parameters';
import { ProductComponent } from 'src/app/pages/product/product.component';
import { uleModeEnum } from 'src/app/classes/enums/ule.enum';
import { NotificationComponent } from '../notification/notification.component';
import { Notification, NotificationType } from 'src/app/classes/utility/notification';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-code-editor',
  templateUrl: './code-editor.component.html',
  styleUrls: ['./code-editor.component.css']
})
export class CodeEditorComponent implements AfterViewInit, OnChanges {
  @Input() type = '';
  @Input() text = '';
  @Input() title = '';
  @Input() modifiedParameters: Parameter[] = [];
  @Input() productUleModeComponent: UleModeComponent;
  @Input() notification: NotificationComponent;
  @Output() valueChanged: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('codeEditor') codeEditorElmRef: ElementRef;

  private codeEditor: any;
  private isFirstLoad: boolean = true;

  constructor(private uleService: UleService, private settingsService: SettingsService) { }

  ngAfterViewInit(): void {
    ProductComponent.isActiveHover = false;
    this.refreshEditor();
    this.refreshText();
    if(this.type === "ule"){
      this.uleService.isInUleEditor = true;
      const afParams = this.modifiedParameters.filter(it => it.isPackParameter);
      if(this.uleService.currentUle.mode === uleModeEnum.CUSTOM){
        this.setEditable(true);
      }else{
        this.setEditable(false);
      }
      this.codeEditor.container.addEventListener("keydown", (event: KeyboardEvent) => {
        this.onKeyDown(event);
      });
      this.isFirstLoad = false;
    }
  }

  ngOnDestroy(): void{
    ProductComponent.isActiveHover = true;
    this.uleService.isInUleEditor = false;
    this.codeEditor.container.removeEventListener("keydown", (event: KeyboardEvent)=>{
      this.onKeyDown(event);
    });
    this.isFirstLoad = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.type && changes.type.currentValue !== changes.type.previousValue) {
      this.refreshEditor();
    }

    let codeEditorCode = '';
    if (this.codeEditor) {
      codeEditorCode = this.codeEditor.getSession().getValue();
    }
    if (changes.text && changes.text.currentValue !== codeEditorCode) {
      this.refreshText();
    }
  }

  private refreshEditor(): void {
    if (!this.codeEditorElmRef) {
      return;
    }

    const element = this.codeEditorElmRef.nativeElement;

    const options = {
      minLines: 30,
      maxLines: 30,
      highlightSelectedWord: true,
      showPrintMargin: false
    };

    this.codeEditor = ace.edit(element, options);
    this.codeEditor.setTheme('ace/theme/github');
    if (this.title === 'User Scripting JS') {
      this.codeEditor.getSession().setMode('ace/mode/javascript');
    } else if (this.type === 'ule') {
      this.codeEditor.getSession().setMode('ace/mode/crystal');
    } else {
      this.codeEditor.getSession().setMode('ace/mode/assembly_x86');
    }
    this.codeEditor.setShowFoldWidgets(true);
    this.codeEditor.getSession().on('change', () => {
      this.valueChanged.emit(this.codeEditor.getSession().getValue());
    });
  }

  private refreshText(): void {
    if (!this.codeEditorElmRef) {
      return;
    }

    if (this.codeEditor) {
      this.codeEditor.getSession().setValue(this.text, -1);
    } else {
      this.codeEditorElmRef.nativeElement.innerHTML = this.text;
    }
  }

  setEditable(value: boolean): void{
    this.codeEditor.setReadOnly(!value);
  }

  onKeyDown(event: KeyboardEvent): void{
    if(this.isFirstLoad){
      return;
    }
  const isCtrl = event.ctrlKey && event.key === 'Control';
  const isCtrlA = event.ctrlKey && event.key === 'a';
  const isCtrlC = event.ctrlKey && event.key === 'c';
  if (isCtrlA || isCtrlC || isCtrl) {
    return; 
  }
    if(this.uleService.currentUle.mode === uleModeEnum.CUSTOM){
      // do nothing
    }else{
      this.productUleModeComponent.setMode(uleModeEnum.CUSTOM);
      this.codeEditor.focus();
    }
  }
}
