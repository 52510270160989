export enum NotificationType {
    Error = "Error",
    Warning = "Warning",
    Success = "Success",
    Confirm = "Confirm",
    Info = "Info",
    Option = "Option"
}

export enum NotificationSizeEnum{
    SM = "sm",
    DEFAULT = "",
    LARGE = "lg",
    EXTRA_LARGE = "xl"
}

export type NoShowAgain = {
    isUsed: boolean;
    checked: boolean;
    message: string;
}

export type NotificationResult = {
    select: string;
    noShowAgain: boolean;
}

export class Notification{
    public type: NotificationType;
    public title: string;
    public message: string;
    public size: NotificationSizeEnum;
    public noShowAgain: NoShowAgain = {
        isUsed: false,
        checked: false,
        message: "Do not show this again."
    };
    
    constructor(_type: string | NotificationType,_message: string, _title: string = "", _size: string | NotificationSizeEnum = NotificationSizeEnum.DEFAULT){
        this.message = _message;
        this.title = _title;

        if(this.isString(_type)){
            switch(_type){
              case "Warning":{
                this.type = NotificationType.Warning;
                break;
              }
              case "Success":{
                this.type = NotificationType.Success;
                break;
              }
              case "Confirm":{
                this.type = NotificationType.Confirm;
                break;
              }
              case "Info":{
                this.type = NotificationType.Info;
                break;
              }
              case "Option":{
                this.type = NotificationType.Option;
                break;
              }
              default:{
                this.type = NotificationType.Error;
                break;
              }
            }
          }else{
            this.type = _type;
          }
      
          if(this.isString(_size)){
            switch(_size){
              case "sm":{
                this.size = NotificationSizeEnum.SM;
                break;
              }
              case "lg":{
                this.size = NotificationSizeEnum.LARGE;
                break;
              }
              case "xl":{
                this.size = NotificationSizeEnum.EXTRA_LARGE;
                break;
              }
              default:{
                this.size = NotificationSizeEnum.DEFAULT;
                break;
              }
            }
          }else{
            this.size = _size;
          }
      
          if(_title === ""){
            switch (this.type) {
              case NotificationType.Success:
                this.title = "Success";
                break;
              case NotificationType.Confirm:
                this.title = "Confirm";
                break;
              case NotificationType.Warning:
                this.title = "Warning";
                break;
              case NotificationType.Info:
                this.title = "Information";
                break;
              case NotificationType.Option:
                this.title = "Option ";
                break;
              default:
                this.title = "Error";
                break;
            }
          }
    }

    isString(data: unknown): data is string {
        return typeof data === 'string';
    };
}