<div id="wrapper-news" [hidden]="newsCondition || standaloneMode">
  <main id="main-news" role="main" class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="internal-wrapper">
          <section id="news" class="mb-5">
            <h3 class="mb-5">{{ 'NEWS.TITLE' | translate }}</h3>
            <div class="row">

              <div class="nws-item col-12 col-md-6 col-xl-3 mb-5">
                <div class="media-item embed-responsive embed-responsive-16by9 mb-3">
                  <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/OHKoHXkGmVA?rel=0" allowfullscreen></iframe>
                </div>
              </div>

              <div class="nws-item col-12 col-md-6 col-xl-3 mb-5">
                <div class="media-item embed-responsive embed-responsive-16by9 mb-3">
                  <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/AJsrdsOzIqw?rel=0" allowfullscreen></iframe>
                </div>
              </div>
          
              <div class="nws-item col-12 col-md-6 col-xl-3 mb-5">
                <div class="media-item embed-responsive embed-responsive-16by9 mb-3">
                  <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/yURudelqU54?rel=0" allowfullscreen></iframe> 
                </div>
              </div>
          
              <div class="nws-item col-12 col-md-6 col-xl-3 mb-5">
                <div class="media-item embed-responsive embed-responsive-16by9 mb-3">
                  <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/OXmTthAxnkY?rel=0" allowfullscreen></iframe>
                </div>
              </div>
          
            </div>
          </section>
        </div>
      </div>
    </div>
  </main>
</div>

