export class FrsCcfInfo{
    constructor(
        public interfaces: string[] = [],
        public initActiveInterface: string = "",
        public productName: string = "",
        public copyLevels: string[] = []
    ){}
}

export class FrsCcfReceivedInfo{
    constructor(
        public customerName: string = "",
        public configName: string = "",
        public activeInterface = "",
        public copyLevel = ""
    ){}
}