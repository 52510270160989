import { Product } from '../products/product';

export class TabItem {
  public url: string;
  public name: string;
  public id: string;
  public mode: string = '';
  public connectedTo: string;
  public terminalContents: string[] = [];
  public isFRS: boolean;

  constructor(
    id: string,
    url: string,
    name: string,
    mode: string = '',
    connectedTo: string,
    isFRS: boolean,

  ) {
    this.id = id;
    this.url = url;
    this.name = name;
    this.mode = mode ? ' [' + mode.toUpperCase() + ']' : '';
    this.connectedTo = connectedTo;
    this.isFRS = isFRS;
  }

  static fromProduct(
    product: Product,
    standaloneMode: boolean,
    connectedTo: string,
    mode: string = '',
    serialNumber: string = '',
    isFRS: boolean
  ): TabItem {
    let queryParams = serialNumber ? '?id=' + serialNumber : '';

    queryParams = queryParams.slice(0, queryParams.length);
    const url =
      '/configuration/product/' +
      product.index +
      (connectedTo && connectedTo !== 'OFFLINE' ? '/' + connectedTo : '') +
      (mode ? '/' + mode : '') +
      queryParams;

    let connection: string = '';

    if (connectedTo === null) {
      if (standaloneMode === true) {
        connection = ' [OFFLINE] ';
      } else {
        connection = '';
      }
    }
    else {
      connection = ' [' + connectedTo + '] ';
    }

    return new this(product.internalName, url, product.name, mode, connection,isFRS);
  }
}
