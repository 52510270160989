import { BehaviorSubject, Observable } from 'rxjs';
import { TaskResponse, WriteCommand } from './device';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  private writeResponse: BehaviorSubject<TaskResponse> = new BehaviorSubject<TaskResponse>('');

  constructor() { 
    window.deviceService = {
      setWriteResponse: (data: string) => this.setWriteResponse(data as TaskResponse),
    }
  }

  writeConfiguration(cmds: WriteCommand[]): Observable<TaskResponse> {
      let data: string = "";
      for(let i = 0; i < cmds.length; i++){
        data += cmds[i].code + cmds[i].value + ',';
      }
      window.deviceService_writeConfiguration(data);
      return this.writeResponse;
  }

  setWriteResponse(data: TaskResponse): void{
    this.writeResponse.next(data);
  }
}
