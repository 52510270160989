import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, AfterContentInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { debounceTime, last } from 'rxjs/operators';

declare var $: any;
@Component({
  selector: 'app-cloud-save-config',
  templateUrl: './cloud-save-config.component.html',
  styleUrls: ['./cloud-save-config.component.css']
})
export class CloudSaveConfigComponent implements OnInit, AfterContentInit {

  @Input() webApiRes = [];
  @Output() blobSave: EventEmitter<string> = new EventEmitter<string>();
  @Output() blobLoad: EventEmitter<string> = new EventEmitter<string>();
  @Output() blobDelete: EventEmitter<string[]> = new EventEmitter<string[]>();
  listSelectedBlod = "";
  selectedIndex = -1;
  listBlobFilter = [];
  listBlobFiltterFormGroup: UntypedFormGroup;
  formArrCheckBox: UntypedFormArray;

  constructor() {
    this.listBlobFiltterFormGroup = new UntypedFormGroup({
      id: new UntypedFormControl(""),
      name: new UntypedFormControl(""),
      product: new UntypedFormControl(""),
      releaseSW: new UntypedFormControl(""),
      lastUpdateFrom: new UntypedFormControl(""),
      lastUpdateTo: new UntypedFormControl("")
    })
    this.formArrCheckBox = new UntypedFormArray([]);
  }
  ngAfterContentInit(): void {
    $( "#datepickerFrom" ).datepicker({
      dateFormat: "mm-dd-yy",
      changeMonth: true,
      changeYear: true,
      yearRange: ((new Date()).getFullYear() - 40).toString() + ":" + (new Date()).getFullYear().toString(),
      duration: "fast",
      onSelect: (data: string, inst: any) => {
        this.listBlobFiltterFormGroup.get("lastUpdateFrom").setValue(data);
      }
    });
    $( "#datepickerTo" ).datepicker({
      dateFormat: "mm-dd-yy",
      duration: "fast",
      changeMonth: true,
      changeYear: true,
      yearRange: ((new Date()).getFullYear() - 40).toString() + ":" + (new Date()).getFullYear().toString(),
      onSelect: (data: string, inst: any) => {
        this.listBlobFiltterFormGroup.get("lastUpdateTo").setValue(data);
      }
    });
  }

  ngOnInit(): void {
    this.listBlobFilter = this.webApiRes;
    this.formArrCheckBox = new UntypedFormArray(this.initFormArray());

    this.listBlobFiltterFormGroup.valueChanges.pipe(
      debounceTime(500)
    ).subscribe((value)=> {
      this.listBlobFilter = this.getBlobListFilter();
      this.formArrCheckBox = new UntypedFormArray(this.initFormArray());
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.webApiRes) {
      this.listBlobFilter = this.getBlobListFilter();
      this.formArrCheckBox = new UntypedFormArray(this.initFormArray());
    }
  }

  show(): void {
    const element: HTMLElement = document.getElementById('cloudSaveConfigWindowButton');
    if (element) {
      element.click();
    }
  }
  
  hide(): void {
    const element: HTMLElement = document.getElementById('cloudSaveConfigWindowClose');
    if (element) {
      element.click();
    }
  }

  saveConfigToCloud(): void {
    let nameConf: string =  (<HTMLInputElement>document.getElementById("inputCloudSaveConfigName")).value;
    this.blobSave.emit(nameConf);
  }
  
  loadConfigFromCloud(): void {
    let nameConf: string =  (<HTMLInputElement>document.getElementById("inputCloudLoadConfigName")).value;
    this.blobLoad.emit(nameConf);
  }

  deleteConfigFromCloud(): void {
    const element: HTMLElement = document.getElementById('confirmDeleteButton');
    if (element) {
      element.click();
    }
  }

  getValueIsSelected() {
    const getRawValue = this.formArrCheckBox.getRawValue();
    return this.listBlobFilter.map((value) => value.id).filter((value, index) => getRawValue[index]);
  }

  clickOk() {
    const result = this.getValueIsSelected();

    this.listSelectedBlod = "";
    this.selectedIndex = -1;
    const element: HTMLElement = document.getElementById('closeDeleteModal');
    element.click();
    this.blobDelete.emit(result);
  }

  selectTrConfigElement(blobId: string, blodIndex: number): void {

    if (this.listSelectedBlod === blobId) {
      this.listSelectedBlod = "";
      this.selectedIndex = -1;
    } else {
      this.listSelectedBlod = blobId;
      this.selectedIndex = blodIndex;
    }

    let inputElement : any = document.getElementById("inputCloudLoadConfigName") as HTMLInputElement | null
    inputElement.value = this.listSelectedBlod;
  }

  getBlobListFilter() {
    const getRawValue = this.listBlobFiltterFormGroup.getRawValue();
    return this.webApiRes.filter((value) => {
      let dateItem = value.lastUpdate.split("T")[0].split("-");
      let dateFrom = getRawValue.lastUpdateFrom.split("-");
      let dateTo = getRawValue.lastUpdateTo.split("-");

      return  value && value.id.includes(getRawValue.id) && value.name.includes(getRawValue.name) && value.product.includes(getRawValue.product) &&
              value.releaseSW.includes(getRawValue.releaseSW) && (!getRawValue.lastUpdateFrom || 
              new Date(dateItem[0], dateItem[1], dateItem[2]).getTime() >= new Date(dateFrom[2], dateFrom[0], dateFrom[1]).getTime()) && (!getRawValue.lastUpdateTo || 
              new Date(dateItem[0], dateItem[1], dateItem[2]).getTime() <= new Date(dateTo[2], dateTo[0], dateTo[1]).getTime());
    })
  }

  initFormArray() {
    return this.listBlobFilter.map((value) => new UntypedFormControl(false));
  }

  changeInputSelectAll(event: any) {
    const check = event.target.checked;
    this.formArrCheckBox.controls.forEach((value, index) => {
        this.formArrCheckBox.at(index).setValue(check);
    })
  }
}
