import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Helper } from 'src/app/classes/utility';

interface Version {
  number: string;
  url: string;
  current: boolean;
}

interface DownloadOption {
  name: string;
  default: boolean;
  versions: Version[];
}

@Component({
  selector: 'app-download-component',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css']
})
export class DownloadComponent implements OnInit {
  downloadOptions: DownloadOption[] = [];
  selectedSystem: string = '';
  selectedVersion: string = '';
  downloadLink: string = '';

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.loadDownloadOptions();
  }

  loadDownloadOptions(): void {
    this.http.get<DownloadOption[]>(Helper.dataDirectory + 'download.json')
      .subscribe(
        (data: DownloadOption[]) => {
          this.downloadOptions = data;
          this.setDefaultSelections();
        },
        error => {
          console.error('Error loading download options:', error);
        }
      );
  }

  setDefaultSelections() {
    const defaultSystem = this.downloadOptions.find(option => option.default);
    if (defaultSystem) {
      this.selectedSystem = defaultSystem.name;
      const currentVersion = defaultSystem.versions.find(v => v.current);
      if (currentVersion) {
        this.selectedVersion = currentVersion.number;
        this.updateDownloadLink();
      }
    }
  }

  onSystemChange() {
    const selectedOption = this.downloadOptions.find(option => option.name === this.selectedSystem);
    if (selectedOption) {
      const currentVersion = selectedOption.versions.find(v => v.current);
      if (currentVersion) {
        this.selectedVersion = currentVersion.number;
        this.updateDownloadLink();
      }
    }
  }

  onVersionChange() {
    this.updateDownloadLink();
  }

  updateDownloadLink() {
    const selectedOption = this.downloadOptions.find(option => option.name === this.selectedSystem);
    if (selectedOption) {
      const selectedVersionObj = selectedOption.versions.find(v => v.number === this.selectedVersion);
      if (selectedVersionObj) {
        this.downloadLink = selectedVersionObj.url;
      }
    }
  }

  submitDownload() {
    if (this.downloadLink) {
      window.location.href = this.downloadLink;
    }
  }

  getVersions(systemName: string): Version[] {
    const selectedSystem = this.downloadOptions.find(option => option.name === systemName);
    return selectedSystem ? selectedSystem.versions : [];
  }

  show() {
    const element = document.getElementById('openDownloadPageBtn');
    if (element) element.click();
  }
}