import { AfterViewChecked, Component, input, output } from '@angular/core';
import { SettingGroupItem } from 'src/app/classes/products/setting.type';

@Component({
  selector: 'selection-group',
  templateUrl: './selection-group.component.html',
  styleUrl: './selection-group.component.css'
})
export class SelectionGroupComponent implements AfterViewChecked {
  title = input<string>('Group Title');
  description = input<string>("Group Description");
  default = input<SettingGroupItem>();
  settingItems = input<SettingGroupItem[]>();
  valueChanged = output<string>();

  constructor() {
  }

  ngAfterViewChecked(): void {
  }
  
  onRadioSelect(value: any):void {
    if(value.target.value){
      this.valueChanged.emit(value.target.value);
    }
  }
}
