import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarRef, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Component, inject } from '@angular/core';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrl: './snackbar.component.css'
})
export class SnackbarComponent {
  private snackbar = inject(MatSnackBar);

  open(message: string, 
               action: string = "Close", 
               duration: number = 5000,
               verticalPosition: MatSnackBarVerticalPosition = 'bottom',
               horizontalPosition: MatSnackBarHorizontalPosition = 'center'): MatSnackBarRef<any> {
    const ref = this.snackbar.open(message, action, {
      duration: duration,
      verticalPosition: verticalPosition,
      horizontalPosition: horizontalPosition,
    });
    return ref;
  }
}
