import { Component, OnInit, Input, ViewChild, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomManualItem, Command } from 'src/app/classes/utility';
import { CustomManualService } from 'src/app/services/custom-manual.service';
import { PdfContentComponent } from '../pdf-content/pdf-content.component';
import { Parameter } from 'src/app/classes/parameters';

type State = 'normal' | 'edit';

@Component({
  selector: 'app-custom-manual',
  templateUrl: './custom-manual.component.html',
  styleUrls: ['./custom-manual.component.css']
})
export class CustomManualComponent implements OnInit {
  @ViewChild('pdfContent') pdfContent: PdfContentComponent;
  @Input() preCommands: Command[] = [];
  @Input() postCommands: Command[] = [];
  @Input() commands: Command[] = [];
  @Input() productName = '';
  @Input() family = '';
  @Input() preCmd: string = 'C';
  @Input() enterConfigurationCmd: string = 'P';
  @Input() exitConfigurationCmd: string = 'P';
  @Input() modifiedParams: Parameter[] = [];

  state = signal<State>('normal');

  customManual: CustomManualItem[] = [];
  customManualCommands: Command[] = [];
  step = 1;
  type = '';
  concatenate = true;
  showConfiguration = false;
  showProgrammingModeLabel = false;
  customecreatingPdf = false;
  customselectedFileType = true;

  currentMargin = { text: this.translate.instant('SHARED.STANDARD'), value: 100 };
  marginValues = [{ text: this.translate.instant('SHARED.SMALL'), value: 50}, { text: this.translate.instant('SHARED.STANDARD'), value: 100 }, { text: this.translate.instant('SHARED.MEDIUM'), value: 200 }, { text: this.translate.instant('SHARED.LARGE'), value: 300 }, { text: this.translate.instant('SHARED.BIG'), value: 400 }, { text: this.translate.instant('SHARED.HUGE'), value: 500 }]

  currentHeight = { text: this.translate.instant('SHARED.STANDARD'), value: 100 };
  heightValues = [{ text: this.translate.instant('SHARED.SMALL'), value: 75 }, { text: this.translate.instant('SHARED.STANDARD'), value: 100 }, { text: this.translate.instant('SHARED.MEDIUM'), value: 125 }, { text: this.translate.instant('SHARED.LARGE'), value: 150 }, { text: this.translate.instant('SHARED.HUGE'), value: 200 }]

  currentWidth = { text: this.translate.instant('SHARED.NORMAL'), value: 2 };
  widthValues = [{ text: this.translate.instant('SHARED.SMALL'), value: 1 }, { text: 'Normal', value: 2 }, { text: this.translate.instant('SHARED.MEDIUM'), value: 3 }, { text: this.translate.instant('SHARED.LARGE'), value: 4 }]

  currentMaxCommand = { text: this.translate.instant('SHARED.UNCONFIGURED'), value: 0 };
  maxCommandValues = [{ text: this.translate.instant('SHARED.UNCONFIGURED'), value: 0 }, { text: '20', value: 20 }, { text: '30', value: 30 }, { text: '40', value: 40 }, { text: '100', value: 100 }, { text: '200', value: 200 },
                      { text: '300', value: 300 }, { text: '400', value: 400 }, { text: '500', value: 500 }, { text: '600', value: 600 }, { text: '700', value: 700 }, { text: '800', value: 800 }]

  constructor(private customManualService: CustomManualService, private translate: TranslateService) { }

  ngOnInit(): void {
  }

  show() {
    this.customManual = this.customManualService.getCompleteCustomManual(this.productName, this.family, this.preCmd, this.enterConfigurationCmd, this.exitConfigurationCmd);
    this.customManualCommands = this.customManual.map(it => Command.fromCustomManualItem(it, this.translate, this.family));
    this.step = 1;

    const element: HTMLElement = document.getElementById('customManualButton');
    element.click();
  }

  setType(type: string): void {
    this.type = type;
    this.currentMaxCommand = { text: this.translate.instant('SHARED.UNCONFIGURED'), value: 0 };
    if (type === 'code128') {
      this.maxCommandValues = [{ text: this.translate.instant('SHARED.UNCONFIGURED'), value: 0 }, { text: '20', value: 20 }, { text: '30', value: 30 }, { text: '40', value: 40 }]
    } else {
      this.maxCommandValues = [{ text: this.translate.instant('SHARED.UNCONFIGURED'), value: 0 }, { text: '100', value: 100 }, { text: '200', value: 200 }, { text: '300', value: 300 }, { text: '400', value: 400 },
                               { text: '500', value: 500 }, { text: '600', value: 600 }, { text: '700', value: 700 }, { text: '800', value: 800 }]
    }
    this.pdfContent.setType(type);

    if (this.family === 'FRS' && this.type === 'datamatrix') {
      this.showProgrammingModeLabel = true;
      this.pdfContent.setProgrammingModeLabel(true);
    }
  }

  printToBarcode() {
    this.step = 2;
    this.setType('code128');
  }

  printToDatamatrix() {
    this.step = 2;
    this.setType('datamatrix');
  }

  toggleConcatenate() {
    this.concatenate = !this.concatenate;
    this.pdfContent.setConcatenate(this.concatenate);
  }

  toggleConfiguration(): void {
    this.showConfiguration = !this.showConfiguration;
    this.pdfContent.toggleShowConfigutation();
  }

  toggleProgrammingModeLabel(): void {
    this.showProgrammingModeLabel = !this.showProgrammingModeLabel;
    this.pdfContent.toggleProgrammingModeLabel();
  }

  hideProgrammingModeLabelButton(): boolean {
    if (this.family === 'FRS' && this.type === 'datamatrix') {
      return true;
    }
    return false;
  }


  download(): void {
    if (this.customselectedFileType) {
      this.pdfContent.downloadRtf();
    } else {
      this.pdfContent.downloadPdf();
    }
  }

  deleteFromManual(item: CustomManualItem) {
    this.customManualService.deleteFromManual(item);
    this.customManual = this.customManualService.getCompleteCustomManual(this.productName, this.family, this.preCmd, this.enterConfigurationCmd, this.exitConfigurationCmd);
    this.customManualCommands = this.customManual.map(it => Command.fromCustomManualItem(it, this.translate, this.family));
  }

  back() {
    this.step = 1;
  }

  onMarginValueChanged(option: any) {
    this.currentMargin = option;
  }

  onHeightValueChanged(option: any) {
    this.currentHeight = option;
  }

  onWidthValueChanged(option: any) {
    this.currentWidth = option;
  }

  onMaxCommandValueChanged(option: any) {
    this.currentMaxCommand = option;
  }

  addModifiedParams(): void{
    if(this.modifiedParams){
      this.modifiedParams.forEach(param =>{
        this.customManualService.updateCustomManualItems(param, true, this.productName, true);
      })
      
      this.customManual = this.customManualService.getCompleteCustomManual(this.productName, this.family, this.preCmd, this.enterConfigurationCmd, this.exitConfigurationCmd);
      this.customManualCommands = this.customManual.map(it => Command.fromCustomManualItem(it, this.translate, this.family));
      this.step = 1;
    }
  }

  onEdit(): void{
    this.state.set('edit');
  }

  onEditInputChange(item: CustomManualItem): void{
    item.isOnEdit = !item.isOnEdit;
  }

  onDeleteAll(): void{
    this.customManual.forEach(item =>{this.deleteFromManual(item)});
    this.customManual = [];
  }

  onEditDelete(): void{
    this.customManual.forEach((item, index) => {
      if(item.isOnEdit){
        this.deleteFromManual(item);
      }
    });
    this.state.set('normal');
  }

  onEditCheckAllChange(value): void{
    if(value.target.checked){
      this.customManual.forEach((item, index) => {
        item.isOnEdit = true;
      });
    }else{
      this.customManual.forEach((item, index) => {
        item.isOnEdit = false;
      });
    }
  }

  onCancel(): void{
    this.state.set('normal');
  }
}
