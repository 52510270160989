<div class="table-parameter container-fluid">
    <div class="row">
        <div class="col">
            <span class="th">{{'PRODUCT.PARAMETER' | translate}}</span>
        </div>
        <div class="col">
            <span class="th">{{'PRODUCT.CURRENT-VALUE' | translate}}</span>
        </div>
        <div class="col">
            <span class="th">{{'PRODUCT.DEVICE-VALUE' | translate}}</span>
        </div>
        <div class="col">
            <span class="th">{{'PRODUCT.DEFAULT-VALUE' | translate}}</span>
        </div>
        <div class="col">
            <span class="th">{{'PRODUCT.COMMAND' | translate}}</span>
        </div>
    </div>
    <div class="row param_row" *ngFor="let parameter of parameters">
        <div class="col">
            <span class="td param_name" [ngClass]="{'af_param': isAfParam(parameter), 'af_param_disable': isAfDisable(parameter)}"
                (click)="goToParam(parameter)">
              {{parameter.label || parameter.text || parameter.code}}
            </span>
        </div>
        <div class="col">
            <span class="td" [ngClass]="{'af_param': isAfParam(parameter), 'af_param_disable': isAfDisable(parameter)}">
              {{parameter.getValueDescription()}}
            </span>
        </div>
        <div class="col">
            <span class="td" [ngClass]="{'af_param': isAfParam(parameter), 'af_param_disable': isAfDisable(parameter)}">
              {{parameter.getDeviceValueDescription()}}
            </span>
        </div>
        <div class="col">
            <span class="td" [ngClass]="{'af_param': isAfParam(parameter), 'af_param_disable': isAfDisable(parameter)}">
              {{parameter.getDefaultValueDescription()}}
            </span>
        </div>                            
        <div class="col last_col">
            <span class="td" [ngClass]="{'af_param': isAfParam(parameter), 'af_param_disable': isAfDisable(parameter)}">
              {{parameter.getCommandForDevice()}}
            </span>
            @if(enableDelete){
                <div class="param_delete_btn">
                    <button>
                        <span (click)="deleteParam(parameter)">Confirm delete</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                    </button>
                </div>
            }
        </div>
    </div>
</div>