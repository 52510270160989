import { CommonModule } from '@angular/common';
import { Component, computed, effect, model, ModelSignal, NgZone, OnInit, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';

type View = 1 | 2;
type Monitor = 1 | 2 | 3;
type Profile = 'SPP' | 'HID';
type DeviceStatus = 'connected' | 'paired' | 'connecting' | 'failed' | 'removing' | '';
type BluetoothDevice = { 
  name: string, 
  macAddress: string,
  status: DeviceStatus,
  statusToRestore: string
};
type Action = 'scan' | 'stopScan' | 'detect';
type ScanStatus = 'scanning' | 'notScanning';

@Component({
  selector: 'app-device-detection',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './device-detection.component.html',
  styleUrl: './device-detection.component.css'
})
export class DeviceDetectionComponent implements OnInit {
  view = signal<View>(1);
  monitor = signal<Monitor>(1);
  profile = signal<string>('HID');
  monitorTitle = computed<string>(() => {
    switch(this.monitor()){
      case 1: return 'Step 1: Unlink paired devices';
      case 2: return 'Step 2: Activate Bluetooth on devices';
      case 3: return 'Step 3: Scan Bluetooth devices';
    }
  });
  bluetoothDevices = signal<BluetoothDevice[]>([]);
  connectedBluetoothDevices = computed(() => {
    return this.bluetoothDevices().filter(dev => dev.status == 'connected' 
                                              || dev.status == 'paired' 
                                              || dev.status == 'removing');
  });
  scanStatus = signal<ScanStatus>('notScanning');
  isBluetoothPageFirstLoad = signal<boolean>(true);
  isDocumentLoadFinished = signal<boolean>(false);
  ip: ModelSignal<string> = model<string>('');
  osName = signal<string>("");

  constructor(private zone: NgZone,) {
    // Creating the public object to be called by JxBrowser
    window.deviceDetectionComponent = {
      component: this,
      zone: this.zone,
      sendBluetoothDevicesFromJava: (value: string) => this.setBluetoothDevices(value),
      sendActionFromJava: (value: string) => this.doAction(value as Action),
      setDeviceStatusFromJava: (mac: string, status: string) => this.setBluetoothDevicesStatus(mac, status as DeviceStatus),
      setScanStatusFromJava: (status: string) => this.scanStatus.set(status as ScanStatus),
      setIsDocumentLoadFinishedFromJava:() => this.isDocumentLoadFinished.set(true),
      setOsNameFromJava: (osName: string) => this.osName.set(osName),
    };

    effect(() => {
      // if status = failed -> back to previous status in 3s
       if(this.bluetoothDevices().some(dev => dev.status === 'failed')){
        setTimeout(() => {
          this.bluetoothDevices.update(devices => devices.map(device => {
            if(device.status === 'failed'){
              device.status = device.statusToRestore as DeviceStatus;
            }
            return device;
          }));
        }, 3000);
      }

      // if status = '' ->  remove device
      if(this.bluetoothDevices().some(dev => dev.status === '')){
        this.bluetoothDevices.update(devices => devices.filter(device => device.status !== ''));
      }

      // on first load, scan for bluetooth devices once
      if(this.isBluetoothPageFirstLoad() && this.isDocumentLoadFinished()){
        this.doAction('scan');
        this.isBluetoothPageFirstLoad.set(false);
      }
    });
  }

  ngOnInit(): void {
  }

  doAction(value: Action): void{
    switch(value){
      case 'stopScan':
        this.scanStatus.set('notScanning');
        break;
      default:
        window.deviceDetectionComponent_action(value);
        break;
    }
  }

  startEthernetSearch(): void{
    if (this.validateIp(this.ip())) {
      window.deviceDetectionComponent_ethSearch();
    }
  }

  setBluetoothDevices(value: string): void{
    this.bluetoothDevices.set(JSON.parse(value));
  }

  onButtonClicked(view: View){
    this.view.set(view);
    if(view === 1){
      this.monitor.set(1);
    }
  }

  onBack(): void{
    if(this.monitor() === 1){
      this.view.set(1);
      return;
    };
    this.monitor.update(monitor => monitor - 1 as Monitor);
  }

  onNext(): void{
    if(this.monitor() === 3){
      this.monitor.set(1);
      this.view.set(1);
      return
    }
    this.monitor.update(monitor => monitor + 1 as Monitor);
  }

  onProfileClick(value: Profile): void{
    this.profile.set(value);
  }

  onConnect(mac: string):void {
    window.deviceDetectionComponent_connect(mac);
  }

  onRemove(mac: string): void{
    window.deviceDetectionComponent_remove(mac);
  }

  onScan(): void{
    this.doAction('scan');
  }

  setBluetoothDevicesStatus(mac: string, status: DeviceStatus): void{
    this.bluetoothDevices.update(devices => devices.map(device => {
      if(device.macAddress === mac){
        device.statusToRestore = device.status;
        device.status = status;
      }
      return device;
    }));
  }

  validateIp(ip: string): boolean{
    const ipRegex = /^(?:((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))|((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:_port_|:)(0|[1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])))$/;
    return ipRegex.test(ip);
  }

  getIpValidateText(): string{
    if(this.ip() !== ''){
      return this.validateIp(this.ip()) ? ': valid connection address.' : ': invalid connection address!';
    }
    return "";
  }
}
