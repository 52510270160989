import { LanguageSelectionComponent } from './../../shared/language-selection/language-selection.component';
import { ParameterRule } from './parameter-rule';
import { ParameterOption } from './parameter-option';
import { Helper } from '../utility';
import { LanguageService } from 'src/app/services/language.service';
import { first } from 'rxjs/operators';
import { XmlParser } from '@angular/compiler';

export class Parameter {
  
  get value(): string {
    if (this.childParameters.length > 0) {
      if (this.type && this.type === "sumHex") {
        let result: string = "0";
        for(let i = 0; i < this.childParameters.length; i++) {
          result = this.addHexWithSize(result, this.childParameters[i].value, this.size);
        }
        return result.toUpperCase();
      } else {
        return this.childParameters.map(it => it.value).join('');
      }
    }
    return this._value;
  }

  set value(newValue: string) {
    if (this.childParameters.length === 0) {
      if (this.checkParamValue(newValue)) {
        this._value = newValue;
        if (this.valuesForInterfaces !== undefined  && this.valuesForInterfaces !== null && 
                                                       this.valuesForInterfaces.has(Parameter.currentInterface)) {
          this.valuesForInterfaces.set(Parameter.currentInterface, this._value);
        } else {
          this._masterValue = this._value; 
        }
      }
    }
  }

  get deviceValue(): string {
    if (this.childParameters.length > 0) {
      if (this.type && this.type === "sumHex") {
        let result: string = "0";
        for(let i = 0; i < this.childParameters.length; i++) {
          result = this.addHexWithSize(result, this.childParameters[i].deviceValue, this.size);
        }
        return result.toUpperCase();
      } else {
        return this.childParameters.map(it => it.deviceValue).join('');
      }
    }
    return this._deviceValue;
  }

  set deviceValue(newValue: string) {
    if (this.childParameters.length === 0) {
      if (this.checkParamValue(newValue)) {
        this._deviceValue = newValue;
      }
    }
  }

  addHexWithSize(c1: string, c2: string, size: number) {
    let hexStr: string = (parseInt(c1, 16) + parseInt(c2, 16)).toString(16);
    if (hexStr.length > size) {
      hexStr = hexStr.substring(0, size);
    } else if (hexStr.length < size) {
      hexStr = hexStr.padStart(size, '0');
    }
    return hexStr;
  }

  // used only for setting current interface without any other effects. Usually, would want to use setValueFollowingInterface
  static setCurrentInterface(value: string) {
    Parameter.currentInterface = value;
  }

  static getCurrentInterface() {
    return Parameter.currentInterface;
  }
  
  public setValueFollowingInterface(currentInterface: string, isOnline: boolean, isInterfaceParameter: boolean): void {
    Parameter.currentInterface = currentInterface;
    if (this.childParameters.length === 0) {
      if (this.defaultValuesForInterfaces !== undefined && this.defaultValuesForInterfaces !== null && 
                                                           this.defaultValuesForInterfaces.has(currentInterface)) {
        this._defaultValue = this.defaultValuesForInterfaces.get(currentInterface);
        // In offline mode, update device value also
        if (!isOnline) {
          if (!isInterfaceParameter) {
            this._value = this.valuesForInterfaces.get(currentInterface);
            this._deviceValue = this._defaultValue;
          }

        }
      } else {
        this._defaultValue = this._masterDefaultValue;
        // In offline mode, update device value also
        if (!isOnline) {
          if (!isInterfaceParameter) {
            this._value = this._masterValue;
          }
          this._deviceValue = this._defaultValue;
        }
      }
    } else if (this.childParameters.length > 0) {
      for (let i = 0; i < this.childParameters.length; i++) {
        this.childParameters[i].setValueFollowingInterface(currentInterface, isOnline, isInterfaceParameter);
      }
    }
  }

  get defaultValue(): string {
    if (this.childParameters.length > 0) {
      if (this.type && this.type === "sumHex") {
        let result: string = "0";
        for(let i = 0; i < this.childParameters.length; i++) {
          result = this.addHexWithSize(result, this.childParameters[i].defaultValue, this.size);
        }
        return result.toUpperCase();
      } else {
        return this.childParameters.map(it => it.defaultValue).join('');
      }
    }
    return this._defaultValue;
  }

  get masterDefaultValue(): string {
    if (this.childParameters.length > 0) {
      if (this.type && this.type === "sumHex") {
        let result: string = "0";
        for(let i = 0; i < this.childParameters.length; i++) {
          result = this.addHexWithSize(result, this.childParameters[i].masterDefaultValue, this.size);
        }
        return result.toUpperCase();
      } else {
        return this.childParameters.map(it => it.masterDefaultValue).join('');
      }
    }
    return this._masterDefaultValue;
  }

  constructor(defaultValue: string, value: string, name: string, parent: Parameter, type: string, protection: string, code: string, hideValue: boolean,
              tableRef: string, min: string, max: string, minLen: string, maxLen: string, incrementBy: number, fillChar: string,
              label: string, sizeLen: number, rulesAsSender: ParameterRule[], rulesAsTarget: ParameterRule[], isPackParameter: boolean,
              hasResetButton: boolean, childParameters: Parameter[], setValueButtonText: string, setValueButtonValue: string,
              defaultValuesForInterfaces: Map<string, string>, valuesForInterfaces: Map<string, string>,
              _isCentralized: boolean, _isSecured: boolean, _isSendToDevice: boolean, _isNoLabelWrite: boolean, _singleParameter: boolean, _readOnlyParameter: boolean) {
    this._protection = protection;
    this._tableRef = tableRef;

    this.min = min;
    this.max = max;
    this.name = name;
    this.parent = parent;
    this.type = type;
    this.label = label;
    this.code = code;
    this.hideValue = hideValue;
    
    if(type === "stringFilled" && value === "") {
      this.size = parseInt(maxLen);
      if (childParameters.length === 0) {
        let valueSize: number = fillChar.length*this.size;
        this._defaultValue = defaultValue.padStart(valueSize, fillChar);
        this._deviceValue = defaultValue.padStart(valueSize, fillChar);
        this._value = value.padStart(valueSize, fillChar);
        this._masterDefaultValue = this._defaultValue;
        this._masterValue = this._value;
        defaultValuesForInterfaces.forEach ((value: string, key: string) => {
          defaultValuesForInterfaces.set(key,value.padStart(valueSize, fillChar))
        });
        valuesForInterfaces.forEach ((value: string, key: string) => {
          valuesForInterfaces.set(key,value.padStart(valueSize, fillChar))
        });
      }
    }else{
      if (sizeLen) {
        this.size = sizeLen
      } else if (type === "hexInt" && min) {
        this.size = min.length;
      }  else {
        this.size = 2;
      }
      this.incrementBy = incrementBy;
      if (childParameters.length === 0) {
        this._defaultValue = defaultValue.padStart(this.size, '0');
        this._deviceValue = defaultValue.padStart(this.size, '0');
        this._value = value.padStart(this.size, '0');
        this._masterDefaultValue = this._defaultValue;
        this._masterValue = this._value;
        defaultValuesForInterfaces.forEach ((value: string, key: string) => {
          defaultValuesForInterfaces.set(key,value.padStart(this.size, '0'))
        });
        valuesForInterfaces.forEach ((value: string, key: string) => {
          valuesForInterfaces.set(key,value.padStart(this.size, '0'))
        });
      }
    }
    
    this.rulesAsSender = rulesAsSender;
    this.rulesAsTarget = rulesAsTarget;
    this.minLen = minLen;
    this.maxLen = maxLen;
    this.fillChar = fillChar;
    this.childParameters = childParameters;
    this.isPackParameter = isPackParameter;
    this.hasResetButton = hasResetButton;
    this.setValueButtonText = setValueButtonText;
    this.setValueButtonValue = setValueButtonValue;
    this.defaultValuesForInterfaces = defaultValuesForInterfaces;
    this.valuesForInterfaces = valuesForInterfaces;
    this.isCentralized = _isCentralized;
    this.isSecured = _isSecured;
    this.isSendToDevice = _isSendToDevice;
    this.isNoLabelWrite = _isNoLabelWrite;
    this.singleParameter = _singleParameter;
    this.readOnlyParameter = _readOnlyParameter;

    if (this.code === "0001" || this.code === "$hA") {
      Parameter.currentInterface = this._value;
    }

    LanguageSelectionComponent.passLangValue.subscribe((newLang)=>{
      this.previousLang = this.currentLang;
      this.currentLang = newLang;
    })
  }

  public name: string;
  public parent: Parameter;
  public label: string;
  public type: string;
  public code: string;
  public options: ParameterOption[] = [];
  public disabled = false;
  public rulesAsSender: ParameterRule[];
  public rulesAsTarget: ParameterRule[];
  public hideValue: boolean = false;

  // set of rules which are currently disabling the parameter
  public ruleDisabling: Set<ParameterRule> = new Set<ParameterRule>();
  // set of rules which are currently enabling the parameter (only used for the <rule> with action="Enable")
  public ruleEnabling: Set<ParameterRule> = new Set<ParameterRule>();
  public childParameters: Parameter[];
  public size: number;
  public minLen: string;
  public maxLen: string;
  public isPackParameter: boolean;
  public isFirstChild: boolean;
  public fillChar: string;
  public hasResetButton: boolean;
  public min: string;
  public max: string;
  public incrementBy: number;
  public setValueButtonText: string;
  public setValueButtonValue: string;

  private _value: string;
  private _deviceValue: string;
  private _defaultValue: string;
  private _protection: string;
  private _tableRef: string;
  public defaultValuesForInterfaces: Map<string, string>;
  public valuesForInterfaces: Map<string, string>;
  public _masterDefaultValue: string;
  public _masterValue: string;
  private  static currentInterface: string = '';
  public isCentralized: boolean = false;
  public isSecured: boolean = false;
  public isSendToDevice: boolean = true;
  public isNoLabelWrite: boolean = false;
  public text: string = "";
  public currentLang: string = 'English';
  public previousLang: string = 'English';
  public hiddenString: string = "*****";
  public singleParameter: boolean = false;
  public readOnlyParameter: boolean = false;

  static fromXmlParameter(parameter, rulesAsSender: ParameterRule[], rulesAsTarget: ParameterRule[], optionValues, isPackParameter: boolean,
                          hasResetButton: boolean, children: Parameter[], languageService: LanguageService, interfaceClasses: Object, singleParameterXml: boolean):
                          Parameter {
    let setValueButtonText = languageService.getProductString(parameter.setValueButtonText || parameter['@setValueButtonText']);
    let setValueButtonValue = parameter['@setValueButtonValue'];
    if (hasResetButton) {
      setValueButtonText = languageService.translate.instant('SHARED.ANY');
      setValueButtonValue = parameter['@value'];
    }

    // Generate the map of the default value for each interface
    let mapDefaultValues = new Map<string, string>();
    for (let interfaceClass in interfaceClasses) {
      if (parameter[interfaceClass] !== null && parameter[interfaceClass] !== undefined && parameter[interfaceClass] !== '') {
        mapDefaultValues.set(interfaceClasses[interfaceClass], parameter[interfaceClass]);
      } 
    }

    // Generate the map of the value for each interface
    let mapValues = new Map<string, string>();
    for (let interfaceClass in interfaceClasses) {
      if (parameter[interfaceClass] !== null && parameter[interfaceClass] !== undefined && parameter[interfaceClass] !== '') {
        mapValues.set(interfaceClasses[interfaceClass], parameter[interfaceClass]);
      } 
    }

    let value = parameter['@value'];
    if (parameter['@offlineInitial']) {
      value = parameter['@offlineInitial']
    }

    let _isCentralized: boolean = false;
    if (parameter['@centralized'] === "true") {
      _isCentralized = true;
    }

    let _isSecured: boolean = false;
    if (parameter['@protection'] === "SECURE") {
      _isSecured = true;
    }

    let _isSendToDevice: boolean = true;
    if (parameter['@sendToDevice'] === "false") {
      _isSendToDevice = false;
    }

    let _isNoLabelWrite: boolean = false;
    if (parameter['@noLabelWrite'] === "true") {
      _isNoLabelWrite = true;
    }

    let _hideValue: boolean = false;
    if(parameter['@hideValue'] === "true"){
      _hideValue = true;
    }
  
    let _label: string = languageService.getProductString(parameter.context || parameter['@context']);  
    
    let _singleParameter = singleParameterXml;

    let _readOnlyParameter: boolean = false;
    if (parameter['@readOnly'] === "true") {
      _readOnlyParameter = true;
    }
    
    const obj = new this(
      value,
      value,
      parameter['@name'],
      parameter['@parent'],
      parameter['@type'],
      parameter['@protection'],
      parameter['@code'],
      _hideValue,
      parameter['@tableRef'],
      parameter['@min'],
      parameter['@max'],
      parameter['@minLen'],
      parameter['@maxLen'],
      parameter['@incrementBy'],
      parameter['@fillChar'] || parameter['@fillchar'],
      _label,
      parameter['@sizeLen'],
      rulesAsSender,
      rulesAsTarget,
      isPackParameter,
      hasResetButton,
      children,
      setValueButtonText,
      setValueButtonValue,
      mapDefaultValues,
      mapValues,
      _isCentralized,
      _isSecured,
      _isSendToDevice,
      _isNoLabelWrite,
      _singleParameter,
      _readOnlyParameter
    );

    if (parameter['@tableRef'])
    {
      if(parameter['@tableRef'].startsWith('IntegerRange0000065535'))
      {

        //parameter['@min'] = 0;
        //parameter['@max'] = 65535;
        //obj.min = '0'; //Number(parameter['@min']);
        //obj.max = 'FFFF';
        //parameter['@minLen'] = 1;
        //parameter['@maxLen'] = 4;
        //parameter['@sizeLen'] = 4;
      }
    }

    if(parameter['@tableRef'] &&
      (parameter['@type'] === 'stringFilled' || parameter['@type'] === 'readableAscii' || parameter['@type'] === 'char') &&
      parameter['@tableRef'].startsWith('exeNumericRange'))
      {
      for (const value of optionValues) {
        if (this.checkProtectionLevelDisplayed(value['@protection'])) {
          obj.options.push(new ParameterOption(languageService.getProductString(value.text), value.value, ''));
        }
      }
    }
    else
    {
      if (parameter.value && Helper.isArray(parameter.value))
      {
        let i = 0;
        for (const value of parameter.value) {
          if (optionValues.length > 0) {
            let optionValue = optionValues[i];
            if (optionValue) {
              if (optionValue['@context']) {
                optionValue = optionValue['@context'];
              }
            }
            if (this.checkProtectionLevelDisplayed(value['@protection'])) {
              obj.options.push(new ParameterOption(languageService.getProductString(value), optionValue, ''));
            }
          } else {
            let optionValue = value;
            if (value) {
              if (value['@read']) {
                optionValue = value['@read'];
              }
            }
            let optionCommand = value;
            if (value) {
              if (value['@write']) {
                optionCommand = value['@write'];
              }
            }
            let optionName = value;
            if (value) {
              if (value['@context']) {
                optionName = value['@context'];
              }
            }
            if (this.checkProtectionLevelDisplayed(value['@protection'])) {
              obj.options.push(new ParameterOption(languageService.getProductString(optionName), optionValue, optionCommand));
            }
          }

          i++;
        }
      }
      else
      {
        for (const value of optionValues) {
          // filter out values that are restricted by protection level
          if (this.checkProtectionLevelDisplayed(value['@protection'])) {
            obj.options.push(new ParameterOption(languageService.getProductString(value['@name']), value['#text'], ''));
          }
        }
      }
    }

    let index = 0;
    children.forEach(element => 
    {
      element.parent = obj;
      if(index == 0)
      {
        element.isFirstChild = true;
      }
      index++;
    });

    return obj;
  }

  // copy parameter ensuring deep copy of child parameters, defaultValuesForInterfaces and valuesForInterfaces maps
  // parentParameter is optional parentParameter if present. Must be provided to prevent infinite loop
  public copyParameter(parentParameter: Parameter): Parameter {
    // provide empty array for child parameters, they will be set after construction
    let newParam = new Parameter(this.defaultValue, this.defaultValue, this.name, parentParameter,this.type,this._protection,this.code,this.hideValue ,this._tableRef,this.min,
      this.max,this.minLen,this.maxLen,this.incrementBy,this.fillChar,this.label,this.size,this.rulesAsSender,this.rulesAsTarget,this.isPackParameter,
      this.hasResetButton, [],this.setValueButtonText,this.setValueButtonValue,new Map(this.defaultValuesForInterfaces),
      new Map(this.valuesForInterfaces),this.isCentralized,this.isSecured,this.isSendToDevice,this.isNoLabelWrite, this.singleParameter, this.readOnlyParameter);
    newParam.options = [...this.options];

    let newChildParameters = this.childParameters.map(child => child.copyParameter(newParam));
    newParam.childParameters = newChildParameters;

    return newParam;
  }

  // returns true if item should be displayed, else false
  public static checkProtectionLevelDisplayed (protectionLevel: string): boolean {
    return (Helper.developerModeEnabled || protectionLevel !== 'DEVELOPER') &&
            (Helper.secureModeEnabled || protectionLevel !== 'SECURE');
  }

  public checkParamValue(value): boolean {
    if (this.type === 'hexInt') {
      const valueInt = parseInt(value, 16);
      const minInt = parseInt(this.min, 16);
      const maxInt = parseInt(this.max, 16);
      const setValueButtonValueInt = parseInt(this.setValueButtonValue, 16);
      if (!isNaN(setValueButtonValueInt) && valueInt == setValueButtonValueInt) {
        return true;
      }
      if (isNaN(valueInt) || valueInt < minInt || valueInt > maxInt) {
        return false;
      }
      if (this.incrementBy) {
        if ((valueInt - minInt) % this.incrementBy != 0) {
          return false;
        }
      }
    } else if (this.type === 'int' && this.options.length === 0 && !isNaN(parseInt(this.min, 10)) && !isNaN(parseInt(this.max, 10))) {
      const valueInt = parseInt(value, 10);
      const minInt = parseInt(this.min, 10);
      const maxInt = parseInt(this.max, 10);
      const setValueButtonValueInt = parseInt(this.setValueButtonValue, 10);
      if (!isNaN(setValueButtonValueInt) && valueInt == setValueButtonValueInt) {
        return true;
      }
      if (isNaN(valueInt) || valueInt < minInt || valueInt > maxInt) {
        return false;
      }
      if (this.incrementBy) {
        if ((valueInt - minInt) % this.incrementBy != 0) {
          return false;
        }
      }

    }

    return true;
  }

  public executeRule(rule: ParameterRule, compliant: boolean): void {
    if (this.childParameters.length > 0) {
      for (const childParameter of this.childParameters) {
        childParameter.executeRule(rule, compliant);
      }
    } else {
      // first mark if current rule disables the parameter
      if (rule.action === 'Enable') {
        if (!compliant) {
          this.addDisablingRule(rule);
        } else {
          this.deleteDisablingRule(rule);
        }
      } else if (rule.action === 'Disable') {
        if (compliant) {
          this.ruleDisabling.add(rule);
        } else {
          this.ruleDisabling.delete(rule);
        }
      } else if (rule.action === 'SetValue') {
        if (compliant) this.value = rule.value;
      }

      // then determine based on all rules if parameter should be disabled.
      if (this.ruleDisabling.size > 0) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    }
  }

  public getTableRef(): string {
    return this._tableRef;
  }

  public addDescription(value: string): string {
    if (this.options.length > 0) {
      const selectedElement = this.options.find(it => it.value === value);
      if (selectedElement) {
        return selectedElement.name + ' [' + value + ']';
      }
    }

    return value;
  }

  public getValueDescription(): string {
    if(this.hideValue === true){
      return this.addDescription(this.hiddenString);
    }
    return this.addDescription(this.value);
  }

  public getDefaultValueDescription(): string {
    if(this.hideValue === true){
      return this.addDescription(this.hiddenString);
    }
    return this.addDescription(this.defaultValue);
  }

  public getDeviceValueDescription(): string {
    if(this.hideValue === true){
      return this.addDescription(this.hiddenString);
    }
    return this.addDescription(this.deviceValue);
  }

  public getDescriptionWithValue(): string {
    if (this.options.length > 0) {
      const selectedElement = this.options.find(it => it.value === this.value);
      if (selectedElement) {
        return (this.label || this.text) + ': ' + selectedElement.name;
      }
    }

    return (this.label || this.text) + ': ' + this.value;
  }

  public getCommandForDevice(): string {
    if(this.hideValue === true){
      return this.addDescription(this.hiddenString);
    }
    if (this.options.length > 0) {
      const firstElement = this.options[0];
      if (firstElement && firstElement.command !== '') {
        let tempOption = this.options.find(it => it.value === this.value);
        if (tempOption) {
          return tempOption.command;
        } else {
          return "";
        }
        
      } else {
        return this.code + this.value;
      }
    } else {
      return this.code + this.value;
    }
  }

  public getCommand(family: string, preCmd: string, enterConfigurationCmd: string, exitConfigurationCmd: string): string {
    if (this.isPackParameter) {
      return this.getCommandForDevice();
    } else {
      const command = this.getCommandForDevice();
      if (command.indexOf('$') >= 0) {
        return '$' + enterConfigurationCmd + ',' + command.replace('$', '') + ',' + exitConfigurationCmd;
      }
      let head = '$' + enterConfigurationCmd + ',' + preCmd;
      let tail = ',' + exitConfigurationCmd;
      if (family == 'FRS') {
        head = '00000\r';
        tail = '\r00000';
      }
      return head + this.getCommandForDevice() + tail;
    }
  }

  public setParamValueForAllInterfaces(newValue: any): void{
    this.valuesForInterfaces.forEach((value,key)=>{
      this.valuesForInterfaces.set(key,newValue);
    })
  }

  private deleteDisablingRule(rule: ParameterRule): void {
    this.ruleEnabling.add(rule);

    this.ruleDisabling.delete(rule);
    // if all rules that have same senders and targets
    if(this.ruleDisabling.size > 0){
      this.ruleDisabling.forEach((ru) => {
        let relevantRule: boolean = ru.sender.some(s => rule.sender.includes(s));
        relevantRule = relevantRule && ru.targets.some(t => rule.targets.includes(t));
        if(relevantRule){
          this.ruleDisabling.delete(ru);
        }
      });
    }
  }
  
  private addDisablingRule(rule: ParameterRule): void {
    this.ruleEnabling.delete(rule);
    // if all rules that have same senders and targets
    let enableRuleCount: number = 0;

    if(this.ruleEnabling.size > 0){
      this.ruleEnabling.forEach((ru) => {
        let relevantRule: boolean = ru.sender.some(s => rule.sender.includes(s));
        relevantRule = relevantRule && ru.targets.some(t => rule.targets.includes(t));
        if(relevantRule){
          enableRuleCount += 1;
        }
      });
    }

    if(enableRuleCount === 0){
      this.ruleDisabling.add(rule);
    }
  }
}
