<button type="button" class="btn btn-info btn-lg d-none" data-toggle="modal" [attr.data-target]="'#' + identifier" [id]="identifier + 'Button'"></button>

<!-- Modal -->
<div [id]="identifier" class="modal fade" role="dialog" id="noti_modal" data-keyboard="false" data-backdrop="static">
  <div [ngClass]="getModalSize()">

    <!-- Modal content-->
    <div class="modal-content noti_container">
      <div class="modal-header d-flex justify-content-start">
        <div class="d-flex align-items-center h-100">
          <img [src]="getSrc()" class="noti_img"/>
        </div>
        <h4 class="modal-title font-weight-bold" [ngStyle]="{'color':getColor()}">{{notification.title}}</h4>
      </div>

      <div class="modal-body text-center">
        <p [innerHTML]="notification.message"></p>
        <br *ngIf="notification.noShowAgain.isUsed"><br/>
        <div *ngIf="notification.noShowAgain.isUsed" class="text-left no_show_container d-flex">
          <input type="checkbox" id="no_show_input" [checked]="notification.noShowAgain.checked" #noShowAgainRef>
          <div for="no_show_input" [innerHTML]="notification.noShowAgain.message"></div>
        </div>
      </div>
      
      <div class="modal-footer justify-content-end d-flex">
        <a *ngIf="!isConfirm() && !isOption()" role="button" class="btn btn-outline-none noti_btn" id="notification_ok" style="white-space: nowrap;"
        [style.background-color]="getColor()" [style.color]="'white'" data-dismiss="modal" (click)="onOK()">
          {{'SHARED.OK' | translate}}
        </a>
        <a *ngIf="isConfirm()" role="button" class="btn btn-outline-none noti_btn" id="notification_confirm" style="white-space: nowrap;" 
        [style.background-color]="getColor()" [style.color]="'white'" data-dismiss="modal" (click)="onConfirm()">
          {{'SHARED.OK' | translate}}
        </a>
        <a *ngIf="isOption()" role="button" class="btn btn-outline-none noti_btn" id="noti_option_yes" 
         data-dismiss="modal" (click)="onYes()">
          YES
          <!-- {{'SHARED.OK' | translate}} -->
        </a>
        <a *ngIf="isOption()" role="button" class="btn btn-outline-none noti_btn" id="noti_option_no"  
          data-dismiss="modal" (click)="onNo()">
          NO
          <!-- {{'SHARED.OK' | translate}} -->
        </a>
        <a *ngIf="isConfirm() || isOption()" role="button" class="btn btn-outline-none noti_btn" id="notification_cancel" style="white-space: nowrap;" 
        [style.border]="getBorder()" [style.color]="getColor()" data-dismiss="modal" (click)="onCancel()">
          {{'SHARED.CANCEL' | translate}}
        </a>
      </div>
    </div>

  </div>
</div>