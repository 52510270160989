import { Component, EventEmitter, Input, Output } from '@angular/core';
import { uleModeEnum } from 'src/app/classes/enums/ule.enum';
import { Parameter } from 'src/app/classes/parameters';
import { UleService } from 'src/app/services/ule/ule.service';

@Component({
  selector: 'app-parameter-table',
  templateUrl: './parameter-table.component.html',
  styleUrl: './parameter-table.component.css'
})
export class ParameterTableComponent {
  @Output() onGoToParam: EventEmitter<Parameter> = new EventEmitter<Parameter>();
  @Output() onDeleteParam: EventEmitter<Parameter> = new EventEmitter<Parameter>();
  
  @Input() parameters: Parameter[];
  @Input() enableDelete: boolean;

  private uleMode: uleModeEnum = uleModeEnum.ADV_FORMAT;

  constructor(private uleService: UleService) { 
    this.uleService.uleChanged.subscribe((value) => {
      this.uleMode = value.mode;
    });
  }

  isAfParam(param: Parameter): boolean{
    if(param.isPackParameter && this.uleService.isInUleEditor){
      return true;
    }

    return false;
  }

  isAfDisable(param: Parameter): boolean{
    if(this.uleMode === uleModeEnum.CUSTOM && param.isPackParameter){
      return true;
    }

    return false;
  }

  goToParam(param: Parameter): void{
    this.onGoToParam.emit(param);
  }

  deleteParam(param: Parameter): void{
    this.onDeleteParam.emit(param);
  }
}
