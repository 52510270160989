<div class="row" [hidden]="!isOnline">
    <div class="col config-title">
      <a
        role="button"
        id="configButton"
        class="btn btn-outline-warning btn-lg mb-2"
        [attr.data-command]="message.command"
        [attr.data-readBack]="message.readBack"
        [attr.data-postCommand]="message.postCommand">
        {{message.description}}
      </a>
    </div>
    <div class="col-4 col-sm-2 config-title text-center" *ngIf="customManualEnabled && showAddButton">
      <a role="button"  class="btn btn-outline-warning btn-sm"
        [ngClass]="{'active': isInCustomManual}" (click)="sendEventForCustomManualWithMessage()">
        <span *ngIf="isInCustomManual" data-toggle="tooltip" data-placement="right" [title]="'SHARED.ADDED-TOOLTIP' | translate">{{'SHARED.ADDED' | translate}}</span>
        <span *ngIf="!isInCustomManual" data-toggle="tooltip" data-placement="right" [title]="'SHARED.ADD-TOOLTIP' | translate">+{{'SHARED.ADD' | translate}}</span>
      </a>
    </div>
</div>