import { Helper } from 'src/app/classes/utility';
import { FrsCcfInfo, FrsCcfReceivedInfo } from './../../classes/devices/frs-ccf-info';
import { FILE_OPERATOR_TYPE } from './../../classes/enums/file-operator.enum';
import { AfterContentInit, Component, ElementRef, Input, OnInit, SimpleChange, ViewChild } from '@angular/core';

declare var $: any;
@Component({
  selector: 'app-file-operator',
  templateUrl: './file-operator.component.html',
  styleUrls: ['./file-operator.component.css']
})
export class FileOperatorComponent implements OnInit, AfterContentInit {
  @Input() identifier: string  = "";
  @ViewChild('ccfCustomerName') ccfCustomerName: ElementRef<HTMLInputElement>;
  @ViewChild('ccfConfigName') ccfConfigName: ElementRef<HTMLInputElement>;
  @ViewChild('ccfInterfaces') ccfInterfaces: ElementRef<HTMLInputElement>;
  
  isSecure: boolean = Helper.secureModeEnabled;
  type: 'load' | 'read' | 'save';
  title: string = "";
  message: string = "";
  acceptedFiles: String = "";
  frsCcfInfo: FrsCcfInfo;
  copyLevelModel: "Copy to user" | "Copy to factory" | "Copy to user and factory" = "Copy to user";
  receivedInfo: FrsCcfReceivedInfo;

  constructor() { }
  
  ngOnInit(): void {
  }
  
  ngAfterContentInit(): void {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="tooltip"]').hover(function(){
      $('[data-toggle="tooltip"]').tooltip()
    });
  }

  show(type: FILE_OPERATOR_TYPE, acceptedFiles: String) {
    const button = document.getElementById(this.identifier + "Button");
    switch(type){
      case FILE_OPERATOR_TYPE.LOAD:
        this.title = "Upload File";
        this.type = "load";
        break;
      case FILE_OPERATOR_TYPE.SAVE:
        this.title = "Save File";
        this.type = "save";
        break;
      case FILE_OPERATOR_TYPE.READ_FRS:
        this.title = "Custom Configuration Details";
        this.type = "read";

        break;
    }
    button.click();
  }

  onFrsOkClicked(): void{
    const customerName: string = this.ccfCustomerName.nativeElement.value;
    const configName: string = this.ccfConfigName.nativeElement.value;
    const interfaces: string = this.ccfInterfaces.nativeElement.value;
    let copyLevel: string = "Copy to user";
    
    if(this.isSecure){
      copyLevel = this.copyLevelModel;
    }  
    this.receivedInfo = new FrsCcfReceivedInfo(customerName,configName,interfaces,copyLevel);
    const out: string = JSON.stringify(this.receivedInfo);
    window.readCCFFromDeviceByJava(out);
  }

  // interfaces:RS232-STD,RS232-WN,RS232-Debug,Keyboard,USB-COM,USB-OEM,ORT/initActiveInterface:RS232-WN/productName:900i/copyLevels:Copy to user,Copy to factory,Copy to user and factory
  setFrsCcfInfo(info: string): void{
    let infoArray = info.split("/");
    let interfaces = infoArray[0].replace("interfaces:","").split(",");
    let initInterface = infoArray[1].replace("initActiveInterface:","");
    let productName = infoArray[2].replace("productName:","");
    let copyLevels = infoArray[3].replace("copyLevels:","").split(",");

    this.frsCcfInfo = new FrsCcfInfo(interfaces, initInterface, productName, copyLevels);
  }

  onChangeCopyLevel(value: any): void{
    this.copyLevelModel = value.target.value;
  }
}
