import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Parameter, ParameterOption } from 'src/app/classes/parameters';
import { Helper } from 'src/app/classes/utility';
import { ProductComponent } from 'src/app/pages/product/product.component';
import { UleService } from 'src/app/services/ule/ule.service';
import { Notification, NotificationType } from 'src/app/classes/utility/notification';
import { uleModeEnum } from 'src/app/classes/enums/ule.enum';
import { NotificationComponent } from '../notification/notification.component';
import { UleModeComponent } from '../ule-mode/ule-mode.component';
import { SettingsService } from 'src/app/services/settings.service';
import {CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-driver-license-editor',
  templateUrl: './driver-license-editor.component.html',
  styleUrls: ['./driver-license-editor.component.css']
})
export class DriverLicenseEditorComponent {
  @Input() parameter: Parameter;
  @Input() readonly = false;
  @Input() commonFields: any;
  @Input() lessCommonFields: any;
  @Input() notification: NotificationComponent;
  @Input() uleModeComponent: UleModeComponent;
  @Output() valueChanged: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('triggerBtn') triggerBtn: ElementRef;

  separatorFields = Helper.hexCharList;

  selectedOptions: ParameterOption[] = [];
  activeOptions: number[] = [];
  selectedOptionsText = '';

  constructor(private uleService: UleService, private settingsService: SettingsService) { }

  ngOnInit(): void {
    this.refreshValue();
    this.saveChanges();
  }

  refreshValue(): void {
    const valueArray = this.parameter.value.match(/.{4}/g);

    for (const value of valueArray) {
      const prefix = value.substring(0, 2);
      const arrayValue = value.substring(2);
      if (prefix == '01') {
        this.selectOption(Helper.hexToInt(arrayValue), '01');
      } else {
        const optionValue = this.commonFields.find(it => it['#text'].toUpperCase() === arrayValue);
        if (optionValue) {
          this.selectOption(optionValue, '02');
        } else {
          const optionValue = this.lessCommonFields.find(it => it['#text'].toUpperCase() === arrayValue);
          if (optionValue) {
            this.selectOption(optionValue, '02');
          }
        }
      }
    }

    ProductComponent.isActiveHover = false;
  }

  selectOption(option: any, prefix: string): void {
    if (this.selectedOptions.length < Helper.parseIntWithRadix(this.parameter.maxLen)) {
      let opt: ParameterOption;
      if (prefix === '02') {
        opt = new ParameterOption(option['@name'], prefix + option['#text'], '');
      } else {
        opt = new ParameterOption(this.separatorFields[option], prefix + Helper.intToHex(option).toUpperCase().padStart(2, '0'), '');
      }
      this.selectedOptions.push(opt);
    }
  }

  toggleActiveOption(index: number): void {
    const itemIndex = this.activeOptions.indexOf(index);
    if (itemIndex === -1) {
      this.activeOptions.push(index);
    } else {
      this.activeOptions.splice(itemIndex, 1);
    }
  }

  moveUp(): void {
    if (this.activeOptions.indexOf(0) >= 0) {
      return;
    }

    this.activeOptions.sort((a, b) => a - b);

    for (let i = 0; i < this.activeOptions.length; i++) {
      const optionIndex = this.activeOptions[i];
      if (optionIndex > 0) {
        Helper.arrayMove(this.selectedOptions, optionIndex, optionIndex - 1);
        this.activeOptions[i] = this.activeOptions[i] - 1;
      }
    }
  }

  moveDown(): void {
    if (this.activeOptions.indexOf(this.selectedOptions.length - 1) >= 0) {
      return;
    }

    this.activeOptions.sort((a, b) => b - a);

    for (let i = 0; i < this.activeOptions.length; i++) {
      const optionIndex = this.activeOptions[i];
      if (optionIndex < (this.selectedOptions.length - 1)) {
        Helper.arrayMove(this.selectedOptions, optionIndex, optionIndex + 1);
        this.activeOptions[i] = this.activeOptions[i] + 1;
      }
    }
  }

  deleteActiveOptions(): void {
    this.selectedOptions = this.selectedOptions.filter((it, i) => this.activeOptions.indexOf(i) < 0);
    this.activeOptions = [];
  }

  resetToDefault(): void {
    this.parameter.value = this.parameter.defaultValue;
    this.selectedOptionsText = '';
    this.valueChanged.emit(this.parameter.name);
  }

  saveChanges(swithUleMode: boolean = false): void {
    if(swithUleMode && this.uleService.currentUle.mode === uleModeEnum.CUSTOM && this.parameter.isPackParameter){
      this.uleModeComponent.setMode(uleModeEnum.ADV_FORMAT);
    }

    this.selectedOptionsText = '';
    for (const valueItem of this.selectedOptions) {
      this.selectedOptionsText += valueItem.name + ' ';
    }

    this.parameter.value = this.selectedOptions.map(it => it.value.toUpperCase()).join('') + this.parameter.defaultValue;

    this.valueChanged.emit(this.parameter.name);

    this.close();
    ProductComponent.isActiveHover = true;
  }

  close(): void {
    this.selectedOptions = [];
    this.activeOptions = [];
    ProductComponent.isActiveHover = true;
  }

  onClick(): void{
    this.trigger();
  }

  trigger(): void{
    this.triggerBtn.nativeElement.click();
  }

  onTrigger(): void{
    this.refreshValue();
  }
  clearAll():void{
    this.selectedOptions = [];
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedOptions, event.previousIndex, event.currentIndex);
  }
  onDragStart():void {
    this.activeOptions = []
  }
}
