<div class="modal fade" id="progressMonitorModal" tabindex="-1" role="dialog" aria-labelledby="progressMonitorLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="progressMonitorLabel">In Progress</h5>
            </div>
            <div class="modal-body text-center">
                <p>{{ message }}</p>
                <mat-progress-bar mode="indeterminate" style="width: 100%;"
                    [color]="isStoppedState ? 'warn' : 'primary'">
                </mat-progress-bar>
            </div>
            <div class="modal-footer">
                <button type="button" id="stopProgressMonitorBtn" class="btn btn-secondary" [disabled]="!enableStopBtn"
                    (click)="stop()">Stop</button>
            </div>
        </div>
    </div>
</div>