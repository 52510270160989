import { TranslateService } from '@ngx-translate/core';
import { Helper } from '../utility';

export class Product {
    public name: string;
    public internalName: string;
    public releases: any[];
    public configUrl: string;
    public imageUrl: string;
    public helpUrl: string;
    public websiteUrl: string;
    public index: number;

    constructor(name: string, internalName: string, websiteUrl: string, releases: any[], index: number) {
      this.name = name;
      this.internalName = internalName;
      this.websiteUrl = websiteUrl;
      this.releases = releases;
      this.index = index;

      this.configUrl = this.internalName + '/config_' + this.internalName + '.xml';
      this.imageUrl = Helper.baseProductsDirectory + this.internalName + '/device.png';
      this.helpUrl = Helper.baseProductsDirectory + this.internalName + '/HelpUrl.txt';
    }

    getCurrentLanguageConfigUrl(translateService: TranslateService): string {
      if (translateService.defaultLang === translateService.currentLang) {
        return this.internalName + '/config_' + this.internalName + '.xml';
      }

      return this.internalName + '/config_' + this.internalName + '.' + translateService.currentLang + '.xml';
    }
}

export type ProductFamily = 'FRS' | 'HHS' | 'OEMSCANENGINE';