import {Component, OnInit} from '@angular/core';

declare var $: any;
@Component({
  selector: 'app-progress-monitor',
  templateUrl: './progress-monitor.component.html',
  styleUrls: ['./progress-monitor.component.css']
})

export class ProgressMonitor implements OnInit{
  message: string = '';
  enableStopBtn: boolean = false;
  isStoppedState: boolean = false;

  constructor() {}

  ngOnInit(): void {  
  }

  show(enableStopBtn: boolean): void {
    this.message = '';
    if (enableStopBtn) {
      this.enableStopBtn = true;
    }
    $('#progressMonitorModal').modal({
      show: true
    });
  }

  stop(): void {
    let result: string = '';
    result = window.getProcessState();
    if(result === 'FINISHED') {
      $('#progressMonitorModal').modal('hide');
      this.message = '';
    } else {
      this.message = 'Progress is stopping';
      this.changeStopProgressBarStyle();
    }
  }
  changeStopProgressBarStyle() {
    this.enableStopBtn = false;
    this.isStoppedState = true;
    const progressBar = document.querySelector('mat-progress-bar');
    progressBar.setAttribute('mode', 'query');
    progressBar.classList.add('stopped-state');
  }

  getMessage(message: string) {
    this.message = message;
  }
  
}
