import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../services/language.service';
import { ConfigurationElement } from './configuration-element';
import { ConfigurationElementType } from './enums/configuration-element-type';
import { Parameter } from './parameters';
import { Message } from './utility';

export class ConfigurationPage {
  public id: string;
  public name: string;
  public tocId: string;
  public configurationElements: ConfigurationElement[];

  constructor(name: string, title: string, tocId: string, configurationElements: any[]) {
    this.id = name;
    this.name = title;
    this.tocId = tocId;
    this.configurationElements = configurationElements;
  }

  static fromXmlPage(page: any, parameters: Parameter[], translateService: TranslateService, languageService: LanguageService) {
    let relateToAdvancedFormatting: boolean = false;
    let pageElements: ConfigurationElement[] = [];
    // Going through the object to add page elements
    for (const key in page) {
      // Excluding properties already used for other purposes
      if (key !== 'order' && key !== 'page' && key[0] !== '@' && key[0] !== '#') {
        if (page.hasOwnProperty(key)) {
          const value = page[key];
          let type = ConfigurationElementType.Message;

          // Checking the property type to assign the correct value
          switch (key) {
            case 'pageMessage':
              type = ConfigurationElementType.Message;
              break;
            case 'label':
              type = ConfigurationElementType.Label;
              break;
            case 'field':
              type = ConfigurationElementType.Field;
              break;
            default:
              break;
          }

          // If the property isn't already an array i have to convert it to one
          let array = value || [];
          if (array.length === undefined) {
            array = [array];
          }

          for (const message of array) {
            if (message['@name'].startsWith('AdvancedFormatting')) {
              const advParams = parameters.filter(it => it.name.startsWith(message['@name']));

              let indexFieldProp = 1;
              let indexAddFields = 1;
              for (let index = 0; index < advParams.length; index++) {
                const advParam = advParams[index];
                if (index === 0) {
                  pageElements.push(new ConfigurationElement('', translateService.instant('ADVANCED-FORMATTING.MCC'), '',
                                message.order, undefined, ConfigurationElementType.Label, undefined,"","",undefined));
                } else if (advParam.label.toLowerCase() === translateService.instant('ADVANCED-FORMATTING.DCF').toLowerCase()) {
                  relateToAdvancedFormatting = true;
                  pageElements.push(new ConfigurationElement('', indexFieldProp + ' ' + translateService.instant('ADVANCED-FORMATTING.FP'), '',
                                message.order, undefined, ConfigurationElementType.Label, undefined,"","",undefined));

                  indexFieldProp++;
                } else if (advParam.label.toLowerCase().indexOf(translateService.instant('ADVANCED-FORMATTING.AF').toLowerCase()) >= 0 && indexAddFields === 1) {
                  pageElements.push(new ConfigurationElement('', translateService.instant('ADVANCED-FORMATTING.AFS'), '',
                                message.order, undefined, ConfigurationElementType.Label, undefined,"","",undefined));

                  indexAddFields++;
                }  else if (advParam.label.toLowerCase() === translateService.instant('ADVANCED-FORMATTING.TO').toLowerCase()) {
                  pageElements.push(new ConfigurationElement('', translateService.instant('ADVANCED-FORMATTING.TO'), '',
                                message.order, undefined, ConfigurationElementType.Label, undefined,"","",undefined));
                }  else if (advParam.label.toLowerCase() === translateService.instant('ADVANCED-FORMATTING.ASF').toLowerCase()) {
                  pageElements.push(new ConfigurationElement('', translateService.instant('ADVANCED-FORMATTING.SF'), '',
                                message.order, undefined, ConfigurationElementType.Label, undefined,"","",undefined));
                }

                pageElements.push(new ConfigurationElement('', '', undefined,
                                  message.order, undefined, ConfigurationElementType.Field, advParam,"","",undefined));
              }
            } else if (message['@name'].startsWith('SATELLITE_FIELD')) {
              const parameter = parameters.find(it => it.name === message['@name']);
              pageElements.push(new ConfigurationElement(message['@name'], languageService.getProductString(message['@text']), message['@guiType'],
              message.order, message['@code'], type, parameter,"","",undefined,
              message['@pairing_removing'], message['@clear_all'], message['@list_all'], message['@check_status'], message['@device_list_all']));
            }
            else {
              const parameter = parameters.find(it => it.name === message['@name']);
              pageElements.push(new ConfigurationElement(message['@name'], languageService.getProductString(message['@text']), message['@guiType'],
                                message.order, message['@code'], type, parameter,"","",undefined));
            }
          }
        }
      }
    }
    // Panel pamagement
    if (page['@panel']) {
      let hideValue =  page['@hideValue'];
      if(hideValue === "true"){
        hideValue = true;
      }else{
        hideValue = false;
      }
      pageElements.push(new ConfigurationElement(page['@panel'], languageService.getProductString(page['@title']), 'editor', pageElements.length,
                        page['@code'], ConfigurationElementType.Field, undefined, page['@maxSize'], page['@packageSize'],hideValue));
    }

    pageElements.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
    
    if (relateToAdvancedFormatting) {
      if (pageElements !== undefined && pageElements.length !== 0) {
        let pageElementsTemp: ConfigurationElement[] = [];
        for (let i = 0; i < pageElements.length; i++) {
          if (pageElements[i].order <= 2) {
            pageElementsTemp.push(pageElements[i])
          } else {
            let indexNumber = -1;
            let textToCheck: string = '1 ' + translateService.instant('ADVANCED-FORMATTING.FP');
            //Look up for 1 Field Properties
            if (pageElementsTemp !== undefined && pageElementsTemp.length !== 0) {
              for (let j = 0; j < pageElementsTemp.length; j++) {
                if (pageElementsTemp[j].text === textToCheck) {
                  indexNumber = j;
                  break;
                }
              }
            }

            if (indexNumber !== -1) {
              pageElementsTemp.splice(indexNumber, 0, pageElements[i]);
            } else {
              pageElementsTemp.push(pageElements[i]);
            }
          }
        }
        pageElements = pageElementsTemp;
      }
    }

    if (pageElements.length == 0) {
      pageElements.push(new ConfigurationElement('', translateService.instant('PRODUCT.BLANK-PAGE'), undefined,
                                0, undefined, ConfigurationElementType.PageBlank, undefined,"","",undefined));
    // display "This page intentionally left blank." if all elements are messages and not isOnline (because the messages are only displayed when isOnline)
    } else if (pageElements.every(function(element) {
       return element.type === ConfigurationElementType.Message; 
      })) {
      pageElements.push(new ConfigurationElement('standaloneonly', translateService.instant('PRODUCT.BLANK-PAGE'), undefined,
                                0, undefined, ConfigurationElementType.PageBlank, undefined,"","",undefined));
    }

    return new this(
      page['@name'],
      languageService.getProductString(page['@title']),
      page['@tocId'],
      pageElements
    );
  }
}
