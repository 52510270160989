<div class="setting_item">
    <div class="setting_item_title">{{title()}}</div>
    <div class="setting_item_content">
        <div class="description">{{description()}}</div>
        <form>
            @for(item of settingItems(); track item){
                <label class="mode_label d-flex" data-toggle="tooltip" data-placement="right" 
                    (click)="onRadioSelect($event)" [title]="item?.tooltip"> 
                    <input  type={{item.type}} 
                            name={{item.type}} 
                            value={{item.value}} 
                            [checked]="item.value == default().value"
                    />
                    <span>{{item.label}}</span>
                </label>
            }
        </form>
    </div>
</div>