import { UleModeComponent } from 'src/app/shared/ule-mode/ule-mode.component';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Parameter, ParameterOption } from 'src/app/classes/parameters';
import { Helper } from 'src/app/classes/utility';
import { ProductComponent } from 'src/app/pages/product/product.component';
import { NotificationComponent } from '../notification/notification.component';
import { UleService } from 'src/app/services/ule/ule.service';
import { uleModeEnum } from 'src/app/classes/enums/ule.enum';
import { Notification, NotificationType } from 'src/app/classes/utility/notification';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-string-filled-editor',
  templateUrl: './string-filled-editor.component.html',
  styleUrls: ['./string-filled-editor.component.css']
})
export class StringFilledEditorComponent implements OnInit {
  @Input() parameter: Parameter;
  @Input() readonly = false;
  @Input() checkCurrentValue: boolean = false;
  @Input() notification: NotificationComponent;
  @Input() uleModeComponent: UleModeComponent;
  @Output() valueChanged: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('triggerBtn') triggerBtn: ElementRef;

  searchText: string;
  deviceValueText = '';
  currentValueText = '';
  selectedOptions: ParameterOption[] = [];
  activeOptions: number[] = [];
  options: ParameterOption[] = [];
  hiddenString: string = "*****";
  isTriggerClick: boolean = false;

  constructor(private uleService: UleService, private settingsService: SettingsService) { }

  ngOnInit(): void {
    this.searchText = '';
    this.options = this.parameter.options;

    const defaultValueArray = this.parameter.defaultValue.match(/.{1,2}/g);

    this.refreshValue();

    let reversedDefaultValueArray = defaultValueArray.reverse();
    let checkValueNotEmpty = false;
    for (const value of reversedDefaultValueArray) {
      const optionValue = this.parameter.options.find(it => it.value.toUpperCase() === value);
      if (optionValue) {
        if (!checkValueNotEmpty && optionValue.value.toLowerCase() !== this.parameter.fillChar.toLowerCase()) {
          checkValueNotEmpty = true;
        }
        
        if (checkValueNotEmpty) {
          this.deviceValueText = optionValue.name + ' ' + this.deviceValueText ;
        }
      }
    }
    
    if(this.parameter.hideValue === true){
      this.currentValueText = this.hiddenString;
    }
  }

  refreshValue(): void {
    if (this.parameter.getTableRef() === 'exeNumericRangeOnlyNumbersIntegerRange0000065535') {
      const currentParameterValue = parseInt(this.parameter.value, 10).toString();
      const valueArray = currentParameterValue.match(/.{1,1}/g);

      this.currentValueText = '';
      this.selectedOptions = [];
      this.options = this.parameter.options;
      for (const value of valueArray) {
        const optionValue = this.parameter.options.find(it => it.value.toUpperCase() === value);
        if (optionValue) {
          this.selectedOptions.push(optionValue);
          this.currentValueText += optionValue.name + ' ';
        }
      }
    } else {
      const valueArray = this.parameter.value.match(/.{1,2}/g);

      this.currentValueText = '';
      this.selectedOptions = [];
      this.options = this.parameter.options;

      let reversedValueArray = valueArray.reverse();
      let checkValueNotEmpty = false;
      let reversedSelectedOptions = [];
      for (let value of reversedValueArray) {
        let optionValue = this.parameter.options.find(it => it.value.toUpperCase() === value);
        if (optionValue) {
          if (!checkValueNotEmpty && optionValue.value.toLowerCase() !== this.parameter.fillChar.toLowerCase()) {
            checkValueNotEmpty = true;
          }
          if (checkValueNotEmpty) {
            reversedSelectedOptions.push(optionValue);
            this.currentValueText = optionValue.name + ' ' + this.currentValueText;
          }
        }
      }

      this.selectedOptions = reversedSelectedOptions.reverse();

      if(this.parameter.hideValue === true && this.isTriggerClick === true) {
        this.currentValueText = this.hiddenString;
        this.selectedOptions = [];
      }
    }
  }

  refreshOptions() {
    if (this.searchText === '') {
      this.options = this.parameter.options;
    } else {
      this.options = this.parameter.options.filter(f => f.name.indexOf(this.searchText) >= 0);
    }
  }

  selectOption(option: ParameterOption): void {
    if (this.selectedOptions.length < Helper.parseIntWithRadix(this.parameter.maxLen)) {
      this.selectedOptions.push(option);
    }
  }

  toggleActiveOption(index: number): void {
    const itemIndex = this.activeOptions.indexOf(index);
    if (itemIndex === -1) {
      this.activeOptions.push(index);
    } else {
      this.activeOptions.splice(itemIndex, 1);
    }
  }

  moveUp(): void {
    if (this.activeOptions.indexOf(0) >= 0) {
      return;
    }

    this.activeOptions.sort((a, b) => a - b);

    for (let i = 0; i < this.activeOptions.length; i++) {
      const optionIndex = this.activeOptions[i];
      if (optionIndex > 0) {
        Helper.arrayMove(this.selectedOptions, optionIndex, optionIndex - 1);
        this.activeOptions[i] = this.activeOptions[i] - 1;
      }
    }
  }

  moveDown(): void {
    if (this.activeOptions.indexOf(this.selectedOptions.length - 1) >= 0) {
      return;
    }

    this.activeOptions.sort((a, b) => b - a);

    for (let i = 0; i < this.activeOptions.length; i++) {
      const optionIndex = this.activeOptions[i];
      if (optionIndex < (this.selectedOptions.length - 1)) {
        Helper.arrayMove(this.selectedOptions, optionIndex, optionIndex + 1);
        this.activeOptions[i] = this.activeOptions[i] + 1;
      }
    }
  }

  deleteActiveOptions(): void {
    this.selectedOptions = this.selectedOptions.filter((it, i) => this.activeOptions.indexOf(i) < 0);
    this.activeOptions = [];
  }

  changeInputValue(changedValue: string, setDeviceValue: boolean = true): void {
    if (setDeviceValue) {
      this.parameter.deviceValue = changedValue;
      this.deviceValueText = '';
    }
    this.currentValueText = '';

    if (this.parameter.getTableRef() === 'exeNumericRangeOnlyNumbersIntegerRange0000065535') {
      changedValue = parseInt(changedValue, 10).toString();
      const deviceValueArray = changedValue.match(/.{1,1}/g);
      
      for (const value of deviceValueArray) {
        const optionValue = this.parameter.options.find(it => it.value.toUpperCase() === value);
        
        if (optionValue) 
        {
          if (setDeviceValue) 
          {
            this.deviceValueText += optionValue.name + ' ';
          }
          this.currentValueText += optionValue.name + ' ';
        }
      }
    } else {
      const deviceValueArray = changedValue.match(/.{1,2}/g);
      
      let reversedDeviceValueArray = deviceValueArray.reverse();
      let checkValueNotEmpty = false;
      for (const value of reversedDeviceValueArray) {
        const optionValue = this.parameter.options.find(it => it.value.toUpperCase() === value);
        
        if (optionValue) 
        {
          if (!checkValueNotEmpty && optionValue.value.toLowerCase() !== this.parameter.fillChar.toLowerCase()) 
          {
            checkValueNotEmpty = true;
          }
          
          if (checkValueNotEmpty) 
          {
            if (setDeviceValue) 
            {
              this.deviceValueText = optionValue.name + ' ' + this.deviceValueText;
            }
            this.currentValueText = optionValue.name + ' ' + this.currentValueText;
          }
        }
      }
    }
    
    this.valueChanged.emit(this.parameter.name);
  }

  saveChanges(): void {
    let currentParameterValue = '';
    let currentParameterValueWithSpace = '';
    for (const valueItem of this.selectedOptions) {
      currentParameterValue += valueItem.name;
      currentParameterValueWithSpace += valueItem.name + ' ';
    }

    this.currentValueText = '';
    if (isNaN(Number(currentParameterValue)) || (currentParameterValue.length === 0)) {
      //In case, the sting currentParameterValue can contains the all characters as configuring Data Format parameter
      this.currentValueText = currentParameterValueWithSpace;
    } else {
      //In case, the sting currentParameterValue only contains the number characters as configuring Ethernet port parameter
      currentParameterValue = parseInt(currentParameterValue, 10).toString();
      const currentParameterValueArray = currentParameterValue.match(/.{1,1}/g);
  
      for (const currentParameterValueItem of currentParameterValueArray) {
        this.currentValueText += currentParameterValueItem + ' ';
      }
    }

    const missingValues = Helper.parseIntWithRadix(this.parameter.maxLen) - this.selectedOptions.length;
    const missingValuesArray = Array(missingValues).fill(this.parameter.fillChar);

    if (this.parameter.getTableRef() === 'exeNumericRangeOnlyNumbersIntegerRange0000065535') {
      this.parameter.value = missingValuesArray.join('') + this.selectedOptions.map(it => it.value.toUpperCase()).join('') ;
    } else {
      this.parameter.value = this.selectedOptions.map(it => it.value.toUpperCase()).join('') + missingValuesArray.join('');
    }

    this.valueChanged.emit(this.parameter.name);

    if(this.deviceValueText !== this.currentValueText && this.parameter.hideValue === true){
      this.currentValueText = this.hiddenString;
      this.isTriggerClick = false
    }

    this.close();
    this.refreshValue();
    ProductComponent.isActiveHover = true;
  }

  close(): void {
    this.activeOptions = [];
    this.searchText = '';
    ProductComponent.isActiveHover = true;
  }

  onClick(): void{
    if(this.uleService.currentUle.mode === uleModeEnum.CUSTOM && this.parameter.isPackParameter){
      this.uleModeComponent.setMode(uleModeEnum.ADV_FORMAT);
      this.onTriggerClick();
    }else{
      this.onTriggerClick();
    }
  }

  onTriggerClick(): void{
    this.triggerBtn.nativeElement.click();
    ProductComponent.isActiveHover = false;
    this.isTriggerClick = true;
    this.refreshValue();
  }
}
