<div class="mb-3 ule_main">
    <div [ngClass]="expandUleMode?'ule_close_hide':'ule_close_show'" class="row ule_mode_container_1" #closedDiv (click)="setExpandUleMode(true)">
      <div class="col-10 d-flex justify-content-start">
        <div class="ule_img_container">
          <img class="ule_img" [src]="getIconOnMode()" alt="">
        </div>
        <div class="ml-3">
          <div class="">ULE mode</div>
          <div class="ule_mode_title">{{uleProperties.mode}}</div>
        </div>
      </div>
      <div class="col-2 justify-content-center">
        <img class="ule_img" src="assets/images/product/right_arrow.png" alt="">
      </div>
    </div>

    <div [ngClass]="expandUleMode?'ule_show':'ule_hide'" class="ule_mode_container_2" #expandedDiv>
      <div class="row no-gutters">
        <div class="col-3 d-flex justify-content-center align-items-center"> 
          <div class="ule_img_container arrow_back" (click)="setExpandUleMode(false)">
            <img class="ule_img" src="assets/images/product/left_arrow.png" alt="">
          </div>
        </div>
        <div class="col-9 d-flex justify-content-start align-items-center">
          <div class="ule_mode_title">ULE Mode Selection</div>
        </div>
      </div>

      <div>
        <div class="row ule_option no-gutters" (click)="setMode(fakeUleEnum.ADV_FORMAT)">
          <div class="col-2 d-flex justify-content-center align-items-center">
            <div class="ule_img_container">
                <img class="ule_img" src="assets/images/product/adv_format.png" alt="">
            </div>
          </div>
          <div class="col-8">
            <div class="ule_mode_title">{{'ULE.MODE_ADV' | translate}}</div>
            <div>{{'ULE.ADV' | translate}}</div>
          </div>
          <div class="col-2">
            <img [hidden]="uleProperties.mode != fakeUleEnum.ADV_FORMAT" class="ule_img" src="assets/images/product/check.png" alt="">
          </div>
        </div>

        <div class="row ule_option no-gutters" (click)="setMode(fakeUleEnum.CUSTOM)">
            <div class="col-2 d-flex justify-content-center align-items-center">
                <div class="ule_img_container">
                    <img class="ule_img" src="assets/images/product/custom_ule.png" alt="">
                </div>
            </div>
            <div class="col-8">
                <div class="ule_mode_title">{{'ULE.MODE_CUSTOM' | translate}}</div>
                <div>{{'ULE.CUSTOM' | translate}}</div>
            </div>
            <div class="col-2">
                <img [hidden]="uleProperties.mode != fakeUleEnum.CUSTOM" class="ule_img" src="assets/images/product/check.png" alt="">
            </div>
        </div>
      </div>
    </div>
</div>