<div id="wrapper">
  <main role="main" class="container-fluid">
    <div class="row">
      <div class="col prod-col">
        <div class="tab-content bg-light" id="myTabContent">
          <div #currentProduct class="tab-pane fade show active" id="currentProduct" role="tabpanel" aria-labelledby="tab2">
            <section id="toolbar" class="row">
              <div class="col-12 col-xl-5 text-left">
                <div class="row justify-content-center justify-content-sm-start">
                  <div class="col-auto col-img">
                    <div [ngClass]="{online: isOnline}" class="disp-img">
                      <img [src]="product?.imageUrl" class="img-fluid float-xl-left d-xl-inline" />
                    </div>
                  </div>
                  <div class="col-auto">
                    <h2 class="d-inline">{{product?.name}}</h2>
                    <h4>
                      <span *ngIf="isOnline">{{'PRODUCT.CONNECTED-DEVICE' | translate}} {{deviceRelease}} [{{connectedTo}}]</span>
                      <span *ngIf="tabModeTitle"> [{{ tabModeTitle.toUpperCase() }}]</span>
                      <span *ngIf="!isOnline && tabModeTitle == ''">{{'PRODUCT.OFFLINE' | translate}}</span>
                    </h4>
                    <a target="_blank" [href]="product?.websiteUrl" *ngIf="product?.websiteUrl && newsVisible">{{'PRODUCT.GO-TO-WEBSITE' | translate}}</a>
                  </div>
                  <div id="release" class="col-auto">
                    <h4 class="d-inline ml-3 mr-2">{{'PRODUCT.RELEASE' | translate}}</h4>
                    <div class="d-inline-block">
                      <select class="form-control" (change)="changeRelease($event.target.value)">
                        <option *ngFor="let item of product?.releases; let i = index" [value]="item.index"
                          [selected]="item.index == selectedReleaseIndex">
                          {{releaseValue(item.name)}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl-7 float-right btn-prod my-3 my-xl-0">
                <div class="row">
                  <div class="col">
                    <a role="button" class="btn btn-outline-warning btn-sm" [ngClass]="{disabled: !isOnline}" [hidden]="true">
                      {{'PRODUCT.JINGLE' | translate}}
                      <img src="assets/images/jingle.svg" class="ml-2" />
                    </a>
                    <div class="w-100 d-none d-xl-block"></div>
                    <!-- <a role="button" class="btn btn-outline-warning btn-sm" id="btnProductViewImage" [ngClass]="{disabled: !isOnline}" [hidden]="!viewImageEnabled">
                      {{'PRODUCT.VIEW-IMAGE' | translate}}
                      <img src="assets/images/view-image.svg" class="ml-2" />
                    </a> -->
                    <a role="button" class="btn btn-outline-warning btn-sm" [ngClass]="{disabled: !isOnline}" [hidden]="true">
                      {{'PRODUCT.DISCONNECT-DEVICE' | translate}}
                      <img src="assets/images/disconnect.svg" class="ml-2" />
                    </a>
                    <a role="button" class="btn btn-outline-warning btn-sm" [ngClass]="{disabled: !isOnline}" [hidden]="true">
                      {{'PRODUCT.SERVICE-MODE' | translate}}
                      <img src="assets/images/service-mode.svg" class="ml-2" />
                    </a>
                  </div>
                  
                  <div class="card manual-box" [hidden]="!viewImageEnabled || tabModeTitle">
                    <div class="card-body text-center">
                      <div class="card-title">
                        {{'PRODUCT.VIEW-IMAGE' | translate}}
                        <img src="assets/images/view-image.svg" class="ml-2" />
                      </div>
                      <a role="button" class="btn btn-warning btn-sm" id="btnProductViewImage" data-toggle="tooltip" data-placement="bottom" [title]="'PRODUCT.VIEW-IMAGE-TOOLTIP' | translate">{{'SHARED.OPEN' | translate}}</a>
                    </div>
                  </div>

                  <div class="col-12 col-sm-auto">
                    <div class="card manual-box">
                      <div class="card-body text-center">
                        <div class="card-title">{{'PRODUCT.CUSTOM-MANUAL' | translate}}</div> 
                        <div class="numItem" *ngIf="customManualEnabled && customManualLength > 0">{{customManualLength}} {{'SHARED.ITEMS' | translate}}</div>
                        <a role="button" class="btn btn-warning btn-sm"
                          *ngIf="customManualEnabled && customManualLength > 0" (click)="openCustomManual()">{{'SHARED.VIEW' | translate}}</a>
                        <a role="button" class="btn btn-warning btn-sm"
                          (click)="toggleCustomManual()">
                          <span *ngIf="customManualEnabled" data-toggle="tooltip" data-placement="bottom" [title]="'PRODUCT.CUSTOM-MANUAL-TOOLTIP-ENABLE' | translate">{{'SHARED.DISABLE' | translate}}</span>
                          <span *ngIf="!customManualEnabled" data-toggle="tooltip" data-placement="bottom" [title]="'PRODUCT.CUSTOM-MANUAL-TOOLTIP-DISABLE' | translate">{{'SHARED.ENABLE' | translate}}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="maincont" class="row">
              <!-- LEFT-PAGE -->
              <div class="col-12 col-lg-4 col-xl-3" id="left_panel">
                <div id="params" class="card bg-light rounded-0 border-0 h-100">
                  <div id="left-page">
                    <div class="card-header blue sm left-page-header" id="left_header">
                      {{'PRODUCT.PARAMETERS' | translate}}
                    </div>
                    <div class="card-body py-2 px-0" id="left-page-items">
                      <div id="param-search" class="form-dark mb-2">
                        <form id="frm-search" method="POST">
                          <div class="align-items-center">
                            <div class="col pr-0 pl-1 input">
                              <input
                                rollback-input 
                                type="text" name="searchValue" class="form-control" 
                                [placeholder]="'PRODUCT.SEARCH-PLACEHOLDER' | translate"
                                [(ngModel)]="searchValue" (ngModelChange)="refreshSearch($event)" autocomplete="off" (click)="set_isOnSearchInputClick(true)" (focusout)="set_isOnSearchInputClick(false)"/>
                              <div class="search-results" [ngClass]="{visible: searchedParams.length > 0}">
                                <div class="result" *ngFor="let param of searchedParams"
                                  (click)="onSearchItemClick(param)">
                                  <span class="page">{{param.page}}</span>
                                  <span class="name">{{ getShowParameterCode() ? param.parameter.label + " (" + param.parameter.code + ")" : param.parameter.label}}</span>
                                </div>
                              </div>

                              <!-- ===== SHOW SEARCH HISTORY ===== -->
                              <div class="search-results" [ngClass]="{visible: searchValue.length == 0 && isOnSearchInputClick}">
                                <div class="result d-flex" *ngFor="let searchItem of searchHistory" 
                                  (mousedown)="onSearchItemClick(searchItem)">
                                  <div class="d-flex justify-content-center"><img src="assets/images/time-back.png" alt="" class="search-icon search-time-back"></div>
                                  <div class="d-flex align-items-center flex-grow-1">
                                    <div class="ml-2">
                                      <span class="page">{{searchItem.page}}</span>
                                      <span class="name">{{ getShowParameterCode() ? searchItem.parameter.label + " (" + searchItem.parameter.code + ")" : searchItem.parameter.label}}</span>  
                                    </div>
                                    <div class="search-delete d-flex justify-content-center align-items-center ml-auto" (mousedown)="deleteSearchItem(searchItem.parameter.code)"><img src="assets/images/delete.png" alt="" class="search-icon"></div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </form>
                      </div>
                      <div id="params-accordion">
                        <app-param-section #paramMenu *ngFor="let item of paramMenu; let i = index"
                          [hidden]="tabModeTitle=='remote' && (item.name === 'ULE.pnl' || item.name === 'SATELLITE.pnl' || item.name === 'CUSTOMCONFIG.pnl' || item.name === 'configuraton.pnl' || 
                                    item.name === 'SmartBattery.pnl' || item.name === 'StatisticPage.pnl' || item.name ==='CustomConfigurationFile.pnl' || 
                                    item.name === 'Certificates.pnl')"
                          [id]="'param-menu-item-' + i" [item]="item" (clicked)="onParamNodeClicked($event)">
                        </app-param-section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- CENTER-PAGE -->
              <div class="col-12 col-lg-8" [ngClass]="{'col-xl-5': rightPanelExpanded(), 'col-xl-8': !rightPanelExpanded()}">
                <div id="config" class="card rounded-0 border-0 h-100">
                  <div class="card-header blue sm" id="center-page-header">
                    {{'PRODUCT.CONFIGURATION' | translate}}
                    <div class="ml-auto"></div>
                    <div id="config-menu-container-id" class="config-menu-container" (mouseenter)="onMouseEnterConfigContainer()">
                      <!-- Trigger Button -->
                      <button class="btn menu-trigger" type="button" aria-expanded="false" aria-controls="configurationBtn"
                        style="border-color: white">
                        <img class="scalle-img" src="assets/images/menu-icon.svg" />
                      </button>
                      
                      <!-- Collapsible Content -->
                      <div class="config-menu-content" id="configurationBtn">
                        <div class="d-flex flex-column config-menu">
                          <!-- Apply Config Button -->
                          <button type="button" class="btn btn-outline-warning btn-config mb-2 d-flex align-items-center" 
                                  (click)="applyConfigToAllInterfaces()" 
                                  id="btnApplyAllConfig"
                                  [hidden]="family!='FRS' || isOnline" 
                                  data-toggle="tooltip" 
                                  data-placement="left" 
                                  [title]="'PRODUCT.APPLY-CONFIG-ALL-INTERFACES' | translate">
                            <img src="assets/images/distribution-icon.svg" class="mr-2" style="width: 20px"/>
                            <span>{{'PRODUCT.APPLY-CONFIG-ALL-INTERFACES' | translate}}</span>
                          </button>
                    
                          <!-- Download Config Button -->
                          <button type="button" class="btn btn-outline-warning btn-config mb-2 d-flex align-items-center" 
                                  id="btnDownloadFullConfig"
                                  [hidden]="family!='FRS' || !isOnline" 
                                  data-toggle="tooltip" 
                                  data-placement="left" 
                                  [title]="'PRODUCT.EXPORT-CSV-CONFIGURATION' | translate">
                            <img src="assets/images/save-csv-config-white.svg" class="mr-2" style="width: 20px"/>
                            <span>{{'PRODUCT.EXPORT-CSV-CONFIGURATION' | translate}}</span>
                          </button>
                    
                          <!-- Generate Barcode Button -->
                          <button type="button" class="btn btn-outline-warning btn-config mb-2 d-flex align-items-center"
                                  (click)="generateBarcode()" 
                                  data-toggle="tooltip" 
                                  data-placement="left" 
                                  [title]="'PRODUCT.EXPORT-CONFIGURATION' | translate">
                            <img src="assets/images/barcode-white.svg" class="mr-2" style="width: 20px"/>
                            <span>{{'PRODUCT.EXPORT-CONFIGURATION' | translate}}</span>
                          </button>
                    
                          <!-- Compare Config Button -->
                          <button type="button" class="btn btn-outline-warning btn-config mb-2 d-flex align-items-center" 
                                  id="btnCompareConf"
                                  (click)="showCompareConfiguration()" 
                                  [hidden]="family!='FRS'" 
                                  data-toggle="tooltip" 
                                  data-placement="left" 
                                  [title]="'PRODUCT.COMPARE-CONFIGURATION' | translate">
                            <img src="assets/images/compare-configuration-white.svg" class="mr-2" style="width: 20px"/>
                            <span>{{'PRODUCT.COMPARE-CONFIGURATION' | translate}}</span>
                          </button>
                    
                          <!-- Save Config Button -->
                          <button type="button" class="btn btn-outline-warning btn-config mb-2 d-flex align-items-center" 
                                  id="btnProductSaveConf"
                                  (click)="saveConfiguration()" 
                                  data-toggle="tooltip" 
                                  data-placement="left" 
                                  [title]="'PRODUCT.SAVE-CONFIGURATION' | translate">
                            <img src="assets/images/save-config-white.svg" class="mr-2" style="width: 20px"/>
                            <span>{{'PRODUCT.SAVE-CONFIGURATION' | translate}}</span>
                          </button>
                    
                          <!-- Load Config Button -->
                          <button type="button" class="btn btn-outline-warning btn-config mb-2 d-flex align-items-center" 
                                  id="btnProductLoadConf"
                                  (click)="loadConfiguration()" 
                                  data-toggle="tooltip" 
                                  data-placement="left" 
                                  [title]="'PRODUCT.LOAD-CONFIGURATION' | translate">
                            <img src="assets/images/open-white.svg" class="mr-2" style="width: 20px"/>
                            <span>{{'PRODUCT.LOAD-CONFIGURATION' | translate}}</span>
                          </button>
                    
                          <!-- Read Config Button -->
                          <button type="button" class="btn btn-outline-warning btn-config mb-2 d-flex align-items-center" 
                                  id="btnProductReadConf"
                                  [hidden]="!(isOnline || tabModeTitle!=='')" 
                                  data-toggle="tooltip" 
                                  data-placement="left" 
                                  [title]="'PRODUCT.READ-CONFIGURATION' | translate"
                                  (click)="clickOnReadConfigurationButtonEvent()">
                            <img src="assets/images/refresh.svg" class="mr-2" style="width: 20px"/>
                            <span>{{'PRODUCT.READ-CONFIGURATION' | translate}}</span>
                          </button>
                    
                          <!-- Write Config Button -->
                          <button type="button" class="btn btn-outline-warning btn-config mb-2 d-flex align-items-center" 
                                  id="btnProductWriteConf"
                                  [hidden]="!(isOnline || tabModeTitle !=='')" 
                                  data-toggle="tooltip" 
                                  data-placement="left" 
                                  [title]="'PRODUCT.WRITE-CONFIGURATION' | translate"
                                  (click)="clickOnWriteConfigurationButtonEvent()">
                            <img src="assets/images/device-white.svg" class="mr-2" style="width: 20px"/>
                            <span>{{'PRODUCT.WRITE-CONFIGURATION' | translate}}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="container-fluid">
                      <div class="row mb-3">
                        <div class="col config-title"></div>
                        <div class="col-4 col-sm-2 config-title text-center" *ngIf="customManualEnabled">{{'PRODUCT.CUSTOM-MANUAL' | translate}}</div>
                      </div>
                      <div class="row mb-3">
                       <!-- CONFIG PAGE -->
                        <div class="col adv-page" *ngIf="isPage()">
                          <div class="config-block  mb-3 p-2" [ngClass]="{'active-hover':checkIsActiveHover()}" *ngFor="let block of currentConfigBlocks; let b = index">
                            <div class="config-element"
                              *ngFor="let item of block; let i = index">
                              
                              <div class="adv-card-title" *ngIf="item.type === 1 && block[i + 1] !== undefined">
                                {{item.text}}</div>
                              
                                <div *ngIf="item.type === 4 && item.name != 'standaloneonly'">
                                {{item.text}}</div>

                              <div [hidden]="isOnline" *ngIf="item.type === 4 && item.name == 'standaloneonly'">
                                {{item.text}}</div>
                              
                              <app-statistic-page *ngIf="standaloneMode && isOnline && item.type === 2 && currentConfig.id === 'StatisticPage.pnl' && item.name !== 'JStatisticPage'"
                                [title]="item.text" [values]="data.statisticPage" [code]="item.code">
                              </app-statistic-page>
                              
                              <app-satellite-pairing 
                                *ngIf="standaloneMode && isOnline && item.type === 2 && currentConfig.id === 'SATELLITE.pnl'"
                                (SendPairDeviceCommand)="clickOnSendTerminalButtonHandler($event)"
                                [notification]="notification"
                                [tabSampleElement]="tabSampleElement"
                                [pairingRemovingCmd]="item.satellitePairingRemoving"
                                [clearAllCmd]="item.satelliteClearAll"
                                [listAllSatelliteCmd]="item.satelliteListAll"
                                [checkStatusCmd]="item.satelliteCheckStatus"
                                [listAllDevicesCmd]="item.satelliteDeviceListAll">
                              </app-satellite-pairing>

                              <app-param-editor #paramEditor
                                *ngIf="item.type === 2 && item.parameter && item.parameter.type !== 'DriverLicense' && item.parameter.type !== 'GS1ElementString' &&  currentConfig.id !== 'StatisticPage.pnl'"
                                [paramParentText]="((i-1) >= 0)? getParamParentText(block[i-1], item) : ''"
                                [parameter]="item.parameter" [customManualEnabled]="customManualEnabled"
                                (toggleFromCustomManual)="onToggleFromCustomManual($event)"
                                [productName]="product?.internalName" [readonly]="item.parameter.disabled"
                                [value]="item.parameter.value" [type]="item.guiType" [interfaceValue]="lastInterface"
                                [parametersOrigin]="data.parameters"
                                [interfaceCode]="interfaceChangeParameter()"
                                [uleModeComponent] = "uleModeComponent"
                                [notification]="notification"
                                (valueChanged)="onParamValueChanged($event)"
                                (throwError)="catchError($event)"
                                >
                              </app-param-editor>

                              <app-driver-license-editor #driverLicenseEditor
                                *ngIf="item.type === 2 && item.parameter && item.parameter.type === 'DriverLicense' && currentConfig.id !== 'StatisticPage.pnl'"
                                [parameter]="item.parameter" [readonly]="item.parameter.disabled"
                                [commonFields]="data?.parsingResult.tableList.driverLicenseTable.element"
                                [lessCommonFields]="data?.parsingResult.tableList.driverLicenseTable2.element"
                                [notification]="notification"
                                [uleModeComponent]="uleModeComponent"
                                (valueChanged)="onParamValueChanged($event)">
                              </app-driver-license-editor>
                              
                              <app-gs1-element-string-editor #gs1ElementStringEditor
                                *ngIf="item.type === 2 && item.parameter && item.parameter.type === 'GS1ElementString' && currentConfig.id !== 'StatisticPage.pnl'"
                                [parameter]="item.parameter" [readonly]="item.parameter.disabled"
                                [commonFields]="data?.parsingResult.tableList.gs1ElementStringTable.element"
                                [notification]="notification"
                                [uleModeComponent]="uleModeComponent"
                                (valueChanged)="onParamValueChanged($event)">
                              </app-gs1-element-string-editor>

                              <app-smart-battery *ngIf="item.type === 2 && item.name === 'JSmartBattery' && currentConfig.id !== 'StatisticPage.pnl'"
                                [values]="data.smartBattery" [code]="item.code">
                              </app-smart-battery>

                              <app-certificate-editor
                                *ngIf="item.type === 2 && (item.name === 'JEditCertificate') && currentConfig.id !== 'StatisticPage.pnl'"
                                [type]="'certificate'"
                                [text]="data.currentCertificate"
                                [title]="item.text"
                                (valueChanged)="onCertificateEditorValueChanged($event)"
                              (click)="onHideValueCertClick()" (focusout)="updateEditorBlur()">
                              </app-certificate-editor>

                              <app-code-editor
                                *ngIf="item.type === 2 && (item.name === 'JEditUle' || item.name === 'JEditCustomConfigFile') && currentConfig.id !== 'StatisticPage.pnl'"
                                #codeEditor
                                [type]="item.name === 'JEditUle' ? 'ule' : ''"
                                [text]="item.name === 'JEditUle' ? data.currentUle : data.currentConfigFile"
                                [title]="item.text"
                                [productUleModeComponent]="uleModeComponent"
                                [modifiedParameters]="modifiedParameters"
                                [notification]="notification"
                                (valueChanged)="onCodeEditorValueChanged($event)">
                              </app-code-editor>

                              <app-message-editor
                                *ngIf="item.type === 3 && item.message !== undefined" [isOnline]="isOnline"
                                [productName]="product?.internalName" [message]="item.message" [customManualEnabled]="customManualEnabled"
                                (toggleFromCustomManualWithMessage)="sendEventForCustomManualWithMessage($event)">
                              </app-message-editor>

                              <app-popup *ngIf="showPopup" 
                                [code]="uleScript"
                                (closeModal)="closeModal()" >
                              </app-popup>
                            </div>
                          </div> 
                        </div>
                      </div>
                      <div class="mb-5 mt-3">
                        <app-ule-mode [hidden]="hidePlainTextCheckBox()" 
                          (setCodeEditorEditable)="setCodeEditorEditable($event)" #uleMode>
                        </app-ule-mode>
                        <div class="d-flex flex-wrap text-center" id="controller-row-element">
                          <button id="smartBatterRefreshInfo" type="button" class="btn btn-outline-warning btn-lg"
                            [hidden]="!currentConfig || !(currentConfig.configurationElements.length > 0) || !(currentConfig.configurationElements[0].type === 2)
                                || !(currentConfig.configurationElements[0].name === 'JSmartBattery')">
                            {{'PRODUCT.REFRESH-INFO' | translate}}
                          </button>
                          <button id="statisticPageRefreshInfo" type="button" class="btn btn-outline-warning btn-lg"
                            [hidden]="!currentConfig || !(currentConfig.configurationElements.length > 0)
                                || !(currentConfig.id === 'StatisticPage.pnl')">
                            {{'PRODUCT.REFRESH-INFO' | translate}}
                          </button>
                          <div class="btn btn-outline-warning btn-sm" id="btnProductCCFWrite" data-toggle="tooltip"
                            [hidden]="!isCCFSelected() || !isOnline" [title]="(isOnline ? 'PRODUCT.CCF-WRITE-TOOLTIP' : '') | translate">
                            {{'SHARED.WRITE' | translate}}
                          </div>
                          <button class="btn btn-outline-warning btn-sm" id="btnProductCCFRead" data-toggle="tooltip"
                            [hidden]="!isCCFSelected() || !isOnline" [title]="(isOnline ? 'PRODUCT.CCF-READ-TOOLTIP' : '') | translate"
                            (click)="configCCF()">
                            {{'SHARED.READ' | translate}}
                          </button>
                          <div class="btn btn-outline-warning btn-sm" id="btnProductULEWrite" [ngClass]="{'disabled': !isOnline}"
                            [hidden]="!isULESelected()">
                            {{'SHARED.WRITE' | translate}}
                          </div>
                          <div class="btn btn-outline-warning btn-sm" id="btnProductULERead" [ngClass]="{'disabled': !isOnline}"
                            [hidden]="!isULESelected()">
                            {{'SHARED.READ' | translate}}
                          </div>
                          <div class="btn btn-outline-warning btn-sm" id="btnCertificateWrite" [ngClass]="{'disabled': !isOnline}"
                            [hidden]="!isCertificateSelected()">
                            {{'SHARED.WRITE' | translate}}
                          </div>
                          <div class="btn btn-outline-warning btn-sm" id="btnCertificateRead" [ngClass]="{'disabled': !isOnline}"
                            [hidden]="isHideReadCertificationButton()">
                            {{'SHARED.READ' | translate}}
                          </div>
                          <div class="btn btn-outline-warning btn-sm" id="btnProductCCFLoad" data-toggle="tooltip" (click)="loadFile()"
                            [hidden]="!isCCFSelected()" [title]="'PRODUCT.CCF-LOAD-TOOLTIP' | translate">
                            {{'SHARED.LOAD' | translate}}
                          </div>
                          <div class="btn btn-outline-warning btn-sm" id="btnProductCCFSave" data-toggle="tooltip" (click)="saveToFile()"
                            [hidden]="!isCCFSelected()" [title]="'PRODUCT.CCF-SAVE-TOOLTIP' | translate">
                            {{'SHARED.SAVE' | translate}}
                          </div>
                          <div class="btn btn-outline-warning btn-sm" id="btnProductULELoad" (click)="loadFileForULEOnWebsite()"
                            [hidden]="!isULESelected()">
                            {{'SHARED.LOAD' | translate}}
                          </div>
                          <div class="btn btn-outline-warning btn-sm" id="btnProductULESave" (click)="saveToFile()"
                            [hidden]="!isULESelected()">
                            {{'SHARED.SAVE' | translate}}
                          </div>
                          <div class="btn btn-outline-warning btn-sm" id="btnCertificateLoad"
                            [hidden]="!isCertificateSelected()">
                            {{'SHARED.LOAD' | translate}}
                          </div>
                          <div class="btn btn-outline-warning btn-sm" id="btnCertificateSave" (click)="saveToFile()"
                            [hidden]="!isCertificateSelected()">
                            {{'SHARED.SAVE' | translate}}
                          </div>
                          <div class="btn btn-outline-warning btn-sm ccfOfflineFRS" id="btnProductCCFExport" data-toggle="tooltip" (click)="exportCCF()"
                            [hidden]="!isCCFSelected() || isOnline || !(family === 'FRS')" [title]="'PRODUCT.CCF-EXPORT-TOOLTIP' | translate">
                            {{'SHARED.EXPORT' | translate}}
                          </div>
                          <div class="btn btn-outline-warning btn-sm" id="btnProductCCFImport" data-toggle="tooltip" (click)="importCCF()"
                            [hidden]="!isCCFSelected() || isOnline || !(family === 'FRS')" [title]="'PRODUCT.CCF-IMPORT-TOOLTIP' | translate">
                            {{'SHARED.IMPORT' | translate}}
                          </div>
                          <div class="btn btn-outline-warning btn-sm" id="btnProductCCFReadULE" data-toggle="tooltip" (click)="ulePreview()"
                          [hidden]="!isCCFSelected() || !isDeveloperMode" [title]="'PRODUCT.CCF-ULEREAD-TOOLTIP' | translate">
                            ULE {{'SHARED.PREVIEW' | translate}}
                          </div>
                          @if(family === 'HHS'){
                            <div class="icon_container" id="" data-toggle="tooltip" (click)="onCCFSettingClick()"
                              [hidden]="!isCCFSelected() || !isDeveloperMode" [title]="'HEADER.TOOLS-SETTINGS' | translate">
                              <img [ngClass]="{'icon': true}" src="assets/images/product/gear.png">
                              @if(isShowCCFSetting()){
                                <floating-card id="ccf_setting_container"
                                  (click)="onCCFSettingCardClick('in')" title="CCF Settings" (clickOut)="onCCFSettingCardClick($event)">
                                  @for(group of data.CCFSettingsData; track group){
                                    <selection-group 
                                      [title]="group.title"
                                      [description]="group.description"
                                      [default]="group.default"
                                      [settingItems]="group.settingItems"
                                      (valueChanged)="onCFFSettingsChanged($event)"
                                    ></selection-group>
                                  }
                                </floating-card>
                              }
                            </div>
                          }
                          <div class="form-check-inline" id="checkbox_script_editor" [hidden]="hidePlainTextCheckBox()">
                            <input class="form-check-input" type="checkbox" value="" id="plainTexCheckBox" [checked]="plainTextCheckBoxStatus"
                              (change)="onPlainTextCheckboxChange($event)">
                            <label class="form-check-label" for="plainTexCheckBox">
                              {{'SHARED.PLAIN-TEXT' | translate}}
                            </label>
                          </div>
                          <input #fileInput type="file" [accept]="acceptedFiles" (change)="loadedFile($event)"
                            class="hidden" />
                        </div>
                        <div class="row justify-content-center" [title]="'PRODUCT.CLEAR-TOOLTIP' | translate">
                          <a role="button" class="btn btn-outline-warning" *ngIf="clearVisible"
                            (click)="clearParameters()">
                            <img src="assets/images/clear.svg" /> {{'SHARED.CLEAR' | translate}}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- RIGHT-PAGE -->
              <div class="col-12 col-lg-12" id="info" [ngClass]="{'col-xl-4': rightPanelExpanded(), 'col-xl-1': !rightPanelExpanded()}">
                  <div id="right_panel_controller" (click)="setRightPanelExpanded()" [ngClass]="{'right_panel_controller_closed': !rightPanelExpanded()}">
                    <img src="assets/images/product/right_arrow_long.png" alt="" class="small_icon">
                  </div>
                  <div id="right_page_info">
                    <div class="card rounded-0 border-0"  *ngIf="enableCloudService && loginDisplayB2c">
                      <div class="card-header blue sm" id="headCloudService">
                        <h5 class="mb-0">
                          <button class="btn btn-link" data-toggle="collapse" data-target="#collapseCloudService" (click)="webApiRes.length == 0? getConfigCloud() : {}"
                            aria-expanded="false" aria-controls="collapseCloudService" [ngClass]="{'right_component_minimized':isRightPanelMinimized()}">
                            <img style="height:24px;" src="assets/images/cloud-service.svg" /> 
                            @if (!isRightPanelMinimized()) {
                              {{'SHARED.CLOUD-SERVICE' | translate}}
                            }
                          </button>
                        </h5>
                      </div>
                      <div id="collapseCloudService" class="collapse" aria-labelledby="headCloudService" data-parent="#info">
                        <div class="d-flex" style="align-items: center; justify-content: center; margin: 20px;" *ngIf="webApiRes.length == 0 || !webApiRes">
                          <app-loading-bar></app-loading-bar>
                        </div>
                        <div class="card-body" *ngIf="webApiRes && webApiRes.length > 0">
                          <div class="table-parameter container-fluid">
                            <div class="row">
                              <div class="col">
                                <span class="th">ID</span>
                              </div>
                              <div class="col">
                                <span class="th">{{'SHARED.NAME' | translate}}</span>
                              </div>
                              <div class="col">
                                <span class="th">{{'SHARED.PRODUCT' | translate}}</span>
                              </div>
                              <div class="col">
                                <span class="th">{{'SHARED.RELEASE' | translate}}</span>
                              </div>
                            </div>
                            <div class="row"*ngFor="let blob of webApiRes">
                              <div class="col"><span class="td">{{blob.id}}</span></div>
                              <div class="col"><span class="td">{{blob.name}}</span></div>
                              <div class="col"><span class="td">{{blob.product}}</span></div>
                              <div class="col"><span class="td">{{blob.releaseSW}}</span></div>                            
                            </div>
                          </div>
                          <div>
                            <a role="button" class="btn btn-outline-warning mt-3" (click)="showConfigService()">
                                {{'PRODUCT.CONFIGURATION' | translate}}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card rounded-0 border-0">
                      <div class="card-header blue sm" id="headHelp">
                        <h5 class="mb-0">
                          <button class="btn btn-link" data-toggle="collapse" data-target="#collapseHelp"
                            aria-expanded="false" aria-controls="collapseHelp" [ngClass]="{'right_component_minimized':isRightPanelMinimized()}">
                            <img src="assets/images/info-orange.svg" /> 
                            @if (!isRightPanelMinimized()) {
                              {{'SHARED.HELP' | translate}}
                            }
                          </button>
                        </h5>
                      </div>
                      <div id="collapseHelp" class="collapse" aria-labelledby="headHelp" data-parent="#info">
                        <div class="card-body">
                          <app-dynamic-html [html]="helpText" (eventRaised)="onHelpRaisedEvent($event)" #helpContent>
                          </app-dynamic-html>
                        </div>
                      </div>
                    </div>
                    <div class="card rounded-0 border-0">
                      <div class="card-header blue sm" id="headNotDef">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseNotDef"
                            aria-expanded="false" aria-controls="collapseNotDef" [ngClass]="{'right_component_minimized':isRightPanelMinimized()}">
                            <img src="assets/images/not-def-params.svg" /> 
                            @if (!isRightPanelMinimized()) {
                              {{'PRODUCT.NOT-DEFAULT-PARAMETERS' | translate}}
                            }
                          </button>
                        </h5>
                      </div>
                      <div id="collapseNotDef" class="collapse" aria-labelledby="headNotDef" data-parent="#info">
                        <div class="card-body">
                          <app-parameter-table 
                            [parameters]="notDefaultParameters"
                            (onGoToParam)="goToParam($event)"
                            [enableDelete]="false"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="card rounded-0 border-0">
                      <div class="card-header blue sm" id="headModified">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseModified"
                            aria-expanded="true" aria-controls="collapseModified" [ngClass]="{'right_component_minimized':isRightPanelMinimized()}">
                            <img src="assets/images/modified.svg" />
                            @if (!isRightPanelMinimized()) {
                              {{'PRODUCT.MODIFIED-PARAMETERS' | translate}}
                            }
                          </button>
                        </h5>
                      </div>
                      <div id="collapseModified" class="collapse show" aria-labelledby="headModified" data-parent="#info">
                        <div class="card-body">
                          <app-parameter-table 
                            [parameters]="modifiedParameters"
                            (onGoToParam)="goToParam($event)"
                            [enableDelete]="true"
                            (onDeleteParam)="deleteModifiedParam($event)"
                          />
                        </div>
                      </div>
                    </div>

                    <!-- =================================== TERMINAL ================================== -->
                    <div *ngIf="isOnline || tabModeTitle=='remote'" class="card rounded-0 border-0">
                      <div class="card-header blue sm" id="headTerminal">
                        <h5 class="mb-0">
                          <button class="btn btn-link" data-toggle="collapse" data-target="#collapseTerminal"
                            aria-expanded="false" aria-controls="collapseTerminal" [ngClass]="{'right_component_minimized':isRightPanelMinimized()}">
                            <img src="assets/images/terminal.svg" /> 
                            @if (!isRightPanelMinimized()) {
                              {{'PRODUCT.TERMINAL' | translate}}
                            }
                          </button>
                        </h5>
                      </div>
                      <div id="collapseTerminal" class="collapse" aria-labelledby="headTerminal" data-parent="#info">
                        <div class="card-body">
                          <div class="terminalCommand">
                            <app-terminal
                              #terminal 
                              [contents]="tabTerminalContents" [isOnline]="isOnline" [isRemoteTab]="tabModeTitle=='remote'" [customButtons]="terminal_customButtons"
                            (clickOnSendTerminalButton)="clickOnSendTerminalButtonHandler($event)" [family]="family"></app-terminal>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <!-- ==============================================================================================================================
                    WRITE CONFIGURATION BUTTONS - start
                  =============================================================================================================================== -->
                  <div class="text-center" *ngIf="isOnline || tabModeTitle !==''" id="write_button_group">
                    <div class="btn-1 bg-warning btn-write-config">
                      <a class="btn btn-block btn-warning align-self-center col-10" role="button" (click)="clickOnWriteConfigurationButtonEvent()">
                        <img src="assets/images/barcode-scanner.svg" class="mr-2" />
                        @if (!isRightPanelMinimized()) {
                          {{('PRODUCT.WRITE-CONFIGURATION'|translate)}}
                        }
                      </a>
                      
                      @if (!isRightPanelMinimized()) {
                        <div *ngIf="tabModeTitle !=='azure'" class="btn-block btn-write-config-icon col-2 align-self-center" type="button" data-toggle="collapse" data-target="#btn-collapse" aria-expanded="false" aria-controls="btn-collapse"  (click)="rotateArrow()" [ngClass]="[btnWriteArrow]">
                          <img src="assets/images/down-arrow-svgrepo-com.svg" alt="">
                        </div>
                      }
                      
                    </div>
                    <div class="collapse" id="btn-collapse">
                      <div class="w-90 mb-2" [hidden]="!(family ==='FRS')"> 
                        <a id="btn-write-config-and-reset" class="btn btn-block btn-warning align-self-center" role="button" (click)="clickOnWriteConfigurationButtonEvent()">
                          <img src="assets/images/barcode-scanner.svg" class="mr-2" />
                          {{('PRODUCT.WRITE-CONFIGURATION-AND-RESET'|translate)}}
                        </a>
                      </div>
                      <div class="w-90 ">
                        <a id="btn-write-config-all" class="btn btn-block btn-warning align-self-center" role="button" (click)="clickOnWriteConfigurationButtonEvent()">
                          <img src="assets/images/barcode-scanner.svg" class="mr-2" />
                          {{('PRODUCT.WRITE-CONFIGURATION-TO-ALL-INTERFACES'|translate)}}
                        </a>
                      </div>
                    </div>
                  </div>
                  <!-- ==============================================================================================================================
                    WRITE CONFIGURATION BUTTONS - end
                  =============================================================================================================================== -->
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
<app-pdf-preview-modal #pdfPreview [preCommands]="data?.preCommands" [postCommands]="data?.postCommands"
  [preCmd] = "preCmd"[enterConfigurationCmd] = "enterConfigurationCmd" [exitConfigurationCmd] = "exitConfigurationCmd"
  [commands]="pdfCommands" [productName]="product?.name" [family]="family" [currentUle]="data?.currentUle" [interfaceCommand]="interfaceCommand"></app-pdf-preview-modal>
<app-compare-configuration #compareConfiguration [parentParameterData]="data?.parameters" [family]="family" ></app-compare-configuration>
<app-custom-manual #customManual [preCommands]="data?.preCommands" [postCommands]="data?.postCommands"
  [preCmd] = "preCmd"[enterConfigurationCmd] = "enterConfigurationCmd" [exitConfigurationCmd] = "exitConfigurationCmd"
  [commands]="pdfCommands" [productName]="product?.internalName" [family]="family" [modifiedParams]="modifiedParameters"></app-custom-manual>
<app-export-ccf-options #exportCCFOptions></app-export-ccf-options>
<app-loading-window #loadingWindow></app-loading-window>
<app-cloud-save-config #cloudSaveConfig [webApiRes]="webApiRes" (blobSave)="saveBlobToCloud($event)" 
      (blobLoad)="loadBlobFromCloud($event)" (blobDelete)="deleteBlobFromCloud($event)">
</app-cloud-save-config>
<app-notification #productNotification [identifier]="'productNotification'"></app-notification>
<app-file-operator #fileOperator [identifier]="'fileOperator'"></app-file-operator>
<app-snackbar #snackbar/>