import { Component, EventEmitter, Input, Output, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TreeComponent, ITreeOptions, TreeNode } from '@ali-hm/angular-tree-component';
import { ParamMenuItem } from '../../classes/param-menu-item';

@Component({
  selector: 'app-param-section',
  templateUrl: './param-section.component.html',
  styleUrls: ['./param-section.component.css'],
})
export class ParamSectionComponent implements OnInit {
  @Input() id: string;
  @Input() item: ParamMenuItem;
  @Output() clicked = new EventEmitter<any>();

  @ViewChild('tree') treeComponent: TreeComponent;
  @ViewChild('titleButton') titleButton: ElementRef;
  currentActiveNode: TreeNode =null;
  visible = true;
  isOpen = false;

  hasSearchResults = true;
  image = '';

  treeItems: object[] = [];
  treeOptions: ITreeOptions = {
    nodeHeight: 70,
    levelPadding: 20,
    allowDrag: (node) => {
      return false;
    },
    allowDrop: (node) => {
      return false;
    }
  };
  openTreeBySearch: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.treeItems = this.item.getChildren();
    this.hasSearchResults = this.item.children.length > 0;
    this.image = 'assets/images/parameters/' + this.item.englishTitle.replace(/ /g, '') + '.svg';
  }

  titleClicked(item): void {
    const wrapper = { node: { data: { id: item.name }}};

    if(!this.openTreeBySearch) {
      this.clicked.emit(wrapper);
      const focusNode = this.treeComponent.treeModel.getFocusedNode();
      this.treeComponent.treeModel.collapseAll();
      if(focusNode) {
        this.currentActiveNode = null;
        this.treeComponent.treeModel.setFocusedNode(false);
        this.treeComponent.treeModel.setActiveNode(focusNode, false);
      }
    } else {
      this.openTreeBySearch = !this.openTreeBySearch;
    }
  }

  // nodeClicked(event: any): void {
    // event.node.toggleExpanded();
    // const temp = event.node.children;
    // this.clicked.emit(event);
  // }

  updateTree(searchFilter: string): void {
    // Search the tree for the searchFilter
    // If the tree has no nodes has to hide the HTML
    if (this.treeComponent) {
      this.treeComponent.treeModel.filterNodes(searchFilter, true);

      this.hasSearchResults = this.treeComponent.treeModel.hiddenNodes.length < this.treeComponent.treeModel.nodes.length;
    }

    this.visible = this.hasSearchResults || this.item.title.toLowerCase().indexOf(searchFilter) >= 0;
  }

  openTree(nodeId: string): void {
    const collapsed = this.titleButton.nativeElement.className.includes("collapsed"); 
    const someNode = this.treeComponent.treeModel.getNodeById(nodeId);
    if (collapsed) {
      if(someNode) this.openTreeBySearch = true;
      this.titleButton.nativeElement.click(); 
    } else {
      if(nodeId == this.item.name) this.clicked.emit(nodeId);
    }

    if(someNode) {
      let node = someNode.parent;
      while(node) {
        node.expand();
        node = node.parent;
      }
      someNode.setActiveAndVisible();
    }
  }

  setDefault(): void {
    this.image = 'assets/images/device.svg';
  }

  nodeClicked(event: any) {
    const activeNode = this.treeComponent.treeModel.getActiveNode();
    if(activeNode) {
      if(this.currentActiveNode != activeNode) {
        this.currentActiveNode = activeNode;
        const wrapper = { node: activeNode};
        this.clicked.emit(wrapper);
        activeNode.toggleExpanded();
      }
    } else {
      if(this.currentActiveNode) {
        this.currentActiveNode.toggleActivated();
        this.currentActiveNode.toggleExpanded();
      } else {
        const text = event.target.innerText;
        const id = this.recursiveTree(this.treeComponent.treeModel.nodes, text);
        if(id) {
          const wrapper = { node: { data: { id }}};
          this.clicked.emit(wrapper);
          const clickedNode = this.treeComponent.treeModel.getNodeById(id);
          if(clickedNode) {
            this.currentActiveNode = clickedNode;
            clickedNode.toggleExpanded();
          }
        }
      }
    }
  }
  recursiveTree(nodes: any, text: string) {
      for(let i = 0; i < nodes.length;i++) {
        if(nodes[i].name == text) {
            return nodes[i].id;
        } else {
          if(nodes[i]?.children?.length > 0) {
            let temp = this.recursiveTree(nodes[i].children, text);
            if(temp) {
              return temp;
            }
          }
        }
      }

      return null;
  }
}
