import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { protectedResources } from '../../auth-config';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { GeneralCloudService } from './general-cloud.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationManagementService  extends GeneralCloudService {
  urlEndpoint: string = protectedResources.apiTodoList.endpoint + "configurations";
  constructor(protected http: HttpClient, protected authService: MsalService, private msalBroadcastService: MsalBroadcastService,
   ) { super(http, authService) }

  getConfiguations(): Observable<Object> {
    return this.getToken().pipe(
      switchMap((value: HttpHeaders) => {
        // const url = this.urlEndpoint + "/aladdin";
        const url = this.urlEndpoint;
        return this.http.get(url, {headers: value});
      })
    )
  }

  getConfigurationById(id: string): Observable<Object> {
    return this.getToken().pipe(
      switchMap((value: HttpHeaders) => {

        const url = this.urlEndpoint + "/" + id;
        return this.http.get(url, {headers: value, responseType: "text"});
      })
    )
  }

  saveConfiguration(body: FormData): Observable<Object> {
    return this.getToken().pipe(
      switchMap((value: HttpHeaders) => {
        let headers = value;
        headers.set('Content-Type', "application/json")
               .set('Accept', "application/json"); 
        let options = {
          headers
        }
        const url = this.urlEndpoint;
        return this.http.post(url, body, options);
      })
    )
  }

  deleteConfiguration(identifies: string[]) {
    return this.getToken().pipe(
      switchMap((value: HttpHeaders) => {
        let options = {
          headers: value,
          params: {
            identifies
          }
        }
        const url = this.urlEndpoint;
        return this.http.delete(url, options);
      })
    )
  }
}
