import { UleService } from 'src/app/services/ule/ule.service';
import { Component, Input, Output, OnInit, EventEmitter, ViewChild,
         ViewChildren, QueryList, OnChanges, SimpleChanges } from '@angular/core';
import { Parameter } from 'src/app/classes/parameters';
import { Helper } from 'src/app/classes/utility';
import { StringFilledEditorComponent } from '../string-filled-editor/string-filled-editor.component';
import { ReadableAsciiComponent } from '../readable-ascii/readable-ascii.component';
import { CustomManualService } from 'src/app/services/custom-manual.service';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from 'src/app/services/settings.service';
import { uleModeEnum } from 'src/app/classes/enums/ule.enum';
import { UleModeComponent } from '../ule-mode/ule-mode.component';
import { Notification, NotificationType } from 'src/app/classes/utility/notification';
import { NotificationComponent } from '../notification/notification.component';


declare var $: any;
@Component({
  selector: 'app-param-editor',
  templateUrl: './param-editor.component.html',
  styleUrls: ['./param-editor.component.css'],
})
export class ParamEditorComponent implements OnInit, OnChanges {
  @Input() paramParentText = "";
  @Input() type = '';
  @Input() customManualEnabled = false;
  @Input() productName = '';
  @Input() readonly = false;
  @Input() value = '';
  @Input() parameter: Parameter;
  @Input() parametersOrigin: Parameter[] = [];
  @Input() interfaceValue: string;
  @Input() interfaceCode: string;
  @Input() uleModeComponent: UleModeComponent;
  @Input() notification: NotificationComponent;
  @Output() valueChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() toggleFromCustomManual: EventEmitter<string> = new EventEmitter<string>();
  @Output() throwError: EventEmitter<string> = new EventEmitter<string>();

  @ViewChildren('childParamEditor') childParamEditors: QueryList<ParamEditorComponent>;
  @ViewChild('stringFilledEditor') stringFilledEditor: StringFilledEditorComponent;
  @ViewChild('readableAsciiEditor') readableAsciiEditor: ReadableAsciiComponent;

  code = '';
  label = '';
  currentValue = '';
  currentValueText = '';
  backupCurrentValue = '';
  deviceValue = '';
  deviceValueText = '';
  defaultValue = '';
  helpLabelText = '';
  hiddenString: string = "*****";

  checkBoxValue = false;

  showInputText = false;
  showInputCombo = false;
  showInputComboAsCheckbox = false;
  showInputStringFilled = false;
  showInputReadableAscii = false;
  showDriverLicense = false;

  isInCustomManual = false;

  constructor(
    private customManualService: CustomManualService, 
    private translateService: TranslateService, 
    private settingsService: SettingsService,
    private uleService: UleService
  ) {}

  ngOnInit(): void {
    // Sometimes, this.parameter is not updated without reason. So the better way does use this.parametersOrigin
    // Example is code "T1SF"
    if (this.parameter.code) {
      let tempPara: Parameter;
      tempPara = this.parametersOrigin.find(it => it.name === this.parameter.name);
      if (tempPara) {
        this.parameter = tempPara;
      }
    }
    this.code = this.parameter.code;
    this.refreshLabel();
    this.defaultValue = this.parameter.type === 'hexInt'
                        ? Helper.hexToInt(this.parameter.defaultValue).toString()
                        : this.parameter.defaultValue;
    this.deviceValue = this.parameter.deviceValue;
    this.helpLabelText = this.getHelpLabelText();
    this.deviceValueText = Helper.hexToInt(this.parameter.deviceValue).toString();
    this.readonly = this.parameter.disabled;

    this.showInputText = this.parameter.type === 'hexInt' ||
                          (this.parameter.type === 'int' && this.parameter.options.length === 0);
    this.showInputStringFilled = this.parameter.type === 'stringFilled';
    this.showInputReadableAscii = this.parameter.type === 'readableAscii';
    this.showInputComboAsCheckbox = (this.parameter.options.length === 2 &&
                                     (this.parameter.options[0].name === 'Disable' || this.parameter.options[0].name === 'Disabled') &&
                                     (this.parameter.options[1].name === 'Enable' || this.parameter.options[1].name === 'Enabled')) ||
                                    this.parameter.type === 'switchHex';
    this.showInputCombo = !this.showInputText && !this.showInputStringFilled && !this.showInputReadableAscii && !this.showDriverLicense && !this.showInputComboAsCheckbox;
    this.refreshValue();

    this.customManualService.getCustomManualSubscriber()
    .subscribe(() => 
    {
      this.checkIsInCustomManual();
    });
  }

  getDropdownMarker(value: string) {
    let isDefault = value === this.defaultValue;
    if (isDefault) {
      return " - (" + this.translateService.instant("SHARED.DEFAULT") + ")";
    } else {
      return "";
    }
  }

  getHelpLabelText() {
    let defaultValue = "";

    if (this.parameter.type === 'hexInt') {
      defaultValue = Helper.hexToInt(this.parameter.defaultValue).toString();
    } else {
      defaultValue = this.parameter.defaultValue;
    }

    if(this.parameter.hideValue === true){
      defaultValue = this.hiddenString;
    }

    let defaultText =  this.translateService.instant("SHARED.DEFAULT") + ": " + defaultValue;
    let helpLabelText = "";
    if (this.parameter.type === 'hexInt') {
      let minString = Helper.hexToInt(this.parameter.min).toString();
      let maxString = Helper.hexToInt(this.parameter.max).toString();
      helpLabelText = minString + "-" + maxString + ", " + defaultText
      if (this.parameter.incrementBy > 1) {
        helpLabelText = helpLabelText + ", " + this.translateService.instant("SHARED.INCREMENT-BY") + ": " + this.parameter.incrementBy.toString();
      }
    } else if (this.parameter.type === 'int' && this.parameter.options.length === 0 && !isNaN(parseInt(this.parameter.min, 10)) && !isNaN(parseInt(this.parameter.max, 10))) {
      let minString =this.parameter.min;
      let maxString = this.parameter.max;
      helpLabelText = minString + "-" + maxString + ", " + defaultText
      if (this.parameter.incrementBy > 1) {
        helpLabelText = helpLabelText + ", " + this.translateService.instant("SHARED.INCREMENT-BY") + ": " + this.parameter.incrementBy.toString();
      }
    } else {
      helpLabelText = defaultText
    }
    return "(" + helpLabelText + ")";
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && changes.value.currentValue !== changes.value.previousValue) {
      if (changes.value.previousValue !== undefined) {
        if(this.uleService.currentUle.mode === uleModeEnum.CUSTOM && this.parameter.isPackParameter){
          this.uleModeComponent.setMode(uleModeEnum.ADV_FORMAT);
          this.refreshValue();
          this.valueChanged.emit(this.parameter.name);
        }else{
          this.refreshValue();
          this.valueChanged.emit(this.parameter.name);
        }
      }
    }
  }

  onChildParamValueChanged(value): void {
    this.valueChanged.emit(this.parameter.name);
  }

  onStringFilledParamValueChanged(value): void {
    this.valueChanged.emit(this.parameter.name);
  }

  checkIsInCustomManual() {
    const parameterTemp = this.parameter.parent ? this.parameter.parent : this.parameter;
    this.isInCustomManual = this.customManualService.isInManual(this.productName, parameterTemp);
  }

  selectOptionValue(item): void {
    try {
        this.backupCurrentValue = this.currentValue;
        // Change options text
        this.currentValue = item.value;
        if (item.value === item.name) {
            this.currentValueText = Helper.hexToInt(item.value).toString();
        } else {
            this.currentValueText = item.name;
        }

        // Change parameter value
        this.parameter.value = item.value;
        // Fire event for parent component
        this.valueChanged.emit(this.parameter.name);

        this.checkIsInCustomManual();
    } catch (error) {
        console.error('Error selecting option value:', error);
    }
}

  // Called to externally update input value
  changeInputValue(changedValue: string, setDeviceValue: boolean = true): void {
    if (this.parameter && this.childParamEditors && this.childParamEditors.length > 0) {
      if (this.parameter.type === "sumHex") {
        let numInput: number = parseInt(changedValue, 16);
        let index = 0;
        for (const childParameter of this.parameter.childParameters) {
          let strOption: string = childParameter.options[1].value;
          let numOption: number = parseInt(strOption, 16);
          let numCheck: number = numInput & numOption;
          if (numCheck && numCheck !== 0) {
            this.childParamEditors.get(index).changeInputValue(strOption, setDeviceValue);
          } else {
            this.childParamEditors.get(index).changeInputValue(childParameter.options[0].value, setDeviceValue);
          }
          
          index++;
        }
      } else {
        let valueStartingIndex:number = 0;
        let valueEndIndex:number = 0;
        let index = 0;
        for (const childParameter of this.parameter.childParameters)
        {
          valueEndIndex = Number(valueStartingIndex) + Number(childParameter.size);
          const value = changedValue.substring(valueStartingIndex,  valueEndIndex);
          if (value)
          {
            this.childParamEditors.get(index).changeInputValue(value, setDeviceValue);
          }
          valueStartingIndex = Number(valueStartingIndex) + Number(childParameter.size);
          index++;
        }
      }
    } else {
      if (setDeviceValue) {
        this.parameter.deviceValue = changedValue;
        this.deviceValue = changedValue;
      }

      this.currentValue = changedValue;
      if (this.showInputText) {
        if (this.parameter.type === 'int' && this.parameter.options.length === 0) {
          this.currentValueText = parseInt(changedValue, 10).toString();
        } else {
          this.currentValueText = Helper.hexToInt(changedValue).toString();
        }
        if (setDeviceValue) {
          this.deviceValueText = this.currentValueText;
        }

        // Value check and event raise
        this.checkParameter(false);
      } else if (this.showInputCombo) {
        const currentValueItem = this.parameter.options.find(it => it.value === changedValue);
        if (currentValueItem) {
          this.currentValueText = currentValueItem.name;
        } else {
          this.currentValueText = Helper.hexToInt(changedValue).toString();
        }

        if (setDeviceValue) {
          this.deviceValueText = this.currentValueText;
        }

        this.selectOptionValue(currentValueItem);
      } else if (this.showInputStringFilled) {
        this.stringFilledEditor.changeInputValue(changedValue, setDeviceValue);

        // Value check and event raise
        this.checkParameter(false);
      } else if (this.showInputReadableAscii) {
        this.readableAsciiEditor.changeInputValue(changedValue, setDeviceValue);

        // Value check and event raise
        this.checkParameter(false);
      } else if (this.showInputComboAsCheckbox) {
        // Value check and event raise
        this.checkParameter(false);
      }
    }
  }

  // Automatically called by ngmodelchange
  inputChangeValue(changedValue: string): void {
    if (this.parameter.type === 'int' && this.parameter.options.length === 0) {
      this.backupCurrentValue = this.currentValue;
      this.currentValue = changedValue;
    } else {
      this.backupCurrentValue = this.currentValue;
      this.currentValue = Helper.stringToHex(changedValue).toUpperCase().padStart(2, '0');
    }
  }

  // Automatically called by ngmodelchange
  chkChangeValue(changedValue: boolean): void {
    this.backupCurrentValue = this.currentValue;
    this.currentValue = changedValue ? '01' : '00';
    //Get the current value if the parameter option value is available
    if (changedValue) {
      let currentCheckBoxValue: string = this.parameter.options[1].value;
      if (this.parameter.type === 'switchHex' && currentCheckBoxValue) {
        this.currentValue = currentCheckBoxValue;
      } else if(currentCheckBoxValue["@name"] === "Enable" && currentCheckBoxValue["#text"] && currentCheckBoxValue["#text"].length > 0) {
        this.currentValue = currentCheckBoxValue["#text"];
      } 
    } else {
      let currentCheckBoxValue: string = this.parameter.options[0].value;
      if (this.parameter.type === 'switchHex' && currentCheckBoxValue) {
        this.currentValue = currentCheckBoxValue;
      } else if(currentCheckBoxValue["@name"] === "Disable" && currentCheckBoxValue["#text"] && currentCheckBoxValue["#text"].length > 0) {
        this.currentValue = currentCheckBoxValue["#text"];
      }
    }
    this.checkParameter();
  }

  checkParameter(showErrorMessage: boolean = true): void {
    // Check if parameter is in range
    if (this.parameter.checkParamValue(this.currentValue)) {
      if (this.parameter.type === 'int' && this.parameter.options.length === 0 && this.parameter.max) {
        this.parameter.value = this.currentValue.padStart(this.parameter.max.length, '0');
      } else if (this.parameter.min) {
        this.parameter.value = this.currentValue.padStart(this.parameter.size, '0');
      } else {
        this.parameter.value = this.currentValue;
      }
      if (this.parameter.type === 'int' && this.parameter.options.length === 0) {
        this.currentValueText = parseInt(this.currentValue, 10).toString();
      } else {
        this.currentValueText = Helper.hexToInt(this.currentValue).toString();
      }
      // Fire event for parent component
      this.valueChanged.emit(this.parameter.name);

      // Check if the changed value is in custom manual
      this.checkIsInCustomManual();
    } else {
      // Out of range --> Error and value reset
      if (showErrorMessage) {
        if (this.parameter.type === 'int' && this.parameter.options.length === 0) {
          this.throwError.emit(this.translateService.instant('NOTIFICATION.PARAMETER-OUT-OF-RANGE-RESETING-OLD-VALUE') + ' ' + this.parameter.value);
        } else {
          this.throwError.emit(this.translateService.instant('NOTIFICATION.PARAMETER-OUT-OF-RANGE-RESETING-OLD-VALUE') + ' ' + Helper.hexToInt(this.parameter.value).toString());
        }
      }

      setTimeout(() => {
        this.currentValue = this.parameter.value;
        if (this.parameter.type === 'int' && this.parameter.options.length === 0) {
          this.currentValueText = parseInt(this.parameter.value, 10).toString();
        } else {
          this.currentValueText = Helper.hexToInt(this.parameter.value).toString();
        }
      }, 100);
    }
  }

  resetToDefault(): void {
    if (this.parameter && this.childParamEditors && this.childParamEditors.length > 0) {
      for (const childParam of this.childParamEditors) {
        childParam.resetToDefault();
      }
    } else {
      this.changeInputValue(this.parameter.defaultValue, false);
    }
  }

  refreshLabel(): void {
    this.label = this.parameter.label || "";
  }

  isShowParamIds(): boolean {
    return this.settingsService.getShowParameterCode();
  }

  codeIsEmpty(): boolean {
    return !this.code || this.code === "" || this.code === "undefined" || this.code === "null";
  }

  sendEventForCustomManual(): void {
    const parameterTemp = this.parameter.parent? this.parameter.parent : this.parameter;
    this.toggleFromCustomManual.emit(parameterTemp.name);
    $('[data-toggle="tooltip"]').tooltip('hide');
  }

  onToggleFromCustomManual(name: string): void {
    this.toggleFromCustomManual.emit(name);
  }

  hasSetValueButton(): boolean {
    return (this.parameter.setValueButtonText !== undefined && this.parameter.setValueButtonValue !== undefined);
  }

  setValueFromButton(): void {
    if (!this.readonly) {
      this.changeInputValue(this.parameter.setValueButtonValue,false);
    }
  }

  private refreshValue(byBackup: boolean = false): void {
    if(byBackup){
      this.parameter.value = this.backupCurrentValue;
    }

    this.currentValue = this.parameter.value;
    const currentValueItem = this.parameter.options.find(it => it.value === this.currentValue);
    if (currentValueItem) {
      this.currentValueText = currentValueItem.name;
    } else {
      if (this.parameter.type === 'int' && this.parameter.options.length === 0) {
        //Remove padding start character '0'
        this.currentValueText = parseInt(this.parameter.value, 10).toString();
      } else {
        this.currentValueText = Helper.hexToInt(this.parameter.value).toString();
      }
    }

    if (this.showInputComboAsCheckbox) {
      if (this.parameter.type === 'switchHex') {
        this.checkBoxValue = this.currentValue === this.parameter.options[1].value;
      } else {
        let enableValue: string = this.getParamEnableValue(this.parameter);
        this.checkBoxValue = this.currentValue === enableValue;
      }
    }
  }

  public getParamEnableValue(param: Parameter): string{
    let value: string = "01";
    const enableObj = param.options.find(el => el.name === "Enable" || el.name === "Enabled");
    if (typeof enableObj.value === 'string'){
      return value;
    }else{
      if(enableObj.value['#text']){
        value = enableObj.value['#text'];
      }
    }
    return value;
  }
}
