<div class="modal-container">
  <div class="modal-header">
    <h5>ULE Preview</h5>
    <button (click)="close()">Close</button>
  </div>
  <div class="modal-body">
    <div #codeEditor style="height: 400px; width: 100%;"></div>
  </div>
</div>
<div class="modal-overlay" (click)="close()"></div>

