import { BehaviorSubject } from 'rxjs';
import { Notification, NotificationResult, NotificationSizeEnum, NotificationType } from './../../classes/utility/notification';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  @Input() identifier: string  = "";
  @ViewChild('noShowAgainRef') noShowAgainRef: ElementRef;

  public modalRef: NotificationComponent;
  notification: Notification = new Notification(NotificationType.Error, "");
  selectionAction = new BehaviorSubject<NotificationResult>({select: "", noShowAgain: false});
  
  constructor() { }
  
  ngOnInit(): void {
  }

  showAlert(notification: Notification): Promise<NotificationResult>{
    this.notification = notification;
    if(this.noShowAgainRef){
      this.noShowAgainRef.nativeElement.checked = this.notification.noShowAgain.checked;
    }
    const button = document.getElementById(this.identifier + "Button");
    button.click();

    let res: NotificationResult = {select: "", noShowAgain: false};
    const subscriber = this.selectionAction.subscribe((result) => {
      res = result;
    });
    res = {select: "", noShowAgain: false};

    const onFinish = () => {
      subscriber.unsubscribe();
    }

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        (function waitForConfirm(){
          if(res.select !== ""){
            switch(res.select){
              case "confirm":
                onFinish();
                return resolve({select: "confirm", noShowAgain: res.noShowAgain});
              case "cancel":
                onFinish();
                return resolve({select: "cancel", noShowAgain: res.noShowAgain});
              case "ok":
                onFinish();
                return resolve({select: "ok", noShowAgain: res.noShowAgain});
              case "yes":
                onFinish();
                return resolve({select: "yes", noShowAgain: res.noShowAgain});
              case "no":
                onFinish();
                return resolve({select: "no", noShowAgain: res.noShowAgain});
              default:
                onFinish();
                return reject({select: "unknown", noShowAgain: res.noShowAgain});
            }
          }else{
            setTimeout(waitForConfirm,200);
          }
        })();
      }, 0);
    });
  }

  onOK(): void{
    this.selectionAction.next({select: "ok", noShowAgain: this.noShowAgainRef? this.noShowAgainRef.nativeElement.checked: false});
  }
  
  onConfirm(): void{
    this.selectionAction.next({select: "confirm", noShowAgain: this.noShowAgainRef? this.noShowAgainRef.nativeElement.checked: false});
  }

  onYes(): void{
    this.selectionAction.next({select: "yes", noShowAgain: this.noShowAgainRef? this.noShowAgainRef.nativeElement.checked: false});
  }

  onNo(): void{
    this.selectionAction.next({select: "no", noShowAgain: this.noShowAgainRef? this.noShowAgainRef.nativeElement.checked: false});
  }
  onCancel(): void{
    this.selectionAction.next({select: "cancel", noShowAgain: this.noShowAgainRef? this.noShowAgainRef.nativeElement.checked: false});
  }

  getColor(): string{
    let color = "";
    switch (this.notification.type) {
      case NotificationType.Success:
        color = "green";
        break;
      case NotificationType.Warning:
        color = "#FFA500";
        break;
      case NotificationType.Confirm:
        color = "#012b49";
        break;
      case NotificationType.Info:
        color = "#012b49";
        break;    
      default:
        color = "#cc3335";
        break;
    }
    return color;
  }

  getBorder(): string{
    let border = "1px solid ";
    border += this.getColor();
    return border;
  }

  getSrc(): string{
    let src:string = "assets/images/notification/";
    switch (this.notification.type) {
      case NotificationType.Success:
        src += "success";
        break;
      case NotificationType.Warning:
        src += "warning";
        break;
      case NotificationType.Confirm:
        src += "confirm";
        break;
      case NotificationType.Info:
        src += "info";
        break;  
      case NotificationType.Option:
        src += "option";
        break;   
      default:
        src += "error";
        break;
    }
    src += ".png";
    return src;
  }

  isConfirm(): boolean{
    if(this.notification.type === NotificationType.Confirm){
      return true;
    }

    return false;
  }
  isOption(): boolean{
    if(this.notification.type === NotificationType.Option){
      return true;
    }

    return false;
  }

  getModalSize(): string{
    let size:string = "modal-dialog ";

    switch (this.notification.size) {
      case NotificationSizeEnum.SM:
        size += "modal-sm";
        break;
      case NotificationSizeEnum.LARGE:
        size += "modal-lg";
        break;
      case NotificationSizeEnum.EXTRA_LARGE:
        size += "modal-xl";
        break;
      default:
        size += "";
        break;
    }

    return size;
  }
}
