import { Component, ElementRef, HostListener, output } from '@angular/core';
import { input } from '@angular/core';

@Component({
  selector: 'floating-card',
  templateUrl: './floating-card.component.html',
  styleUrl: './floating-card.component.css'
})
export class FloatingCardComponent {
  title = input<string>("Card Title");  

  clickOut = output<'out' | 'in'>();

  private init = false;
  constructor(private eRef: ElementRef) {
  }

  @HostListener('document:click', ['$event'])
  onClickOut(event) {
    if(this.init == false){
      this.init = true;
      return;
    }
    
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.clickOut.emit("out");
    }
  }
}
