export type SettingGroup = {
    title: string;
    description: string;
    default: SettingGroupItem;
    settingItems: SettingGroupItem[];
}

export type SettingGroupItem = {
    type: 'radio' | 'select' | 'checkbox';
    label: string;
    value: any;
    tooltip?: string;
}

export const CCF_SETTINGS_DEFAULT_DATA: SettingGroup[] = [
    {
      title: "Reset Command",
      description: "Choose the reset default command when switching interfaces.",
      default: {
        type: 'radio',
        label: '$Mxx',
        value: 'mxx',
      },
      settingItems: [
        {
            type: 'radio',
            label: '$Mxx',
            value: 'mxx',
            tooltip: 'Reset default by command Mxx'
        },
        {
            type: 'radio',
            label: '$M00',
            value: 'm00',
            tooltip: 'Reset default by command M00'
        }
      ]
    }
];

export type CCFSetting = {
    interfaceCommand: 'mxx' | 'm00';
};