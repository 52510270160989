import { Component, Input, OnInit } from '@angular/core';
import {
  Notification,
  NotificationType,
} from 'src/app/classes/utility/notification';
import { NotificationComponent } from '../notification/notification.component';
import { TabItem } from 'src/app/classes/utility';
import { TranslateService } from '@ngx-translate/core';
import { SatelliteType } from 'src/app/classes/enums/satellite-type';
import { SatelliteStatus } from 'src/app/classes/enums/satellite-status';
import { ProductComponent } from 'src/app/pages/product/product.component';

interface SatelliteDevice {
  id: string;
  type: string;
  status: string;
  pairing: boolean;
  needsReset?: boolean;
  notDetected?: boolean;
}

interface PairedSatellite {
  macAddress: string;
  type: string;
}

@Component({
  selector: 'app-satellite-pairing',
  templateUrl: './satellite-pairing.component.html',
  styleUrls: ['./satellite-pairing.component.css'],
})
export class SatellitePairingComponent implements OnInit {
  // @Input() isOnline: boolean = false;
  @Input() notification: NotificationComponent;
  // @Output() SendPairDeviceCommand: EventEmitter<any> = new EventEmitter();
  @Input() tabSampleElement: TabItem;
  @Input() pairingRemovingCmd: string;
  @Input() clearAllCmd: string;
  @Input() listAllSatelliteCmd: string;
  @Input() checkStatusCmd: string;
  @Input() listAllDevicesCmd: string;

  // standaloneMode: boolean = false;
  // isRemoted: boolean = false;
  allSatelliteInScannerList: string = '';
  needToBeResetStatus: string = 'Available (Need to be reset)';
  current: string = 'current';
  listAllOpearation: string = 'getListAllSatellite';
  statusOperation = 'checkSatelliteStatus';
  pairOperation = 'pairSatellite';
  removeAllOperation = 'removeAllSatellites';
  unPairOperation = 'unPairSatellite';
  ACKByte: string = '0x06';
  satelliteDevices: SatelliteDevice[] = [];
  filteredSatelliteDevices: SatelliteDevice[] = [];
  sortedSatelliteDevices: SatelliteDevice[] = [];
  pairedSatellites: PairedSatellite[] = [];
  detectButtonDisabled: boolean = false;
  isResponseError: boolean = false;
  isDisplayedNotification: boolean = false;
  DEVICE_PATTERN =
    /device_type_str\((\w+)\)\s+device_id_str\(([0-9A-Fa-f:]{17})\)/g;
  typeOptions: string[] = [];
  statusOptions: string[] = [];
  pairingOptions: { [key: string]: string } = {
    Yes: 'Yes',
    No: 'No',
  };

  typeFilters: { [key: string]: boolean } = {};
  statusFilters: { [key: string]: boolean } = {};
  pairingFilters: { [key: string]: boolean } = {};

  constructor(private translate: TranslateService) {
    // this.remoteService.isRemoted.subscribe((value) => {
    //   this.isRemoted = value;
    // });
  }

  ngOnInit(): void {
    window.satelliteComponent = {
      getCheckStatusCmd: () => this.getCheckStatusCmd(),
    };
    ProductComponent.isActiveHover = false;
    // Initialize filter objects
    this.typeOptions = Object.values(SatelliteType);
    this.statusOptions = Object.values(SatelliteStatus);
    this.typeOptions.forEach((type) => (this.typeFilters[type] = false));
    this.statusOptions.forEach(
      (status) => (this.statusFilters[status] = false)
    );
    Object.keys(this.pairingOptions).forEach(
      (option) => (this.pairingFilters[option] = false)
    );
  }

  getCheckStatusCmd(): string {
    return this.checkStatusCmd;
  }

  DetectSatelliteWithStatusToPair(includeStatus: boolean) {
    let allDeviceInLocalEthernetList: string = '';
    //Get all Satellite
    allDeviceInLocalEthernetList = window.getListAllDiscoverableDevices(
      this.listAllDevicesCmd,
      includeStatus === true ? 'true' : 'false'
    );
    //Check BasePairingList
    this.allSatelliteInScannerList = window.satelliteOperation(
      this.listAllOpearation,
      this.listAllSatelliteCmd
    );
    if (
      allDeviceInLocalEthernetList === 'null' ||
      this.allSatelliteInScannerList === 'null'
    ) {
      this.showNotification(
        NotificationType.Error,
        this.translate.instant('SATELLITE-PAIRING.CANNOT_SEND_COMMAND_MESSAGE'),
        this.translate.instant('SATELLITE-PAIRING.CANNOT_SEND_COMMAND_TITLE')
      );
    } else {
      this.parseGetListAllDiscoverableDevicesResponse(
        allDeviceInLocalEthernetList
      );
    }
  }

  checkDeviceStatus(device: SatelliteDevice): void {
    const checkStatusCommand = `${this.checkStatusCmd}"${device.id}"00`;
    const checkStatusReponse = window.satelliteOperation(
      this.statusOperation,
      checkStatusCommand
    );
    this.allSatelliteInScannerList = window.satelliteOperation(
      this.listAllOpearation,
      this.listAllSatelliteCmd
    );

    if (
      checkStatusReponse.includes(SatelliteStatus.Error) ||
      this.allSatelliteInScannerList.includes(SatelliteStatus.Error)
    ) {
      this.showNotification(
        NotificationType.Error,
        this.translate.instant('SATELLITE-PAIRING.CANNOT_SEND_COMMAND_MESSAGE'),
        this.translate.instant('SATELLITE-PAIRING.CANNOT_SEND_COMMAND_TITLE')
      );
      return;
    }

    this.extractPairedSatellitesFromScannerResponse();
    const isInPairingList = this.pairedSatellites.some(
      (satellite) => satellite.macAddress === device.id
    );
    const updatedStatus = this.updateDeviceStatus(
      device,
      checkStatusReponse,
      isInPairingList
    );
    Object.assign(device, updatedStatus);
  }

  RemoveAllPairedSatellite() {
    const removeAllSatelliteResponse = window.satelliteOperation(
      this.removeAllOperation,
      this.clearAllCmd
    );

    if (removeAllSatelliteResponse.includes(this.ACKByte)) {
      let statusChanged = false;
      this.satelliteDevices.forEach((device) => {
        if (device.status === SatelliteStatus.Paired) {
          device.status = this.needToBeResetStatus;
          device.pairing = false;
          device.needsReset = true;
          statusChanged = true;
        }
      });
      if (statusChanged) {
        this.detectButtonDisabled = true;
        this.showNotification(
          NotificationType.Warning,
          this.translate.instant(
            'SATELLITE-PAIRING.HAS_CHANGE_WARNING_MESSAGE'
          ),
          this.translate.instant('SATELLITE-PAIRING.HAS_CHANGE_WARNING_TITLE')
        );
      } else {
        this.showNotification(
          NotificationType.Info,
          this.translate.instant('SATELLITE-PAIRING.NO_CHANGE_MESSAGE'),
          this.translate.instant('SATELLITE-PAIRING.NO_CHANGE_TITLE')
        );
      }
    } else {
      this.showNotification(
        NotificationType.Error,
        this.translate.instant('SATELLITE-PAIRING.CANNOT_SEND_COMMAND_MESSAGE'),
        this.translate.instant('SATELLITE-PAIRING.CANNOT_SEND_COMMAND_TITLE')
      );
    }
  }

  pairDevice(device: SatelliteDevice) {
    let typeCode: string;
    switch (device.type) {
      case SatelliteType.TDR:
        typeCode = '02';
        break;
      case SatelliteType.CCMV:
        typeCode = '03';
        break;
      case SatelliteType.CCMH:
        typeCode = '04';
        break;
      default:
        console.error(`Unknown device type: ${device.type}`);
        return;
    }

    const pairCommand = `${this.pairingRemovingCmd}${typeCode}"${device.id}"00`;

    const pairSatelliteResponse = window.satelliteOperation(
      this.pairOperation,
      pairCommand
    );

    // Handle the response
    if (pairSatelliteResponse.includes(this.ACKByte)) {
      // Pairing successful
      device.status = SatelliteStatus.Paired;
      device.pairing = true;
      this.detectButtonDisabled = true;
      this.showNotification(
        NotificationType.Warning,
        this.translate.instant('SATELLITE-PAIRING.PAIRED_MESSAGE'),
        this.translate.instant('SATELLITE-PAIRING.PAIRED_TITLE')
      );
    } else {
      this.showNotification(
        NotificationType.Error,
        this.translate.instant('SATELLITE-PAIRING.CANNOT_SEND_COMMAND_MESSAGE'),
        this.translate.instant('SATELLITE-PAIRING.CANNOT_SEND_COMMAND_TITLE')
      );
    }
  }

  unpairDevice(device: SatelliteDevice) {
    const unPairCommand = `${this.pairingRemovingCmd}05"${device.id}"00`;

    const unPairSatelliteResponse = window.satelliteOperation(
      this.unPairOperation,
      unPairCommand
    );

    // Handle the response
    if (unPairSatelliteResponse.includes(this.ACKByte)) {
      // Pairing successful
      device.status = this.needToBeResetStatus;
      device.pairing = false;
      device.needsReset = true;
      this.detectButtonDisabled = true;
      if (this.isDisplayedNotification == false) {
        this.isDisplayedNotification = true;
        this.showNotification(
          NotificationType.Warning,
          this.translate.instant(
            'SATELLITE-PAIRING.HAS_CHANGE_WARNING_MESSAGE'
          ),
          this.translate.instant('SATELLITE-PAIRING.HAS_CHANGE_WARNING_TITLE')
        );
      }
    } else {
      this.showNotification(
        NotificationType.Error,
        this.translate.instant('SATELLITE-PAIRING.CANNOT_SEND_COMMAND_MESSAGE'),
        this.translate.instant('SATELLITE-PAIRING.CANNOT_SEND_COMMAND_TITLE')
      );
    }
  }

  parseGetListAllDiscoverableDevicesResponse(
    allDeviceInLocalEthernetList: string
  ) {
    const services = allDeviceInLocalEthernetList
      .split('\n')
      .map((line) => line.trim())
      .filter((line) => line.length > 0);

    this.satelliteDevices = services.map(this.parseSatelliteDevice.bind(this));

    if (this.isResponseError === true) {
      this.showNotification(
        NotificationType.Error,
        this.translate.instant('SATELLITE-PAIRING.CANNOT_SEND_COMMAND_MESSAGE'),
        this.translate.instant('SATELLITE-PAIRING.CANNOT_SEND_COMMAND_TITLE')
      );
    }

    this.checkAvailablePairingSatellites();

    this.sortedSatelliteDevices = this.sortSatelliteDevicesByType(
      this.satelliteDevices
    );
  }

  parseSatelliteDevice(service: string): SatelliteDevice {
    let [id, type, status] = service.split('|');
    let device: SatelliteDevice = {
      id,
      type,
      status,
      pairing: false,
      needsReset: false,
    };

    if (status.includes(SatelliteStatus.Error)) {
      this.isResponseError = true;
      device.status = '';
    } else {
      this.extractPairedSatellitesFromScannerResponse();
      const isInPairingList = this.pairedSatellites.some(
        (satellite) => satellite.macAddress === device.id
      );
      const updatedStatus = this.updateDeviceStatus(
        device,
        status,
        isInPairingList
      );
      Object.assign(device, updatedStatus);
    }

    return device;
  }

  private updateDeviceStatus(
    deviceInfo: SatelliteDevice,
    status: string,
    isInPairingList: boolean
  ): Partial<SatelliteDevice> {
    let { pairing, needsReset } = deviceInfo;
    let updatedStatus = status;

    if (isInPairingList) {
      pairing = true;
    }

    if (status.includes(SatelliteStatus.Paired)) {
      needsReset = false;

      if (status.includes(this.current)) {
        updatedStatus = SatelliteStatus.Paired;

        if (!isInPairingList) {
          this.detectButtonDisabled = true;
          pairing = false;
          needsReset = true;
          this.showNotification(
            NotificationType.Warning,
            this.translate.instant(
              'SATELLITE-PAIRING.HAS_CHANGE_WARNING_MESSAGE'
            ),
            this.translate.instant('SATELLITE-PAIRING.HAS_CHANGE_WARNING_TITLE')
          );
        } else {
          pairing = true;
          needsReset = false;
        }
      } else {
        const scannerMacAddress = this.extractMacAddress(status);
        pairing = false;
        updatedStatus = `${SatelliteStatus.PairedWithOtherScanner} (${scannerMacAddress})`;
      }
    } else if (status.includes(SatelliteStatus.Available)) {
      updatedStatus = SatelliteStatus.Available;
      needsReset = false;
    }

    return { status: updatedStatus, pairing, needsReset };
  }

  extractPairedSatellitesFromScannerResponse() {
    const lines = this.allSatelliteInScannerList.split('\n');
    this.pairedSatellites = [];
    for (const line of lines) {
      const matches = [...line.matchAll(this.DEVICE_PATTERN)];
      for (const match of matches) {
        if (match && match.length === 3) {
          const type = match[1];
          const macAddress = match[2];
          this.pairedSatellites.push({ type, macAddress });
        }
      }
    }
  }

  checkAvailablePairingSatellites() {
    this.pairedSatellites.forEach((satellite: PairedSatellite) => {
      const deviceExists = this.satelliteDevices.some(
        (device) => device.id === satellite.macAddress
      );

      if (!deviceExists) {
        // If the MAC address doesn't exist in satelliteDevices, add it
        this.satelliteDevices.push({
          id: satellite.macAddress,
          type: satellite.type,
          status: SatelliteStatus.NotDetected,
          pairing: true,
          needsReset: false,
          notDetected: true,
        });
      }
    });
  }

  sortSatelliteDevicesByType(
    satelliteDevices: SatelliteDevice[]
  ): SatelliteDevice[] {
    let sortedSatelliteDevices: SatelliteDevice[] = [];

    return (sortedSatelliteDevices = [
      ...satelliteDevices.filter(
        (device) => device.type === SatelliteType.CCMH
      ),
      ...satelliteDevices.filter(
        (device) => device.type === SatelliteType.CCMV
      ),
      ...satelliteDevices.filter((device) => device.type === SatelliteType.TDR),
    ]);
  }

  applyFilters() {
    this.filteredSatelliteDevices = this.satelliteDevices.filter((device) => {
      const typeFilterActive = Object.values(this.typeFilters).some(
        (value) => value
      );
      const statusFilterActive = Object.values(this.statusFilters).some(
        (value) => value
      );
      const pairingFilterActive = Object.values(this.pairingFilters).some(
        (value) => value
      );

      const typeMatch =
        !typeFilterActive || this.typeFilters[device.type] || false;

      const statusMatch =
        !statusFilterActive ||
        Object.keys(this.statusFilters).some((status) => {
          // Exact match for the status
          const exactMatch =
            status.toLowerCase() === device.status.toLowerCase();

          // Handle the case of "Paired" vs "Paired with other scanner"
          const partialMatch =
            status.toLowerCase() === 'paired'
              ? device.status.toLowerCase() === 'paired'
              : status.toLowerCase() === 'paired with other scanner'
              ? device.status
                  .toLowerCase()
                  .startsWith('paired with other scanner')
              : false;

          return this.statusFilters[status] && (exactMatch || partialMatch);
        });

      const pairingMatch =
        !pairingFilterActive ||
        this.pairingFilters[device.pairing ? 'Yes' : 'No'];

      return typeMatch && statusMatch && pairingMatch;
    });

    this.sortedSatelliteDevices = this.sortSatelliteDevicesByType(
      this.filteredSatelliteDevices
    );
  }

  isDisabledDetectButton(): boolean {
    return this.detectButtonDisabled;
  }

  extractMacAddress(input: string): string | null {
    // Regular expression to match MAC address format
    const macPattern =
      /([0-9A-Fa-f]{2}:[0-9A-Fa-f]{2}:[0-9A-Fa-f]{2}:[0-9A-Fa-f]{2}:[0-9A-Fa-f]{2}:[0-9A-Fa-f]{2})/;
    // Try to find a match in the input string
    const match = input.match(macPattern);
    // If a match is found, return it; otherwise, return null
    return match ? match[1] : null;
  }

  CloseTheDeviceTab() {
    let responseFromAladdin = window.closeTabHandlerFromJs(
      this.tabSampleElement.url
    );
    console.log(responseFromAladdin);
  }

  showNotification(type: NotificationType, message: string, title: string) {
    this.notification
      .showAlert(new Notification(type, message, title))
      .then((res) => {
        if (res.select === 'confirm') {
          console.log('confirm');
        } else {
          console.log('reject');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
