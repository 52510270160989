/**
 * create custom input directive 
 * functionality: store the input value in the input field on send, restore to input field on key arrow up and key arrow down button 
 */

import { Directive, HostListener, Output, EventEmitter, model, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: 'input[rollback-input]'
})
export class RollbackInputDirective {
    @Output() storeChanged = new EventEmitter<string[]>();

    private index: number; // index of the store array [0 -> 9]
    private store: string[] = [];
    private KEY_CODE = {
      "UP_ARROW": 38,
      "DOWN_ARROW": 40,
      "ENTER": 13
    }

    constructor(private el: ElementRef, private renderer: Renderer2) { }
    
    ngOnInit() {
      this.index = this.store.length - 1;
    }
  
    getValue(): string {
      return this.el.nativeElement.value;
    }

    setValue(value: string): void {
        this.el.nativeElement.value = value;
    }

    onSend(): void {
      if(this.getValue() == '') return;
      if(this.getValue() == this.store[this.store.length - 1]) return;
  
      if(this.store.length == 10){
        this.store.shift();
      }
      this.store.push(this.getValue());
      this.storeChanged.emit(this.store);
      this.index = this.store.length;
      //this.setValue('');
    }
  
    @HostListener('keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): void {
      this.onKeyDown(event);
    }
  
    onKeyDown(event: KeyboardEvent): void { 
      if(event.keyCode === this.KEY_CODE.ENTER) {
        this.onSend();
        return;
      }
      if(this.store.length == 0) return;
  
      if (event.keyCode === this.KEY_CODE.UP_ARROW) {
        this.index = this.index == 0 ? 0 : this.index - 1;      
        this.setValue(this.store[this.index]);
      } else if (event.keyCode === this.KEY_CODE.DOWN_ARROW) {
        this.index = this.index == this.store.length - 1 ? this.store.length - 1 : this.index + 1;
        this.setValue(this.store[this.index]);
      }
    }
  
    getStore(): string[] {
      return this.store;
    }

    setStore(store: string[]): void {
      this.index = this.store.length - 1;
      this.store = store;
    }
}