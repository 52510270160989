import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, observable } from 'rxjs';
import { find, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import { HelperService } from '../services/helper.service';
import { ParamMenuItem } from '../classes/param-menu-item';
import { ProductData } from '../classes/products/product-data';
import { ConfigurationPage } from '../classes/configuration-page';
import { Parameter, ParameterRule, ParameterRuleAcceptedValue } from '../classes/parameters';
import { Helper, Command, HelpItem } from '../classes/utility';
import { Message } from '../classes/utility';
import { Product } from '../classes/products/product';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root',
})
export class XmlService {
  public editedProducts: ProductData[] = [];

  public genericDeviceContent = '';
  standaloneMode: boolean;

  constructor(private http: HttpClient, private translate: TranslateService, private languageService: LanguageService, private helperService: HelperService, private router: Router) {
    Helper.standaloneMode
    .subscribe(value => {
      this.standaloneMode = value;
    });
  }

  isValid(file: string): boolean {
    const parsedXml = this.parseXml(file);
    console.log(parsedXml);

    return parsedXml !== undefined && parsedXml.parameters && parsedXml.rootPage && parsedXml['@name'] && parsedXml['@releaseSW'];
  }

  getXmlConfig(name: string, product: Product, connection: string): Observable<ProductData> {
    return new Observable((observer) => {
      // Check if product was already edited
      const alreadyEdited = this.editedProducts.find(it => it.name === name && it.connection === connection);
      if (alreadyEdited) {
        // get new language labels for already edited tabs
        if(alreadyEdited.parameters[0].currentLang != alreadyEdited.parameters[0].previousLang){
          this.http.get(Helper.baseProductsDirectory + product.configUrl, { responseType: 'text' })
        .subscribe((content: string) => {
          const newXmlObj = this.parseXmlToProductData(name, content, connection);

          // get new labels for params
          for (let i = 0; i < alreadyEdited.parameters.length; i++) {
            alreadyEdited.parameters[i].label = newXmlObj.parameters[i].label;
            for (let j = 0; j < alreadyEdited.parameters[i].options.length; j++) {
              alreadyEdited.parameters[i].options[j].name = newXmlObj.parameters[i].options[j].name;
            }
          }

          // get new labels for paramMenu
          for (let i = 0; i < alreadyEdited.paramMenu.length; i++) {
            alreadyEdited.paramMenu[i].title = newXmlObj.paramMenu[i].title;

            for (let j = 0; j < alreadyEdited.paramMenu[i].children.length; j++) {
              alreadyEdited.paramMenu[i].children[j].title = newXmlObj.paramMenu[i].children[j].title;
              
              for (let k = 0; k < alreadyEdited.paramMenu[i].children[j].children.length; k++) {
                alreadyEdited.paramMenu[i].children[j].children[k].title = newXmlObj.paramMenu[i].children[j].children[k].title;

                for (let l = 0; l < alreadyEdited.paramMenu[i].children[j].children[k].children.length; l++) {
                  alreadyEdited.paramMenu[i].children[j].children[k].children[l].title = newXmlObj.paramMenu[i].children[j].children[k].children[l].title;
                }
              }
            }
          }

          // get new labels for configPages
          for (let i = 0; i < alreadyEdited.configurationPages.length; i++) {
            alreadyEdited.configurationPages[i].name = newXmlObj.configurationPages[i].name;
            for (let j = 0; j < alreadyEdited.configurationPages[i].configurationElements.length ; j++) {
                alreadyEdited.configurationPages[i].configurationElements[j].text = newXmlObj.configurationPages[i].configurationElements[j].text;
            }
          }

          // get new labels for messages (isOnline)
          for (let i = 0; i < alreadyEdited.messages.length; i++) {
            alreadyEdited.messages[i].description = newXmlObj.messages[i].description;
            console.log("mess: ", alreadyEdited.messages[i].description = newXmlObj.messages[i].description);
          }
        })
        }
        observer.next(alreadyEdited);
        observer.complete();
      } else {
        this.http.get(Helper.baseProductsDirectory + product.getCurrentLanguageConfigUrl(this.translate), { responseType: 'text' })
        .subscribe((content: string) => {
          const xmlObj = this.parseXmlToProductData(name, content, connection);
          observer.next(xmlObj);
          observer.complete();
        },
        (error) => {
          const alreadyEditedDefault = this.editedProducts.find(it => it.name === name);
          if (alreadyEditedDefault) {
            observer.next(alreadyEditedDefault);
            observer.complete();
          } else {
            // Not found the localizated one, fallback to default
            this.http.get(Helper.baseProductsDirectory + product.configUrl, { responseType: 'text' })
            .subscribe((content: string) => {
              const xmlObj = this.parseXmlToProductData(name, content, connection);              
              observer.next(xmlObj);
              observer.complete();
            }, (error) => {
              //Missing xml package, request to check on server
              if (this.standaloneMode) {
                let indexTemp = product.releases.findIndex(obj => obj.index === product.index.toString());
                //call java function from javascript
                if (product.releases.length > 1 && indexTemp !== product.releases.length - 1) {
                  let responseFromAladdin = window.requestToOpenOldVersionForMissingPackage(product.internalName,
                                                                                           product.releases[indexTemp].index,
                                                                                           product.releases[indexTemp+1].index);
                  if (responseFromAladdin !== "use_older_package") {
                    this.router.navigate(['']);
                  }
                } else {
                  let responseFromAladdin = window.requestToRecheckServerForMissingPackage(product.internalName);
                  this.router.navigate(['']);
                }
              }
            });
          }
        });
      }
    });
  }

  getXmlConfigAsString(product: Product): Observable<string> {
    return new Observable((observer) => {
      this.http.get(Helper.baseProductsDirectory + product.getCurrentLanguageConfigUrl(this.translate), { responseType: 'text' })
      .subscribe((content: string) => {
        observer.next(content);
        observer.complete();
      },
      (error) => {
        // Not found the localizated one, fallback to default
        this.http.get(Helper.baseProductsDirectory + product.configUrl, { responseType: 'text' })
        .subscribe((content: string) => {
          observer.next(content);
          observer.complete();
        });
      });
    });
  }

  setXmlConfig(name: string, content: string): Observable<ProductData> {
    return new Observable((observer) => {
      const xmlObj = this.parseXmlToProductData(name, content, "");
      observer.next(xmlObj);
      observer.complete();
      this.genericDeviceContent = content;
    });
  }

  getHelpXml(mapName: string, basePath: string): Observable<HelpItem[]> {
    return this.http.get(basePath + mapName, { responseType: 'text' })
    .pipe(map(res => {
      let xmlContent = res.replace(/[\n\r]/g, '');
      xmlContent = xmlContent.replace(/\t/g, '');
      xmlContent = xmlContent.replace(/  /g, '');
      const xmlObj = this.parseXml(xmlContent);
      const helpItems = HelpItem.fromXml(xmlObj, basePath);
      return helpItems;
    }));
  }

  getConfigValuesFromXmlContent(content: string): any[] {
    const parsedXml = this.parseXml(content);

    if (!parsedXml.values) {
      return [];
    }

    if (Helper.isArray(parsedXml.values.value)) {
      return parsedXml.values.value;
    } else {
      return [parsedXml.values.value];
    }
  }

  saveProductEditedData(productData: ProductData): void {
    if (productData) {
      const alreadyPresentIndex = this.editedProducts.findIndex(it => it.name === productData.name &&
                                                                it.connection === productData.connection);
      if (alreadyPresentIndex >= 0) {
        this.editedProducts[alreadyPresentIndex] = productData;
      } else {
        this.editedProducts.push(productData);
      }
    }
  }

  deleteProductEditedData(name: string): void {
    const alreadyPresentIndex = this.editedProducts.findIndex(it => it.name === name);
    if (alreadyPresentIndex >= 0) {
      this.editedProducts.splice(alreadyPresentIndex, 1);
    }
  }

  private parseXmlToProductData(name: string, xml: string, connection: string): ProductData {
    const nameWithoutRelease = name.substring(0, name.lastIndexOf('_'));
    // Convert xml text to a JSON object
    const parsedXml = this.parseXml(xml);
    const properties = new Map<string, string>();
    for (const property in parsedXml) {
      if (property.startsWith('@')) {
        properties.set(property.substring(1), parsedXml[property]);
      }
    }
    // Parse messages
    const messages: Message[] = [];
    if (Helper.isArray(parsedXml.messages.message)) {
      for (const message of parsedXml.messages.message) {
        messages.push(Message.fromXml(message, this.languageService));
      }
    } else {
      messages.push(Message.fromXml(parsedXml.messages.message, this.languageService));
    }
    // Parse pages to menu items to show
    const paramMenu: ParamMenuItem[] = this.parseJsonObjectToParamMenu(parsedXml.rootPage.page.page, nameWithoutRelease, true);
    paramMenu.unshift(
      new ParamMenuItem(parsedXml.rootPage.page['@name'], 
                        this.languageService.getProductString(parsedXml.rootPage.page['@title']),
                        parsedXml.rootPage.page['@title'],
                        parsedXml.rootPage.page['@tocId'], []));

    // build up list of interfaces so recommended values can be saved
    let interfaceClasses: Object = new Object();
    if (parsedXml.interfaceclasses != null) {
      if (parsedXml.interfaceclasses.interfaceclass != null) {
        for (const elem of parsedXml.interfaceclasses.interfaceclass) {
          interfaceClasses[elem['@name']] = elem.interface['#text'];
        }
      }  
    }

    // Parse parameters and rules
    const parameters: Parameter[] = this.parseJsonObjectToParamObjects(parsedXml, interfaceClasses);
    // Parse special interface rules
    if (parsedXml['@family'] == 'FRS') {
      this.parseJsonObjectToInterfaceRules(parsedXml, parameters);
    }

    // Create the config pages to show
    const configurationPages: ConfigurationPage[] = [];
    this.parseJsonObjectToConfigPages(parsedXml.rootPage.page, configurationPages, parameters);
    // Parse device commands
    const preCommands: Command[] = [];
    if (Helper.isArray(parsedXml.printConf.pre.value)) {
      for (const preCommand of parsedXml.printConf.pre.value) {
        preCommands.push(Command.fromXml(preCommand, this.translate));
      }
    } else {
      preCommands.push(Command.fromXml(parsedXml.printConf.pre.value, this.translate));
    }
    const postCommands: Command[] = [];
    if (Helper.isArray(parsedXml.printConf.post.value)) {
      for (const postCommand of parsedXml.printConf.post.value) {
        postCommands.push(Command.fromXml(postCommand, this.translate));
      }
    } else {
      postCommands.push(Command.fromXml(parsedXml.printConf.post.value, this.translate));
    }

    const data = new ProductData(name, properties, paramMenu, configurationPages, parameters,
                                 preCommands, postCommands, messages, parsedXml, interfaceClasses, connection);

    this.saveProductEditedData(data);

    return data;
  }

  private parseJsonObjectToParamObjects(xml: any, interfaceClasses: Object): Parameter[] {
    let parameters: Parameter[] = [];
    const tables = xml.tableList.table;
    const exeNumericRanges = xml.tableList.exeNumericRange;
    const integerRangeTable = xml.tableList.integerRangeTable;
    let packParameters = [];
    if (xml.parameters.packParameter) {
      packParameters = xml.parameters.packParameter.parameterRef;
      if (packParameters === undefined) {
        if (xml.parameters.packParameter instanceof Array && xml.parameters.packParameter.length >= 1) {
          packParameters = xml.parameters.packParameter[0].parameterRef
        }
      }
    }

    let rules: ParameterRule[] = [];

    if (xml.parameters.rule){
      if (Helper.isArray(xml.parameters.rule)) {
        for (const rule of xml.parameters.rule) {
          rules.push(ParameterRule.fromRule(rule));
        }
      } else {
        rules.push(ParameterRule.fromRule(xml.parameters.rule));
      }
    }

    if (xml.parameters.ruleSet){
      if (Helper.isArray(xml.parameters.ruleSet)) {
        for (const rule of xml.parameters.ruleSet) {
          rules = rules.concat(ParameterRule.fromRuleSet(rule));
        }
      } else {
        rules = rules.concat(ParameterRule.fromRuleSet(xml.parameters.ruleSet));
      }
    }

    for (const paramXml of xml.parameters.parameter) {
      const parameter = this.parseParameter(paramXml, rules, tables, exeNumericRanges, integerRangeTable, packParameters, [], interfaceClasses, false);
      if (parameter) {
        parameters = parameters.concat(parameter);
      }
    }

    if (xml.parameters.multiParameter)
    {
      if (Helper.isArray(xml.parameters.multiParameter)) 
      {
        for (const paramXml of xml.parameters.multiParameter) {
          let children: Parameter[] = [];

          if (paramXml.singleParameter && Helper.isArray(paramXml.singleParameter)) 
          {
            for (const parameterXml of paramXml.singleParameter) 
            {
              const par = this.parseParameter(parameterXml, rules, tables, exeNumericRanges, integerRangeTable, packParameters, [], interfaceClasses, true);
              if (par) {
                parameters = parameters.concat(par);
                children = children.concat(par);
              }
            }
          }
          const parameter = this.parseParameter(paramXml, rules, tables, exeNumericRanges, integerRangeTable, packParameters, children, interfaceClasses, false);
          if (parameter) {
            parameters = parameters.concat(parameter);
          }
        }
      } 
      else 
      {
        let children: Parameter[] = [];

        if (xml.parameters.multiParameter.singleParameter && Helper.isArray(xml.parameters.multiParameter.singleParameter)) {
          for (const parameterXml of xml.parameters.multiParameter.singleParameter) {
            const par = this.parseParameter(parameterXml, rules, tables, exeNumericRanges, integerRangeTable, packParameters, [], interfaceClasses, true);
            if (par) {
              parameters = parameters.concat(par);
              children = children.concat(par);
            }
          }
        }

        const parameter = this.parseParameter(xml.parameters.multiParameter, rules, tables, exeNumericRanges, integerRangeTable, packParameters, children, interfaceClasses, false);
        if (parameter) {
          parameters = parameters.concat(parameter);
        }
      }

    }

    return parameters;
  }

  private parseJsonObjectToConfigPages(page: any, configurationPages: ConfigurationPage[], parameters: Parameter[]): void {
    configurationPages.push(ConfigurationPage.fromXmlPage(page, parameters, this.translate, this.languageService));

    // If the page has children parse them
    if (page.page) {
      let array = page.page || [];
      if (array.length === undefined) {
        array = [array];
      }

      for (const childPage of array) {
        this.parseJsonObjectToConfigPages(childPage, configurationPages, parameters);
      }
    }
  }

  private parseJsonObjectToInterfaceRules(xml, parameters: Parameter[]): void {
    if ((xml['@family'] == 'FRS') && (xml.interfaceclasses != null)) {
      let interfaceParam = parameters.find(param => param.name == "CI_INTERFACE_TYPE");
      const senderTagName = interfaceParam.name;
      // build up array of multiparameters
      let multiParameters = xml.parameters.multiParameter;
      let singleParams = [];
      if (multiParameters) {
        for (const param of multiParameters) {
          if (param.singleParameter) {
            singleParams = [...singleParams, ...param.singleParameter];
          }
        }
      }
      for (const interfaceClass of xml.interfaceclasses.interfaceclass) {
        let interfaceValue: string = interfaceClass.interface['#text'];
        let interfaceName: string = interfaceClass['@name'];
        // find parameters that should be disabled for this interface
        let interfaceDisabledParams = xml.parameters.parameter.filter(param => param.hasOwnProperty(interfaceName) && param[interfaceName] == null);
        // find children of multiparameters that should be disabled
        let interfaceDisabledSingleParams = singleParams.filter(param => param.hasOwnProperty(interfaceName) && param[interfaceName] == null);
        interfaceDisabledParams = interfaceDisabledParams.concat(interfaceDisabledSingleParams);
        // build up list of target parameters
        let targets: string[] = [];
        let targetParameters: Parameter[] = [];
        for (const param of interfaceDisabledParams) {
          // find target parameter
          let parsedParam = parameters.find(p => p.name == param['@name']);
          if (parsedParam !== undefined) {
            targets.push(param['@name']);
            targetParameters.push(parsedParam);
          }
        }
        // create rule
        let rule = new ParameterRule([senderTagName], targets, "Disable", "equalTo", [new ParameterRuleAcceptedValue(senderTagName, interfaceValue)], '');
        // push rule to sender and target parameters
        interfaceParam.rulesAsSender.push(rule);
        for (const targetParam of targetParameters) {
          targetParam.rulesAsTarget.push(rule);
        }
      }
    }
  }

  private parseJsonObjectToParamMenu(pages: any, productName: string, isFirstLevel: boolean): ParamMenuItem[] {
    let paramMenu: ParamMenuItem[] = [];

    if (pages.length === undefined) {
      // Single page --> Transformed into array and called function
      const page = pages.page || [];
      let array = page;
      if (array.length === undefined) {
        array = [page];
      }

      return this.parseJsonObjectToParamMenu(array, productName, isFirstLevel);
    } else if (pages.length > 0) {
      for (const page of pages) {
        // Check protection level
        if (!Helper.developerModeEnabled && page['@protection'] === 'DEVELOPER') {
          continue;
        }

        if (!Helper.secureModeEnabled && page['@protection'] === 'SECURE') {
          continue;
        }

        // If i have a single child i transform it into an array
        const children = page.page || [];
        let array = children;
        if (array.length === undefined) {
          array = [children];
        }

        // If product is BASE type, group the parameters for each scanner type
        if (productName.indexOf("-BASE-") >= 0){
          // If root page and name same as the product skip and lift children
          if (isFirstLevel && page['@title'] && page['@title'].indexOf(productName) >= 0) {
            paramMenu = paramMenu.concat(this.parseJsonObjectToParamMenu(array, productName, isFirstLevel));
          } else {
            paramMenu.push(new ParamMenuItem(page['@name'], this.languageService.getProductString(page['@title']), page['@title'], page['@tocId'], this.parseJsonObjectToParamMenu(array, productName, false)));
          }  
        } else {
          // If root page and name same as the product skip and lift children
          if (isFirstLevel && page['@title'] && 
          (page['@title'].indexOf(productName) >= 0 || 
          page['@title'].indexOf("2D Imager Scanner") >= 0 ||
          page['@title'].indexOf("Linear Imager Scanner") >= 0) ) {
            paramMenu = paramMenu.concat(this.parseJsonObjectToParamMenu(array, productName, isFirstLevel));
          } else {
            paramMenu.push(new ParamMenuItem(page['@name'], this.languageService.getProductString(page['@title']), page['@title'], page['@tocId'], this.parseJsonObjectToParamMenu(array, productName, false)));
          }
        }
      }
    }

    return paramMenu;
  }

  private parseParameter(xml: any, rules: ParameterRule[], tables: any, exeNumericRanges: any, integerRangeTable: any,
                         packParameters: any, children: Parameter[], interfaceClasses: Object, singleParameterXml: boolean): Parameter[] {
    if (!Helper.developerModeEnabled && xml['@protection'] === 'DEVELOPER') {
      return undefined;
    }

    if (!Helper.secureModeEnabled && xml['@protection'] === 'SECURE') {
      return undefined;
    }

    if (xml['@name'].startsWith('AdvancedFormatting') && xml['@type'] === 'adv') {
      return this.parseAdvancedFormatting(xml, rules, tables, packParameters);
    }

    // If it has rules inside (normally used for some multiparameter) parse and add
    if (xml.rule) {
      if (Helper.isArray(xml.rule)) {
        for (const rule of xml.rule) {
          rules = rules.concat(ParameterRule.fromRule(rule));
        }
      } else {
        rules = rules.concat(ParameterRule.fromRule(xml.rule));
      }
    }

    // Parameter value table if it exists
    let currentParameterTable: any = [];
    if (xml['@tableRef'])
    {
      if(xml['@tableRef'].startsWith('IntegerRange0000065535'))
      {
        xml['@tableRef'] = 'exeNumericRangeOnlyNumbersIntegerRange0000065535';
        xml['@fillChar'] = '0';

        xml['@minLen'] = '5';
        xml['@maxLen'] = '5';

        xml['@type'] = 'stringFilled';

        for (const char of ["0","1","2","3","4","5","6","7","8","9"]) {
          currentParameterTable.push({ text: char, value: char});
        }

      }
      else if ((xml['@type'] === 'stringFilled' || xml['@type'] === 'readableAscii' || xml['@type'] === 'char') && xml['@tableRef'].startsWith('exeNumericRange'))
      {
        const currentNumericRange = exeNumericRanges.find(it => it['@name'] === xml['@tableRef']);
        const rangeMin: number = Helper.hexToInt(currentNumericRange['@min']);
        const rangeMax: number = Helper.hexToInt(currentNumericRange['@max']);
        const charList = Helper.hexCharList.slice(rangeMin, rangeMax + 1);

        let i = rangeMin;
        for (const char of charList) {
          currentParameterTable.push({ text: char, value: Helper.intToHex(i).padStart(2, '0')});

          i++;
        }
      }
      else if ((xml['@type'] === 'int') && xml['@tableRef'].startsWith('IntegerRange'))
      {
        const currenIntegerRangeTable = integerRangeTable.find(it => it['@name'] === xml['@tableRef']);
        xml['@min'] = currenIntegerRangeTable['@min'];
        xml['@max'] = currenIntegerRangeTable['@max'];
      }
      else
      {
        // If it has tableref i get the values
        const table = tables.find(it => it['@name'] === xml['@tableRef']);
        if (table) {
          if((table.element instanceof Array) == false){
            table.element = [table.element]
          }
          currentParameterTable = table.element;
        }
      }
    }

    // Parameter rules if any
    const parameterRulesAsSender = rules.filter(it =>
      it.sender.find(s => Helper.isParameterAffectedFromRule(xml['@name'], s)) !== undefined);
    const parameterRulesAsTarget = rules.filter(it =>
      it.targets.find(s => Helper.isParameterAffectedFromRule(xml['@name'], s)) !== undefined);

    // Is pack parameter
    const isPackParameter = packParameters.find(it => it['@name'] === xml['@name']) !== undefined;

    return [Parameter.fromXmlParameter(xml, parameterRulesAsSender, parameterRulesAsTarget, currentParameterTable,
                                       isPackParameter, false, children, this.languageService, interfaceClasses, singleParameterXml)];
  }

  private parseAdvancedFormatting(xml: any, rules: ParameterRule[], tables: any, packParameters: any): Parameter[] {
    const result = [];
    const params = xml['@value'].split('$').filter(it => it !== '').map(it => it.substring(1));
    const isPackParameter = packParameters.find(it => it['@name'] === xml['@name']) !== undefined;

    // create all Advanced Formatting rules
    for (const paramValue of params) {
      const paramCode = paramValue.substring(0, 4);
      const paramRules = this.helperService.getAdvParamRules(xml['@name'], paramCode);
      rules = rules.concat(paramRules);
    }

    for (const paramValue of params) {
      const paramXml = {};
      const paramCode = paramValue.substring(0, 4);
      const paramLabel = this.helperService.translateAdvParamToText(paramCode);
      if (paramLabel) {
        const hasResetButton = paramCode.substring(2).toLowerCase() === 'ml' || paramCode.substring(2).toLowerCase() === 'mp';
        const paramType = this.helperService.translateAdvParamToType(paramCode);
        paramXml['@value'] = paramValue.substring(4);
        paramXml['@name'] = xml['@name'] + '.' + paramCode;
        paramXml['@type'] = paramType;
        paramXml['@protection'] = paramXml['@xml'];
        paramXml['@code'] = paramCode;
        paramXml['@min'] = paramType === 'hexInt' ? '0000' : undefined;
        paramXml['@max'] = paramType === 'hexInt' ? 'FFFF' : undefined;
        paramXml['@minLen'] = paramType === 'stringFilled' ? '00' : undefined;
        paramXml['@maxLen'] = paramType === 'stringFilled' ? (Math.floor(paramXml['@value'].length / 2)).toString() : undefined;
        paramXml['@fillChar'] = paramType === 'stringFilled' ? '00' : undefined;
        paramXml['@context'] = paramLabel;
        paramXml['@sizeLen'] = paramType === 'hexInt' ? '4' : undefined;
        paramXml['@sendToDevice'] = xml['@sendToDevice'];
        paramXml['@readOnly'] = xml['@readOnly'];

        if (paramType === 'stringFilled') {
          const value = paramValue.substring(4);
          // If all zeroes
          if (/^0+$/.test(value)) {
            paramXml['@value'] = Array(Helper.parseIntWithRadix(paramXml['@maxLen'])).fill(paramXml['@fillChar']).join('');
          }
        }

        if (paramCode.substring(2).toLowerCase() === 'to') {
          paramXml['@maxLen'] = '7';
        }

        const parameterRulesAsSender = rules.filter(it =>
          it.sender.find(s => Helper.isParameterAffectedFromRule(paramXml['@name'], s)) !== undefined);
        const parameterRulesAsTarget = rules.filter(it =>
           it.targets.find(s => Helper.isParameterAffectedFromRule(paramXml['@name'], s)) !== undefined);

        const currentParameterTable = this.helperService.getAdvParamOptions(paramCode, tables);

        result.push(Parameter.fromXmlParameter(paramXml, parameterRulesAsSender, parameterRulesAsTarget, currentParameterTable,
                                               isPackParameter, hasResetButton, [], this.languageService, [], false));
      }
    }

    return result;
  }

  private parseXml(xml) {
    let dom: Document = null;
    if (window.DOMParser) {
       try {
          dom = (new DOMParser()).parseFromString(xml, 'text/xml');
       }
       catch (e) { dom = null; }
    }

    // Getting the device node from the XML
    const deviceElement = Array.from(dom.childNodes).filter(it => it.nodeName === 'device');

    // If there is a device element parse and return
    let parsedXml: any;
    if (deviceElement.length > 0) {
      parsedXml = this.toObj(this.removeWhite(deviceElement[0]), 0);
    } else {
      // Try getting the map node for help_map
      const mapElement = Array.from(dom.childNodes).filter(it => (it.nodeName === 'map') && (it.nodeType === Node.ELEMENT_NODE));
      if (mapElement.length > 0) {
        parsedXml = this.toObj(this.removeWhite(mapElement[0]), 0);
      } else {
        parsedXml = this.toObj(this.removeWhite(dom.childNodes[1]), 0);
      }
    }

    return parsedXml;
  }

  private toObj(xml, index): any {
    let o: any = {};
    o.order = index;
    if (xml.nodeType === 1) {   // element node ..
      if (xml.attributes.length) {   // element with attributes  ..
        for (const attribute of xml.attributes) {
            o['@' + attribute.nodeName] = this.escape((attribute.nodeValue || '').toString());
        }
      }
      if (xml.firstChild) { // element has child nodes ..
        let textChild = 0;
        let cdataChild = 0;
        let hasElementChild = false;
        let childIndex = 0;
        for (let n = xml.firstChild; n; n = n.nextSibling) {
          if (n.nodeType === 1) { hasElementChild = true; }
          else if (n.nodeType === 3 && n.nodeValue.match(/[^ \f\n\r\t\v]/)) { textChild++; } // non-whitespace text
          else if (n.nodeType === 4) { cdataChild++; } // cdata section node
        }
        if (hasElementChild) {
          if (textChild < 2 && cdataChild < 2) { // structured element with evtl. a single text or/and cdata node ..
            this.removeWhite(xml);
            for (let n = xml.firstChild; n; n = n.nextSibling) {
              if (n.nodeType === 3) {  // text node
                o['#text'] = this.escape(n.nodeValue);
              }
              else if (n.nodeType === 4) {  // cdata node
                o['#cdata'] = this.escape(n.nodeValue);
              }
              else if (o[n.nodeName]) {  // multiple occurence of element ..
                if (o[n.nodeName] instanceof Array) {
                    o[n.nodeName][o[n.nodeName].length] = this.toObj(n, childIndex);
                }
                else {
                    o[n.nodeName] = [o[n.nodeName], this.toObj(n, childIndex)];
                }
              }
              else {  // first occurence of element..
                o[n.nodeName] = this.toObj(n, childIndex);
              }

              childIndex++;
           }
          }
          else { // mixed content
            if (!xml.attributes.length) {
                o = this.escape(this.innerXml(xml));
            }
            else {
                o['#text'] = this.escape(this.innerXml(xml));
            }
          }
        }
        else if (textChild) { // pure text
          if (!xml.attributes.length) {
            o = this.escape(this.innerXml(xml));
          }
          else {
            o['#text'] = this.escape(this.innerXml(xml));
          }
        }
        else if (cdataChild) { // cdata
          if (cdataChild > 1) {
            o = this.escape(this.innerXml(xml));
          }
          else {
            for (let n = xml.firstChild; n; n = n.nextSibling) {
              o['#cdata'] = this.escape(n.nodeValue);
            }
          }
        }
      }
      if (!xml.attributes.length && !xml.firstChild) { o = null; }
    }
    else if (xml.nodeType === 9) { // document.node
       o = this.toObj(xml.documentElement, 0);
    }
    // else
    //   alert("unhandled node type: " + xml.nodeType);
    return o;
  }

  private innerXml(node) {
    let s = '';
    if ('innerHTML' in node) {
      s = node.innerHTML;
    }
    else {
      const asXml = (n) => {
        let nodeContent = '';
        if (n.nodeType === 1) {
          nodeContent += '<' + n.nodeName;
          for (const attribute of n.attributes) {
            nodeContent += ' ' + attribute.nodeName + '="' + (attribute.nodeValue || '').toString() + '"';
          }
          if (n.firstChild) {
            nodeContent += '>';
            for (let c = n.firstChild; c; c = c.nextSibling) {
              nodeContent += asXml(c);
            }
            nodeContent += '</' + n.nodeName + '>';
          }
          else {
            nodeContent += '/>';
          }
        }
        else if (n.nodeType === 3) {
          nodeContent += n.nodeValue;
        }
        else if (n.nodeType === 4) {
          nodeContent += '<![CDATA[' + n.nodeValue + ']]>';
        }
        return nodeContent;
      };
      for (let c = node.firstChild; c; c = c.nextSibling) {
          s += asXml(c);
      }
    }
    return s;
  }

  private escape(txt) {
    return txt.replace(/[\n]/g, '\\n')
              .replace(/[\r]/g, '\\r');
  }

  private removeWhite(e) {
    e.normalize();
    for (let n = e.firstChild; n; ) {
      if (n.nodeType === 3) {  // text node
        if (n.nodeValue.replace(/\uFEFF/g, '').length === 0) { // empty text node
          const nxt = n.nextSibling;
          e.removeChild(n);
          n = nxt;
        }
        else if (!n.nodeValue.match(/[^ \f\n\r\t\v]/)) { // pure whitespace text node
          const nxt = n.nextSibling;
          e.removeChild(n);
          n = nxt;
        }
        else {
          n = n.nextSibling;
        }
       }
       else if (n.nodeType === 1) {  // element node
        this.removeWhite(n);
        n = n.nextSibling;
       }
       else {                      // any other node
        n = n.nextSibling;
      }
    }
    return e;
  }

  public getMCFNumber(productName: string): Observable<Object> {
    let releaseUrl = productName + '/config_' + productName + '.xml';
    return new Observable((observer) => {
      this.http.get(Helper.baseProductsDirectory + releaseUrl, { responseType: 'text' })
      .subscribe((content: string) => {
        // parse MCF from xml string
        let deviceIndex = content.indexOf("<device");
        let endDeviceIndex = content.indexOf(">", deviceIndex);
        let mcfIndex = content.indexOf("mcf=", deviceIndex);
        let mcf = undefined;
        // make sure mcf is in correct place, or else ignore
        if (mcfIndex < endDeviceIndex && mcfIndex > deviceIndex) {
          mcf = content.substring(mcfIndex+5,mcfIndex+10);
        }

        let retObj = { mcf: mcf, productName: productName };
        observer.next(retObj);
        observer.complete();
      });
    });
  }

}
