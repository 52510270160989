import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { Parameter, ParameterOption } from 'src/app/classes/parameters';
import { Helper } from 'src/app/classes/utility';
import { ProductComponent } from 'src/app/pages/product/product.component';
import { NotificationComponent } from '../notification/notification.component';
import { UleModeComponent } from '../ule-mode/ule-mode.component';
import { UleService } from 'src/app/services/ule/ule.service';
import { uleModeEnum } from 'src/app/classes/enums/ule.enum';
import { NotificationType, Notification } from 'src/app/classes/utility/notification';
import {CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray} from '@angular/cdk/drag-drop';
@Component({
  selector: 'app-gs1-element-string-editor',
  templateUrl: './gs1-element-string-editor.component.html',
  styleUrls: ['./gs1-element-string-editor.component.css'],
})
export class Gs1ElementStringEditorComponent implements OnInit {
  @Input() parameter: Parameter;
  @Input() readonly = false;
  @Input() commonFields: any;
  @Input() notification: NotificationComponent;
  @Input() uleModeComponent: UleModeComponent;
  @ViewChild('triggerBtn') triggerBtn: ElementRef;
  @Output() valueChanged: EventEmitter<string> = new EventEmitter<string>();

  separatorFields = Helper.hexCharList;

  selectedInputOptions: ParameterOption[] = [];
  activeInputOptions: number[] = [];
  selectedOutputOptions: ParameterOption[] = [];
  activeOutputOptions: number[] = [];
  selectedOptionsText = '';
  addLabelEnable: boolean = false;
  commonFieldsAfterSearch: any;

  constructor(private uleService: UleService) {}

  ngOnInit(): void {
    this.commonFieldsAfterSearch = this.commonFields;
    this.refreshValue();
    this.checkAddLableInputBox();
    this.saveChanges();
  }

  refreshValue(): void {
    const valueArray = this.parameter.value.match(/.{6}/g);

    for (const value of valueArray) {
      const prefix = value.substring(0, 2);
      const arrayValue = value.substring(2);
      if (prefix == '01') {
        this.selectOption(Helper.hexToInt(arrayValue), '01');
      } else if (prefix == '02' || prefix == '03') {
        const optionValue = this.commonFields.find(
          (it) => it['#text'].toUpperCase() === arrayValue
        );
        if (optionValue) {
          this.selectOption(optionValue, prefix);
        }
      }
    }

    ProductComponent.isActiveHover = false;
  }

  selectOptionToInputAndOutput(option: any): void {
    this.selectOption(option, '02');
    this.selectOption(option, '03');
  }

  selectOption(option: any, prefix: string): void {
    if (
      this.selectedInputOptions.length <
        Helper.parseIntWithRadix(this.parameter.maxLen) &&
      this.selectedOutputOptions.length <
        Helper.parseIntWithRadix(this.parameter.maxLen)
    ) {
      let opt: ParameterOption;
      //if (prefix === '02' && !this.checkInputExistedOption(option['@name'])) {
      if (prefix === '02') {
        opt = new ParameterOption(
          option['@name'],
          prefix + option['#text'],
          ''
        );
        this.selectedInputOptions.push(opt);
        //} else if (prefix === '03' && !this.checkOutputExistedOption(option['@name'])) {
      } else if (prefix === '03') {
        opt = new ParameterOption(
          option['@name'],
          prefix + option['#text'],
          ''
        );
        this.selectedOutputOptions.push(opt);
      } else if (prefix === '01') {
        opt = new ParameterOption(
          this.separatorFields[option],
          prefix + Helper.intToHex(option).toUpperCase().padStart(4, '0'),
          ''
        );
        this.selectedOutputOptions.push(opt);
      }
    }
  }

  addLabelEnableToggle(event: any): void {
    this.addLabelEnable = event.target.checked;
  }

  searchListGS1Element(event: any): void {
    let value = event.target.value;
    if (value.length >= 1) {
      this.commonFieldsAfterSearch = this.commonFields.filter(
        (it) => it['@name'].toLowerCase().indexOf(value.toLowerCase()) >= 0
      );
      //console.log(this.commonFieldsAfterSearch);
    } else {
      this.commonFieldsAfterSearch = this.commonFields;
    }
  }

  addOutputOptions(): void {
    if (!(this.activeInputOptions.length > 0)) {
      return;
    }

    this.activeInputOptions.sort((a, b) => a - b);

    for (let i = 0; i < this.activeInputOptions.length; i++) {
      const optionIndex = this.activeInputOptions[i];

      if (
        this.selectedOutputOptions.length <
        Helper.parseIntWithRadix(this.parameter.maxLen)
      ) {
        const selectedInputOptionAddingToOutput =
          this.selectedInputOptions[optionIndex];
        //if (!this.checkOutputExistedOption(selectedInputOptionAddingToOutput.name)) {
        this.selectedOutputOptions.push(selectedInputOptionAddingToOutput);
        //}
      }
    }
  }

  toggleInputActiveOption(index: number): void {
    const itemIndex = this.activeInputOptions.indexOf(index);
    if (itemIndex === -1) {
      this.activeInputOptions.push(index);
    } else {
      this.activeInputOptions.splice(itemIndex, 1);
    }
  }

  toggleOutputActiveOption(index: number): void {
    const itemIndex = this.activeOutputOptions.indexOf(index);
    if (itemIndex === -1) {
      this.activeOutputOptions.push(index);
    } else {
      this.activeOutputOptions.splice(itemIndex, 1);
    }
  }

  moveUpInputOption(): void {
    if (this.activeInputOptions.indexOf(0) >= 0) {
      return;
    }

    this.activeInputOptions.sort((a, b) => a - b);

    for (let i = 0; i < this.activeInputOptions.length; i++) {
      const optionIndex = this.activeInputOptions[i];
      if (optionIndex > 0) {
        Helper.arrayMove(
          this.selectedInputOptions,
          optionIndex,
          optionIndex - 1
        );
        this.activeInputOptions[i] = this.activeInputOptions[i] - 1;
      }
    }
  }

  moveUpOutputOption(): void {
    if (this.activeOutputOptions.indexOf(0) >= 0) {
      return;
    }

    this.activeOutputOptions.sort((a, b) => a - b);

    for (let i = 0; i < this.activeOutputOptions.length; i++) {
      const optionIndex = this.activeOutputOptions[i];
      if (optionIndex > 0) {
        Helper.arrayMove(
          this.selectedOutputOptions,
          optionIndex,
          optionIndex - 1
        );
        this.activeOutputOptions[i] = this.activeOutputOptions[i] - 1;
      }
    }
  }

  moveDownInputOption(): void {
    if (this.activeInputOptions.indexOf(this.selectedInputOptions.length - 1) >= 0) {
      return;
    }

    this.activeInputOptions.sort((a, b) => b - a);

    for (let i = 0; i < this.activeInputOptions.length; i++) {
      const optionIndex = this.activeInputOptions[i];
      if (optionIndex < this.selectedInputOptions.length - 1) {
        Helper.arrayMove(
          this.selectedInputOptions,
          optionIndex,
          optionIndex + 1
        );
        this.activeInputOptions[i] = this.activeInputOptions[i] + 1;
      }
    }
  }

  moveDownOutputOption(): void {
    if (this.activeOutputOptions.indexOf(this.selectedOutputOptions.length - 1) >= 0) {
      return;
    }

    this.activeOutputOptions.sort((a, b) => b - a);

    for (let i = 0; i < this.activeOutputOptions.length; i++) {
      const optionIndex = this.activeOutputOptions[i];
      if (optionIndex < this.selectedOutputOptions.length - 1) {
        Helper.arrayMove(
          this.selectedOutputOptions,
          optionIndex,
          optionIndex + 1
        );
        this.activeOutputOptions[i] = this.activeOutputOptions[i] + 1;
      }
    }
  }

  deleteInputActiveOptions(): void {
    this.selectedInputOptions = this.selectedInputOptions.filter(
      (it, i) => this.activeInputOptions.indexOf(i) < 0
    );
    this.activeInputOptions = [];
  }

  deleteOutputActiveOptions(): void {
    this.selectedOutputOptions = this.selectedOutputOptions.filter(
      (it, i) => this.activeOutputOptions.indexOf(i) < 0
    );
    this.activeOutputOptions = [];
  }

  resetToDefault(): void {
    this.parameter.value = this.parameter.defaultValue;
  }

  saveChanges(swithUleMode: boolean = false): void {
    if (
      swithUleMode &&
      this.uleService.currentUle.mode === uleModeEnum.CUSTOM &&
      this.parameter.isPackParameter
    ) {
      this.uleModeComponent.setMode(uleModeEnum.ADV_FORMAT);
    } 

    this.selectedOptionsText = '';
    for (let i = 0; i < this.selectedOutputOptions.length; i++) {
      if (i !== this.selectedOutputOptions.length - 1) {
        this.selectedOptionsText += this.selectedOutputOptions[i].name + ' ';
      } else {
        this.selectedOptionsText += this.selectedOutputOptions[i].name;
      }
    }

    if (
      this.selectedInputOptions.length > 0 ||
      this.selectedOutputOptions.length > 0
    ) {
      this.parameter.value = this.selectedInputOptions
        .map((it) => it.value.toUpperCase())
        .join('');
      if (this.addLabelEnable) {
        this.parameter.value = this.parameter.value + '040001';
      } else {
        this.parameter.value = this.parameter.value + '040000';
      }
      this.parameter.value =
        this.parameter.value +
        this.selectedOutputOptions.map((it) => it.value.toUpperCase()).join('');
    } else {
      this.parameter.value = this.parameter.defaultValue;
    }

    this.valueChanged.emit(this.parameter.name);

    this.close();
    ProductComponent.isActiveHover = true;
  }

  close(): void {
    this.selectedInputOptions = [];
    this.selectedOutputOptions = [];
    this.activeInputOptions = [];
    this.activeOutputOptions = [];
    ProductComponent.isActiveHover = true;
  }

  checkAddLableInputBox() {
    if (this.parameter.value.includes('040001')) {
      this.addLabelEnable = true;
    }
  }

  onClick(): void {
    this.refreshValue();
  }

  trigger(): void {
    this.triggerBtn.nativeElement.click();
  }
  clearAll(): void{
    this.selectedInputOptions = [];
    this.selectedOutputOptions = [];
  }
  drop(event: CdkDragDrop<string[]>) {
    if (event.container.id === 'input-list') {
      moveItemInArray(this.selectedInputOptions, event.previousIndex, event.currentIndex);
    } else if (event.container.id === 'output-list') {
      moveItemInArray(this.selectedOutputOptions, event.previousIndex, event.currentIndex);
    }
  }
  onDragStart(listType: string) {
    if (listType === 'input') {
      this.activeInputOptions = []
    } else if (listType === 'output') {
      this.activeOutputOptions = [];
    }
  }
  /*checkInputExistedOption(name: string) {
    let result: boolean = false;
    for (let i = 0; i < this.selectedInputOptions.length; i++) {
      if (name === this.selectedInputOptions[i].name) {
        result = true;
        break;
      }
    }

    return result;
  }*/

  /*checkOutputExistedOption(name: string) {
    let result: boolean = false;
    for (let i = 0; i < this.selectedOutputOptions.length; i++) {
      if (name === this.selectedOutputOptions[i].name) {
        result = true;
        break;
      }
    }

    return result;
  }*/
}
