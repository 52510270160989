<div id="wrapper">
<main role="main" class="container-fluid bg-light">
  <div class="row">
    <div class="col no-padding sidebar" [ngClass]="{'visible': standaloneMode || isRemoted || azureMode}">
      <app-sidebar [standaloneMode]=standaloneMode [isRemoted]="isRemoted" #sidebar></app-sidebar>
    </div>
    <div class="col-md">
      <section id="cat">
        <div class="row">
          <div class="col align-middle">
            <h1 class="mb-5 d-inline float-left">{{'HOME.OFFLINE-CONFIGURATION' | translate}}</h1>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a
                  class="nav-link bg-warning active"
                  id="home-tab"
                  data-toggle="tab"
                  routerLink="/configuration/home"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true">
                  {{currentCategory.name}}</a>
              </li>
            </ul>
            <div class="tab-content" id="categoryContent">
              <div class="tab-pane fade p-4 show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div class="row">
                  <div class="col">
                    <div class="card-deck">
                      <app-img-link class="app-img-link"  *ngFor="let item of currentCategory.children; let i = index"
                      [title]="item?.name"
                      [image]="isUserConfig ? item.image : 'assets/images/home/' + item.children[0].name + '.png'"
                      (click)="selectSubCategory(i)"
                      [isActive]="selectedSubCategoryIndex === i"
                      [isUserConfig]="isUserConfig"
                      (removeEvent)="removeUserConfiguration()"
                      ></app-img-link>
                      <div id="listCategoryCard"> 
                      </div>
                      <div class="add-card" *ngIf="this.isUserConfig">
                        <button class="icon-button"  id="addCustomConfigurationBtn">
                          <div class="icon-button-add"></div>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div id="prod-list" class="col-xl-3" *ngIf="selectedSubCategoryIndex !== undefined">
                    <div class="card rounded-0 border-0 h-100">
                      <div class="card-body">
                        <h5 class="card-title">{{subCategories[selectedSubCategoryIndex].name}}</h5>
                        <ul class="list-group list-group-flush card-list-group">
                            <li
                              class="list-group-item"
                              *ngFor="let item of subCategories[selectedSubCategoryIndex].children; let i = index">
                              <a [routerLink]="'/configuration/product/' + item.children[0]">
                                {{ item.name }}
                              </a>
                              <a *ngIf="isUserConfig" class="group-remove-item" (click)="removeUserConfiguration(i)"></a>
                            </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</main>
</div>
