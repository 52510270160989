<button type="button" id="openDownloadPageBtn" class="btn btn-primary" data-toggle="modal"
    data-target="#openDownloadPageModal">
    Open Download Modal
</button>

<div class="modal fade" id="openDownloadPageModal" tabindex="-1" role="dialog" aria-labelledby="downloadLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="downloadPageLabel">{{'HEADER.DOWNLOAD-STANDALONE' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="form-group">
                        <label for="downloadSys">{{'DOWNLOAD.CHOOSE_VERSION' | translate}}</label>
                        <div class="form-row">
                            <div class="col-sm-5 mb-2">
                                <select name="downloadSys" id="downloadSys" class="custom-select"
                                    [(ngModel)]="selectedSystem" (change)="onSystemChange()">
                                    <option *ngFor="let option of downloadOptions" [value]="option.name">
                                        {{option.name}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-sm-3 mb-2">
                                <select name="downloadVer" id="downloadVer" class="custom-select"
                                    [(ngModel)]="selectedVersion" (change)="onVersionChange()">
                                    <option *ngFor="let version of getVersions(selectedSystem)"
                                        [value]="version.number">
                                        {{version.number}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-sm-4 mb-2">
                                <button class="btn btn-primary btn-block" (click)="submitDownload()">Download</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>