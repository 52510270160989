<div class="satellite-pairing-container mt-3">
  <h2 class="text-center mb-3 responsive-heading">
    {{ "SATELLITE-PAIRING.SATELLITE_DETECT_AND_PAIR" | translate }}
  </h2>

  <div class="control-buttons row justify-content-center mb-3">
    <div class="col-12 col-sm-4 mb-2 mb-sm-0">
      <div class="btn-group w-100">
        <button type="button" [disabled]="isDisabledDetectButton()" class="btn btn-detect btn-responsive flex-grow-1"
          (click)="DetectSatelliteWithStatusToPair(false)">
          Detect Satellite
        </button>
        <button type="button" [disabled]="isDisabledDetectButton()"
          class="btn btn-detect btn-responsive dropdown-toggle dropdown-toggle-split" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <span class="sr-only">Toggle Dropdown</span>
        </button>
        <div class="dropdown-menu dropdown-menu-right w-100">
          <a class="dropdown-item" role="button" (click)="DetectSatelliteWithStatusToPair(true)">Detect and Check
            Status</a>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-4 mb-2 mb-sm-0 padding-left-0">
      <button class="btn btn-clear-all btn-block btn-responsive" (click)="RemoveAllPairedSatellite()">
        Clear All Paired Satellites
      </button>
    </div>
    <div class="col-12 col-sm-4 padding-left-0">
      <button class="btn btn-reset btn-block btn-responsive" (click)="CloseTheDeviceTab()">
        Reset Scanner
      </button>
    </div>
  </div>

  <div class="satellite-list table-responsive">
    <div class="table-wrapper min-height-20rem">
      <table class="table table-striped table-sm responsive-table sticky-table">
        <thead class="thead-blue">
          <tr>
            <th class="medium-column-responsive">
              Satellite MAC Address
            </th>
            <th class="small-column-responsive center-align">
              Type
              <div class="dropdown d-inline-block">
                <button class="btn" type="button" id="filterTypeDropdown"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="filter-icon"></i>
                </button>
                <div class="dropdown-menu" aria-labelledby="filterTypeDropdown">
                  <div class="px-3 py-2">
                    <div *ngFor="let type of typeOptions" class="form-check">
                      <input class="form-check-input" type="checkbox" [id]="'type' + type"
                        [(ngModel)]="typeFilters[type]" (change)="applyFilters()">
                      <label class="form-check-label" [for]="'type' + type">
                        {{ type }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </th>
            <th class="medium-column-responsive center-align">
              Satellite Status
              <div class="dropdown d-inline-block">
                <button class="btn" type="button" id="filterStatusDropdown"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="filter-icon"></i>
                </button>
                <div class="dropdown-menu" aria-labelledby="filterStatusDropdown">
                  <div class="px-3 py-2">
                    <div *ngFor="let status of statusOptions" class="form-check">
                      <input class="form-check-input" type="checkbox" [id]="'status' + status"
                        [(ngModel)]="statusFilters[status]" (change)="applyFilters()">
                      <label class="form-check-label" [for]="'status' + status">
                        {{ status }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </th>
            <th class="wide-column-responsive center-align">
              Paired with this scanner
              <div class="dropdown d-inline-block">
                <button class="btn" type="button" id="filterPairingDropdown"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="filter-icon"></i>
                </button>
                <div class="dropdown-menu" aria-labelledby="filterPairingDropdown">
                  <div class="px-3 py-2">
                    <div *ngFor="let option of pairingOptions | keyvalue" class="form-check">
                      <input class="form-check-input" type="checkbox" [id]="'pairing' + option.key"
                        [(ngModel)]="pairingFilters[option.key]" (change)="applyFilters()">
                      <label class="form-check-label" [for]="'pairing' + option.key">
                        {{ option.value }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let device of sortedSatelliteDevices">
            <td>{{ device.id }}</td>
            <td class="center-align">{{ device.type }}</td>
            <td class="center-align">
              {{ device.status }}
            </td>
            <td class="center-align">{{ device.pairing ? 'Yes' : 'No' }}</td>
            <td>
              <button *ngIf="device.status === 'Available' && device.pairing === false" class="btn btn-pair btn-responsive"
                (click)="pairDevice(device)">
                Pair
              </button>
              <button *ngIf="device.status === 'Paired' && device.pairing === true" class="btn btn-unpair btn-responsive"
                (click)="unpairDevice(device)">
                Unpair
              </button>
              <button *ngIf="
                device.status !== 'Available' && device.status !== 'Paired' && device.notDetected === undefined && !device.needsReset 
              " class="btn btn-check-status check-status-font-size btn-responsive" (click)="checkDeviceStatus(device)">
                Check Status
              </button>
              <span *ngIf="device.needsReset" class="reset-required">Reset required</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>