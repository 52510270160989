<!-- tabs -->
<div id="dd-container">
    <div class="dd_page dd_page-effect-scale dd_page-theme-1">
        <div class="dd_heading">
            <h2>DEVICE DETECTION</h2>
            <img src="assets/images/Datalogic_PDF_LOGO.png">
        </div>
        <input type="radio" name="dd_page" checked  id="tab1" class="tab-content-first">
        <label for="tab1">
            <img class="dd_img_icon" src="assets/images/detection/barcode.png">
            <span class="dd_tab_title">Barcode Scan</span>
        </label>
        
        <input type="radio" name="dd_page" id="tab2" class="tab-content-2">
        <label for="tab2">
            <img class="dd_img_icon" src="assets/images/detection/com.png">
            <span class="dd_tab_title">COM Port</span>
        </label>
        
        <input type="radio" name="dd_page" id="tab3" class="tab-content-3">
        <label for="tab3">
            <img class="dd_img_icon" src="assets/images/detection/ethernet.png">
            <span class="dd_tab_title">Ethernet Port</span>
        </label>
        
        <input type="radio" name="dd_page" id="tab5" class="tab-content-last">
        <label for="tab5" [hidden]="osName() == 'linux'">
            <img class="dd_img_icon" src="assets/images/detection/bluetooth.png">
            <span class="dd_tab_title">Bluetooth</span>
        </label>
        
        <ul>
            <li class="tab-content tab-content-first">
                <!-- Barcode Scan Tab -->
                <p class="intro" id="idIntro1">Scan the Barcode below then please <span class="dd_highlight">Click to Search</span> device.</p>
                <p class="intro" id="idIntro2">If you wish to configure a different device, please <span class="dd_highlight">Select Connection to Search Device</span> manually.</p>
                <div class="d-flex justify-content-center"><img class="barcode" src="assets/images/bar.png" /></div>
                <div class="form-group row d-flex align-items-center">
                    <div class="col-12 col-form-label text-center">
                        <button class="btn btn-outline-warning btn-lg" type="button" id="btnSearchOnServicePort">Click to Search</button>			  
                    </div>
                </div>
            </li>
            
            <li class="tab-content tab-content-2">
                <!-- COM Port Tab -->
                    <div class="form-group row d-flex align-items-center mb-1">
                        <div class="col-12">
                            <label id="lblCOMSelectionTitle">Select Connection to search device</label>
                        </div>
                    </div>
                    <div class="form-group row d-flex align-items-center dd_input_group">
                        <div class="col-4 col-form-label text-right">
                        <label id=lblCOMPort>Connection</label>
                        </div>
                        <div class="col-8">
                            <select class="custom-select" required="" id="selectCOMPort">
                                <option value="">COM1</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>			  
                        </div>
                    </div>
                    <div class="form-group row d-flex align-items-center dd_input_group">
                        <div class="col-4 col-form-label text-right">
                        <label id=lblBaudrate>baud rate</label>
                        </div>
                        <div class="col-8">
                            <select class="custom-select" required="" id="selectBaudrate">
                                <option value="9600">9600</option>
                                <option value="5000">5000</option>
                                <option value="1000">1000</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row d-flex align-items-center dd_input_group">
                        <div class="col-4 col-form-label text-right">
                            <label id="lblParity">parity</label>
                        </div>
                        <div class="col-8">
                            <select class="custom-select" required="" id="selectParity">
                                <option value="n">N</option>
                                <option value="s">S</option>
                                <option value="x">X</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row d-flex align-items-center dd_input_group">
                        <div class="col-4 col-form-label text-right">
                            <label id="lblDatabits">data bits</label>
                        </div>
                        <div class="col-8">
                            <select class="custom-select" required="" id="selectDatabit">
                                <option value="8">8</option>
                                <option value="16">16</option>
                                <option value="32">32</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row d-flex align-items-center dd_input_group">
                        <div class="col-4 col-form-label text-right">
                            <label id="lblStopbits">stop bits</label>
                        </div>
                        <div class="col-8">
                            <select class="custom-select" required="" id="selectStopbit">
                                <option value="1">1</option>
                                <option value="4">4</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group d-flex align-items-center justify-content-center">
                        <!-- <button class="btn btn-outline-warning btn-lg" type="button" id="btnRefreshComPort">Refresh COM Port</button> -->
                        <button type="button" class="btn btn-outline-warning btn-lg " id="btnSearchOnSelectedPort">Search on Connection</button>
                    </div>
            </li>
            
            <li class="tab-content tab-content-3">
                <!-- Custom Ethernet Tab -->
                    <div class="form-group row d-flex align-items-center mb-1">
                        <div class="col-12">
                            <label id="lblETHCustomTitle">Input Ethernet IP and Port:</label>
                        </div>
                    </div>
                    <div class="form-group row d-flex dd_input_group">
                        <div class="col-4 col-form-label text-right">
                            <label id="lblETHCustomHost">Host</label>
                        </div>
                        <div class="col-8">
                            <input class="form-control" type="text" placeholder="" id="inputIPHost" [(ngModel)]="ip">
                            <div [ngClass]="{'invalid_ip': !validateIp(ip())}" class="ip_validate">
                                <span id="" class="font-weight-bold">{{ip()}}</span>
                                <span id="">{{getIpValidateText()}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group d-flex align-items-center justify-content-center">
                        <button type="button" class="btn btn-outline-warning btn-lg"
                            [ngClass]="{'disabled': !validateIp(ip())}" id="btnSearchOnCustomHost" (click)="startEthernetSearch()">Search on Connection</button>
                    </div>
            </li>
            
            <li class="tab-content tab-content-last">
                @switch (view()) {
                    @case (1) {
                        <!-- init view -->
                        <div id="bt_view1">
                            <div class="home_btn_group">
                                <div class="bt_button add_btn" (click)="onButtonClicked(2)">
                                    <div>Add Bluetooth Device</div>
                                </div>

                                <div class="bt_button detect_btn" (click)="doAction('detect')">
                                    <div>Detect</div>
                                </div>
                            </div>

                            <div class="w-100 mt-2">
                                <div class="d-flex justify-content-start home_list_title">Connected Devices</div>
                                <div class="home_list">
                                    @if(scanStatus() == 'notScanning'){
                                        @if(bluetoothDevices().length == 0){
                                            <div class="d-flex justify-content-center w-100 align-items-center"><span class="">No device found</span></div>
                                        }@else{
                                            @for (item of connectedBluetoothDevices(); track item) {
                                                <div class="bt_device_home">
                                                    <div class="bt_item_status mb-1">
                                                        <div class="indicator" [ngClass]="'indicator_'+ item.status"></div>
                                                        <span>{{item.status}}</span>
                                                    </div>
                                                    @if(item.status != 'removing'){
                                                        <div class="btn-group dropup opitons_group">
                                                            <div class="options_btn" data-toggle="dropdown" aria-expanded="false">
                                                            <img class="icon_img" src="assets/images/product/options.png" alt="">
                                                            </div>
                                                            <div class="dropdown-menu p-1">
                                                                <div class="option_element">
                                                                    <button class="btn_option" (click)="onRemove(item.macAddress)">Remove</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div class="bt_device_name">{{item.name}}</div>
                                                    <div class="bt_device_mac">{{item.macAddress}}</div>
                                                    @if (item.status == 'removing') {
                                                        <span class="removing_item_loader"></span>
                                                    }
                                                </div>
                                            }
                                        }
                                    }@else{
                                        <div class="d-flex justify-content-center w-100 align-items-center"><span class="bt_home_loader"></span></div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    @case (2) {
                        <!-- SPP/HID barcode view -->
                        <div id="bt_view2" class="row">
                            <div class="bt_menu col-12 col-md-2">
                                <div class="bt_button_mini" (click)="onButtonClicked(1)">
                                    <img class="bt_img" src="assets/images/detection/home.png">
                                </div>
                            </div>
                            <div class="bt_main col-12 col-md-10">
                                <div class="director d-flex justify-content-between">
                                    <span (click)="onBack()">Back</span>
                                    <span (click)="onNext()">Next</span>
                                </div>
                                <div class="monitor">
                                    <div class="monitor_title">{{monitorTitle()}}</div>
                                    @switch (monitor()) {
                                        @case (1) {
                                            <p class="text-center">Please scan the barcode below to deactivate the Bluetooth connection from your devices.<p>
                                            <p class="text-center">Then click the <span class="dd_highlight">Next</span> button for next step.</p>
                                            <div class="d-flex justify-content-center">
                                                <img class="barcode" src="assets/images/detection/unlink.png">
                                            </div>
                                        }

                                        @case (2) {
                                            <p class="text-center">Please choose your Bluetooth profile:</p>
                                            <div class="d-flex justify-content-center w-100">
                                                <div class="profile" [ngClass]="{'profile_selected': profile() == 'HID'}" (click)="onProfileClick('HID')">HID</div>
                                                <div class="vertical_line"></div>
                                                <div class="profile" [ngClass]="{'profile_selected': profile() == 'SPP'}" (click)="onProfileClick('SPP')">SPP</div>
                                            </div>
                                            <div class="d-flex justify-content-center">
                                                @if (profile() == 'HID') {
                                                    <img class="barcode" src="assets/images/detection/hid.png" alt="" >
                                                }
                                                @if (profile() == 'SPP') {
                                                    <img class="barcode" src="assets/images/detection/spp.png" alt="" >
                                                }
                                            </div>
                                        }

                                        @case (3) {
                                            @if (scanStatus() == 'notScanning') {
                                                <div class="w-100 d-flex justify-content-center">
                                                    <div class="bt_button_mini scan_btn" (click)="onScan()">
                                                        <img class="bt_img size_25 mr-1" src="assets/images/detection/scan.png">
                                                        <div>Scan</div>
                                                    </div>
                                                </div>
                                            }
                                            @if (scanStatus() == 'scanning') {
                                                <p class="text-center my-0 mb-1">Select a device below to connect. Please wait ...</p>
                                                <div class="d-flex justify-content-center w-100 mb-3"><span class="loader"></span></div>
                                            }
                                            
                                            <div class="w-100 bt_scan_list">
                                                @for (item of bluetoothDevices(); track item) {
                                                    <div class="bt_scan_item row">
                                                        <div class="col-md-3  bt_item_status pl-3">
                                                            <div class="indicator" [ngClass]="'indicator_'+ item.status"></div>
                                                            <span>{{item.status}}</span>
                                                        </div>
                                                        <div class="col-md-6 col-8 mt-1 pl-1">
                                                            <div class="bt_device_mac">{{item.macAddress}}</div>
                                                            <div class="bt_device_name">{{item.name}}</div>
                                                        </div>
                                                        <div class="col-md-3 col-4 d-flex align-items-center justify-content-end">
                                                            @if (item.status != 'connected' && item.status != 'connecting' && scanStatus() == 'notScanning') {
                                                                <div class="bt_action mr-2" (click)="onConnect(item.macAddress)">
                                                                    <img class="icon mr-1" src="assets/images/detection/connect.png">
                                                                    Connect
                                                                </div>
                                                            }
                                                        </div>
                                                        @if (item.status == 'connecting') {
                                                            <span class="loader_scan_item"></span>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        }
                                    }
                                </div>
                            </div>
                        </div>
                    }
                }
            </li>
        </ul>
    </div>
</div>
<!--/ tabs -->
